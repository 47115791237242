<div class="congrats-msg-wrapper" rtl>
    <div class="heading">{{ congratsHeading }}</div>
    <div class="payment-info">
        <div class="payment-info-row" *ngIf="congratsMessage?.planID">
            <div class="info-cell">ID</div>
            <div class="info-cell">{{ congratsMessage?.planID }}</div>
        </div>
        <div class="payment-info-row" *ngIf="congratsMessage?.planDesc">
            <div class="info-cell">{{ 'PLAN_DESCRIPTION' | translate }}</div>
            <div class="info-cell">{{ congratsMessage?.planDesc }}</div>
        </div>
        <div class="payment-info-row" *ngIf="congratsMessage?.planPrice">
            <div class="info-cell">{{ 'Price' | translate }}</div>
            <div class="info-cell">{{ congratsMessage?.planPrice }}</div>
        </div>
    </div>
</div>
