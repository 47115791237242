import { ChangeDetectionStrategy, EventEmitter, Output } from '@angular/core';
import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { IStatusLabel } from 'src/app/store/interfaces';
import { StatusLabelActions } from '../../store/actions/status-label.actions';
import { IAppState } from '../../store/state/app.state';

@Component({
    selector: 'app-status-label',
    templateUrl: './status-label.component.html',
    styleUrls: ['./status-label.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatusLabelComponent {
    @Input() statusLabel: IStatusLabel;
    @Input() isHoverType: boolean;
    @Input() address: string;
    @Input() isSomitomo = false;
    @Input() isSomitomoMobile: boolean;
    @Output() hideTooltip = new EventEmitter();
    @Output() checkSomitomoStatus = new EventEmitter();

    constructor(private store: Store<IAppState>) {}

    closeStatusLabel() {
        if (this.isSomitomo) {
            this.hideTooltip.emit();
        }
        this.store.dispatch(StatusLabelActions.hideStatusLabel());
    }

    checkAgainSomitomoStatus() {
        this.checkSomitomoStatus.emit();
    }
}
