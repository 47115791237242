import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { DevicesActions } from '../actions/devices.actions';
import { MapActions } from '../actions/map.actions';
import { ZonesActions } from '../actions/zones.actions';
import {
    selectDeviceHistory,
    selectDevicesList,
    selectDevicesWithLocation,
    selectSectedDevice,
} from '../selectors/devices.selectors';
import { selectIsMapLoaded } from '../selectors/status.selectors';
import { selectSectedZone, selectZonesList } from '../selectors/zones.selectors';
import { ApiService, GoogleMapService, LocalStorageService } from '../services';
import { IAppState } from '../state/app.state';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../../dialogs/confirm-dialog/confirm-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { selectUser } from '../selectors/user.selector';
import { EventsActions } from '../actions/events.actions';
import { selectDescendantsList } from '../selectors/descendants.selectors';
import { IZone } from '../interfaces';
import { SoundService } from '../services/sound.service';
import { ZoneType } from '../constants/common.constants';

@Injectable()
export class MapEffects {
    loadMap$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MapActions.loadMap),
            switchMap(() =>
                this.googleMapService.mapApiLoaded().pipe(
                    map((response) => MapActions.mapLoadedSuccess({ response })),
                    catchError((error) => of(MapActions.mapLoadedError({ error }))),
                ),
            ),
        ),
    );

    // TODO
    // mapLoadedSuccess$ = createEffect(
    //     () =>
    //         this.actions$.pipe(
    //             ofType(MapActions.mapLoadedSuccess),
    //             tap(() => this.googleMapService.getUserLocation()),
    //         ),
    //     { dispatch: false },
    // );

    addMarkers$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(DevicesActions.getDevicesSuccess),
                filter(() => this.router.url === '/map/devices'),
                withLatestFrom(
                    this.store.select(selectIsMapLoaded),
                    this.store.select(selectDevicesWithLocation),
                    this.store.select(selectUser),
                    this.store.select(selectZonesList),
                ),
                tap((res) => console.log('addMarkers', res)),
                tap(() => this.googleMapService.removeMarkers()),
                tap(() => this.googleMapService.removeZones()),
                tap(() => this.googleMapService.removeInfoWindows()),
                tap(() => this.googleMapService.removeHistory()),
                filter(([, isMapLoaded, devices]) => isMapLoaded && Boolean(devices?.length)),
                tap(([, , devices]) => this.googleMapService.addMarkerCluster(devices)),
                tap(([, , devices]) => this.googleMapService.fitBounds(devices, [])),
            ),
        { dispatch: false },
    );

    initDeviceMarker$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(DevicesActions.getDeviceByIDSuccess),
                withLatestFrom(
                    this.store.select(selectIsMapLoaded),
                    this.store.select(selectSectedDevice),
                    this.store.select(selectUser),
                ),
                tap((res) => console.log('initDeviceMarker', res)),
                filter(([, isMapLoaded, device]) => isMapLoaded && Boolean(device)),
                tap(() => this.googleMapService.removeMarkers()),
                tap(() => this.googleMapService.removeZones()),
                tap(() => this.googleMapService.removeInfoWindows()),
                tap(([, , device]) => this.googleMapService.initDeviceMarker(device)),
                filter(([, , device, user]) => user.preferences.show_device_zones && !!device.location_ping),
                tap(([, , device]) => this.googleMapService.addZones(device.assigned_fences)),
                tap(([, , device]) => this.googleMapService.fitBounds([], device.assigned_fences, device)),
            ),
        { dispatch: false },
    );

    addZones$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(ZonesActions.getZonesSuccess),
                filter(() => this.router.url === '/map/places/geo'),
                withLatestFrom(this.store.select(selectIsMapLoaded), this.store.select(selectZonesList)),
                tap((res) => console.log('addZones', res)),
                tap(() => this.googleMapService.removeMarkers()),
                tap(() => this.googleMapService.removeZones()),
                tap(() => this.googleMapService.removeInfoWindows()),
                tap(() => this.googleMapService.removeHistory()),
                filter(([, isMapLoaded, zones]) => isMapLoaded && Boolean(zones.length)),
                tap(([, , zones]) => this.googleMapService.fitBounds([], zones)),
                tap(([, , zones]) => this.googleMapService.addZones(zones)),
            ),
        { dispatch: false },
    );

    initDeviceHistory$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(DevicesActions.getDeviceHistorySuccess),
                withLatestFrom(this.store.select(selectDeviceHistory), this.store.select(selectSectedDevice)),
                tap(([, history, device]) => {
                    this.googleMapService.removeMarkers();
                    this.googleMapService.removeHistory();
                    this.googleMapService.removeZones();
                    this.googleMapService.removeInfoWindows();
                    if (!history.length) {
                        this.dialog.open(ConfirmDialogComponent, {
                            data: {
                                title: this.translate.instant('NO_HISTORY_AT_THIS_RANGE'),
                                isHideButtons: true,
                            },
                            autoFocus: false,
                            width: '600px',
                            backdropClass: 'dialogBackground',
                        });
                        this.googleMapService.initDeviceMarker(device);
                    } else {
                        this.googleMapService.initDeviceHistory(history);
                    }
                }),
            ),
        { dispatch: false },
    );

    hideDeviceHistoryInfo$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(DevicesActions.hideDeviceHistoryInfo),
                // tap((res) => console.log('hideDeviceHistoryInfo', res)),
                withLatestFrom(this.store.select(selectSectedDevice), this.store.select(selectUser)),
                tap(() => this.googleMapService.removeHistory()),
                tap(() => this.googleMapService.removeMarkers()),
                tap(() => this.googleMapService.removeZones()),
                tap(() => this.googleMapService.removeInfoWindows()),
                filter(() => !(this.router.url.indexOf('/history') > -1)),
                tap(([, device]) => this.googleMapService.initDeviceMarker(device)),
                filter(([, , user]) => user.preferences.show_device_zones),
                tap(([, device]) => this.googleMapService.addZones(device.assigned_fences)),
                tap(([, device]) => this.googleMapService.fitBounds([], device.assigned_fences, device)),
            ),
        { dispatch: false },
    );

    selectDeviceHistoryItem$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(DevicesActions.showDeviceHistoryInfo),
                map((action) => action.historyInfo),
                tap((historyInfo) => {
                    this.googleMapService.updateHistoryMarkerIcon(historyInfo);
                }),
            ),
        { dispatch: false },
    );

    hideHistoryPoint$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(DevicesActions.hideHistoryPoint),
                tap(() => this.googleMapService.deselectHistoryPoint()),
            ),
        { dispatch: false },
    );

    removeDeviceHistory$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(DevicesActions.deleteDeviceHistorySuccess),
                withLatestFrom(this.store.select(selectSectedDevice)),
                tap(() => this.googleMapService.removeHistory()),
                tap(() => this.googleMapService.removeMarkers()),
                tap(([, devices]) => this.googleMapService.initDeviceMarker(devices)),
            ),
        { dispatch: false },
    );

    initNewZone$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(DevicesActions.getDevicesSuccess),
                filter(() => this.router.url === '/map/places/add-gzone'),
                withLatestFrom(
                    this.store.select(selectIsMapLoaded),
                    this.store.select(selectDevicesList),
                    this.store.select(selectUser),
                    this.store.select(selectZonesList),
                ),
                tap((res) => console.log('initNewZone', res)),
                tap(() => this.googleMapService.removeMarkers()),
                switchMap(([, , , user]) => {
                    return this.apiService.getZones(user.account_id).pipe(
                        tap((inZones) => {
                            const lZones = inZones.map((inZone) => ({
                                ...inZone,
                                type: inZone.type.toUpperCase(),
                            }));
                            this.googleMapService.removeZones();
                            this.googleMapService.removeInfoWindows();
                            if (this.localStorage.getItem('COMPLEX_GZONES')) {
                                this.store.dispatch(ZonesActions.showZoneTooltip());
                            }

                            if (lZones.length) {
                                this.googleMapService.addZones(lZones);
                                this.googleMapService.initNewZone();
                            } else {
                                this.googleMapService.initNewZone();
                            }
                        }),
                    );
                }),
            ),
        { dispatch: false },
    );

    initDeviceZone$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(ZonesActions.getZoneByIDSuccess),
                filter(() => this.router.url.indexOf('/map/places/geo/') > -1),
                withLatestFrom(
                    this.store.select(selectSectedZone),
                    this.store.select(selectDevicesList),
                    this.store.select(selectZonesList),
                    this.store.select(selectUser),
                ),
                tap((res) => console.log('initDeviceZone', res)),
                tap(() => this.googleMapService.removeMarkers()),
                tap(() => this.googleMapService.removeZones()),
                tap(() => this.googleMapService.removeInfoWindows()),
                switchMap(([, zone, , zones, user]) => {
                    if (zones.length) {
                        const zoneFiltered = zones.filter((z: IZone) => z.id !== zone.id);
                        this.googleMapService.addZones(zoneFiltered);
                        this.googleMapService.initDeviceZone(zone);
                        return of(true);
                    } else {
                        return this.apiService.getZones(user.account_id).pipe(
                            tap((inZones) => {
                                const lZones = inZones.map((inZone) => ({
                                    ...inZone,
                                    type: inZone.type.toUpperCase(),
                                }));
                                if (lZones.length) {
                                    const zoneFiltered = lZones.filter((z: IZone) => z.id !== zone.id);
                                    this.googleMapService.addZones(zoneFiltered);
                                    this.googleMapService.initDeviceZone(zone);
                                } else {
                                    this.googleMapService.initDeviceZone(zone);
                                }
                            }),
                        );
                    }
                }),
            ),
        { dispatch: false },
    );

    updateMarkerOnSOS$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(EventsActions.getSOSDevicesEventsSuccess),
                filter(() => this.router.url === '/map/devices'),
                withLatestFrom(this.store.select(selectDevicesList)),
                filter(([, deviceList]) => !!deviceList.length),
                tap(([, deviceList]) => {
                    const devicesWithEvents = deviceList.filter((device) => device.allSOSEvents?.length);
                    this.googleMapService.updateMarkerIcon(devicesWithEvents);
                }),
            ),
        { dispatch: false },
    );

    updateMarkerOnSOSRead$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(DevicesActions.updateDeviceEventSuccess),
                withLatestFrom(this.store.select(selectSectedDevice)),
                tap(([, device]) => {
                    this.soundService.stopAudio();
                    if (!device.history?.length && device.location_ping) {
                        this.googleMapService.updateSelectedDeviceMarkerIcon(device);
                    }
                }),
            ),
        { dispatch: false },
    );

    chengeZoneType$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(ZonesActions.changeZoneType),
                map((action) => action.zoneType),
                tap((zoneType: ZoneType) => {
                    this.googleMapService.removeInitialZone();
                    this.googleMapService.initNewZone(zoneType);
                }),
            ),
        { dispatch: false },
    );

    constructor(
        private actions$: Actions,
        private googleMapService: GoogleMapService,
        public dialog: MatDialog,
        private store: Store<IAppState>,
        private translate: TranslateService,
        private router: Router,
        private apiService: ApiService,
        private soundService: SoundService,
        private localStorage: LocalStorageService,
    ) {}
}
