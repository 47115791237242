<section class="activation-account">
    <app-header></app-header>

    <ng-container *ngIf="skin.whiteLabel !== 'TAGANDTRACK'; else tntTitle">
        <h1>{{ 'WELCOME' | translate }}</h1>
    </ng-container>
    <ng-template #tntTitle>
        <h1 class="tnt">{{ 'CREATE_ACCOUNT' | translate }}</h1>
    </ng-template>

    <app-create-account-stepper [selectedIndex]="2"></app-create-account-stepper>

    <div class="content confirmation">
        <h2 *ngIf="skin.whiteLabel === 'TAGANDTRACK'" class="tnt-welcome">{{ 'WELCOME' | translate }}</h2>
        <p class="info">
            {{ 'SIGN_UP_CONFIRM_SUCCESS' | translate: { brand: skin.brandName } }}<br />
            {{ 'GET_STARTED_BELOW' | translate }}.
        </p>

        <button class="btn primary" (click)="goToAddTracker()" mat-flat-button>
            {{ 'ADD_TRACKER' | translate }}
        </button>
    </div>
    <app-footer class="footer"></app-footer>
</section>
