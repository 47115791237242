<div class="info-wrapper">
    <app-header *ngIf="isShowHeader" [user]="user$ | async"></app-header>
    <div class="info-container" rtl>
        <h1 class="page-header">{{ 'CONTACT_US' | translate }}</h1>
        <app-breadcrumbs [breadcrumbsList]="breadcrumbsList"> </app-breadcrumbs>

        <div class="contact-us">
            <ng-container *ngIf="skin.whiteLabel !== 'TAGANDTRACK'; else whiteLabelTNT">
                <p class="contact-us-title">
                    {{
                        skin.whiteLabel === 'TRACKIMO'
                            ? ('CONTACT_US_TITLE' | translate)
                            : ('CONTACT_US_TITLE_MAMORI' | translate)
                    }}
                </p>
                <div class="icons-wrapper">
                    <i class="icon no-fill mail-us-icon" [inlineSVG]="'#mail'"></i>
                    <i class="icon no-fill contact-us-icon" [inlineSVG]="'#phone-privacy'"></i>
                </div>
                <div
                    [innerHTML]="
                        skin.whiteLabel === 'TRACKIMO'
                            ? ('CONTACT_US_CONTENT' | translate)
                            : ('CONTACT_US_CONTENT_MAMORI' | translate)
                    "
                    class="contact-us-block"
                ></div>
            </ng-container>

            <ng-template #whiteLabelTNT>
                <div class="tnt-container">
                    <mat-accordion class="tnt-accordion">
                        <mat-expansion-panel class="expansion-item" *ngFor="let item of contactsTNT">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    {{ item.location | translate }}
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <div class="tnt-contact-info">
                                <i
                                    class="icon no-fill tnt-contact-info-icon"
                                    [inlineSVG]="'#phone-privacy'"
                                ></i>
                                <p class="heading-text" [innerHTML]="item.label_1 | translate"></p>
                                <p [innerHTML]="item.label_2 | translate"></p>
                                <p *ngIf="item.label_3" [innerHTML]="item.label_3 | translate"></p>
                                <p *ngIf="item.label_4" [innerHTML]="item.label_4 | translate"></p>
                            </div>
                        </mat-expansion-panel>
                    </mat-accordion>
                </div>
            </ng-template>
        </div>
    </div>
    <app-footer class="footer"></app-footer>
</div>
