<h1 class="title"> {{'RESET_PASSWORD' | translate}}</h1>

<p class="description">{{'FOR' | translate}} <strong>{{email}}</strong></p>

<form [formGroup]="reserPasswordForm" (ngSubmit)="onSubmit(reserPasswordForm.valid)">

  <div class="input-text-wrapper">
    <mat-form-field class="form-field" appearance="outline">
      <mat-label>{{'PASSWORD' | translate}}</mat-label>
      <input matInput required placeholder="{{'PASSWORD' | translate}}" formControlName='password'
        [type]="showPassword  ? 'text' : 'password'">
      <i class="icon" (click)="changeVisiblePassword()" matSuffix
        [inlineSVG]="showPassword ? '#eye' : '#eye-close'"></i>
    </mat-form-field>
  </div>

  <app-password-hints [formControlValue]='reserPasswordForm.controls.password'></app-password-hints>

  <div class="input-text-wrapper">
    <mat-form-field class="form-field" appearance="outline">
      <mat-label>{{'CONFIRM_NEW_PASSWORD' | translate}}</mat-label>
      <input matInput required placeholder="{{'CONFIRM_NEW_PASSWORD' | translate}}" formControlName='passwordConfirmed'
        [type]="showConfirmePassword ? 'text' : 'password'">
      <i class="icon" (click)="changeVisibleConfirmPassword()" matSuffix
        [inlineSVG]="showConfirmePassword ? '#eye' : '#eye-close'"></i>
    </mat-form-field>
  </div>


  <button class="btn primary form-btn" mat-flat-button type="submit">{{ 'RESET_PASSWORD' | translate }}</button>
</form>

<button class="btn basic" routerLink="/">{{ 'CANCEL' | translate }}</button>