import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ICongratsMessage } from '../../store/interfaces';

@Component({
    selector: 'app-payment-congrats-msg',
    templateUrl: './payment-congrats-msg.component.html',
    styleUrls: ['./payment-congrats-msg.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentCongratsMsgComponent implements OnInit {
    @Input() congratsMessage: ICongratsMessage;
    @Input() isSMS = false;
    congratsHeading: string;

    constructor(private translate: TranslateService) {}

    ngOnInit() {
        this.congratsHeading = this.isSMS
            ? this.translate.instant('CONGRATS_PLAN_ACTIVE')
            : this.translate.instant('CONGRATS_DEVICE_ACTIVE');
    }
}
