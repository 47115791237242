import { createReducer, on } from '@ngrx/store';
import { initialContactsState } from '../state/contacts.stete';
import { ContactsActions } from '../actions/contacts.actions';
import { DevicesActions } from '../actions/devices.actions';

export const contactsReducer = createReducer(
    initialContactsState,
    on(ContactsActions.getContactsError, () => initialContactsState),
    on(ContactsActions.getContactsSuccess, (state, { response }) => ({
        ...state,
        contacts: response,
    })),
    on(DevicesActions.getDeviceContactAssignmentsSuccess, (state, { response }) => ({
        ...state,
        contacts: state.contacts?.map((contact) => ({
            ...contact,
            eventTypes: response.contactAssignments.filter((c) => c.contactId === contact.id)[0]?.eventTypes,
        })),
    })),
    on(ContactsActions.clearContacts, (state, {}) => ({
        ...state,
        contacts: undefined,
    })),
);
