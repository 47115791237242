import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class SoundService {
    private audio = new Audio();

    constructor() {
        this.audio.src = 'assets/audio/half-life-alarm.m4a';
        this.audio.load();
        this.audio.loop = true;
    }

    playAudio(): void {
        this.audio.play();
    }

    stopAudio(): void {
        this.audio.pause();
    }
}
