import { createReducer, on } from '@ngrx/store';
import { DevicesActions } from '../actions/devices.actions';
import { DEVICES_TYPES, METRIC_SYSTEM } from '../constants/common.constants';
import { IDeviceHistory, IDeviceLocationEvent, IDeviceShort, ISubscription, IZone } from '../interfaces';
import { initialDeviceState } from '../state/devices.stete';
import { converter } from '../services/helper';
import { EventsActions } from '../actions/events.actions';

export const devicesReducer = createReducer(
    initialDeviceState,
    on(DevicesActions.getDevicesError, () => initialDeviceState),
    on(DevicesActions.getDevicesSuccess, (state, { response }) => ({
        ...state,
        devices: response.map((device: IDeviceShort) => ({
            ...device,
            icon: DEVICES_TYPES.find((d) => +d.icon_id === device.icon_id)?.icon || '#universal',
        })),
    })),
    on(DevicesActions.clearDevices, (state, {}) => ({
        ...state,
        devices: undefined,
    })),
    on(DevicesActions.getAddressForDeviceSuccess, (state, { deviceID, address }) => {
        if (state.selectedDevice) {
            return {
                ...state,
                selectedDevice: {
                    ...state.selectedDevice,
                    location: {
                        ...state.selectedDevice.location,
                        address: address,
                    },
                },
            };
        } else {
            return {
                ...state,
                devices: state.devices?.map((device: IDeviceShort) => {
                    if (device.device_id === deviceID) {
                        return {
                            ...device,
                            address,
                        };
                    }
                    return device;
                }),
            };
        }
    }),
    on(DevicesActions.getAddressForDeviceError, (state, { error, deviceID, errorText }) => ({
        ...state,
        devices: state.devices?.map((device: IDeviceShort) => {
            if (device.device_id === deviceID) {
                return {
                    ...device,
                    address: errorText,
                };
            }
            return device;
        }),
    })),
    on(DevicesActions.deleteDeviceHistorySuccess, (state) => ({
        ...state,
        selectedDevice: {
            ...state.selectedDevice,
            history: [],
        },
    })),
    on(DevicesActions.hideDeviceHistoryInfo, (state) => ({
        ...state,
        selectedDevice: {
            ...state.selectedDevice,
            history: [],
        },
    })),
    on(DevicesActions.getDeviceByIDSuccess, (state, { device, user }) => {
        const altitude = device.location?.altitude;

        let selectedDevice = {
            ...device,
            assigned_fences: device.assigned_fences.map((zone) => ({
                ...zone,
                type: zone.type.toUpperCase(),
            })),
            info: {
                ...device.info,
                icon: getDeviceIcon(device),
            },
            location: {
                ...device.location,
                altitude: altitude ? `${altitude} M` : 'N/A',
            },
        };

        if (user.preferences.speed_unit === METRIC_SYSTEM.MPH) {
            selectedDevice.location.altitude = altitude
                ? `${converter.metricToImperial.ft(altitude)} FT`
                : 'N/A';
        }

        return {
            ...state,
            selectedDevice,
        };
    }),
    on(DevicesActions.setDeviceSettingsSuccess, (state, { deviceName, preferences, usageName }) => ({
        ...state,
        selectedDevice: {
            ...state.selectedDevice,
            info: {
                ...state.selectedDevice.info,
                nick_name: deviceName,
                usage: usageName,
            },
            settings: {
                ...state.selectedDevice.settings,
                preferences: {
                    ...state.selectedDevice.settings.preferences,
                    ...preferences,
                },
            },
        },
    })),
    on(DevicesActions.getDeviceHistorySuccess, (state, { response }) => ({
        ...state,
        selectedDevice: {
            ...state.selectedDevice,
            history: response.map((history, i) => ({
                ...history,
                toTime: response[i + 1]?.time ? response[i + 1]?.time : response[i]?.time,
                device_name: state.selectedDevice.info.nick_name,
                sliderIndex: i + 1,
            })),
        },
    })),
    on(DevicesActions.updateDeviceImageSuccess, (state, response) => ({
        ...state,
        selectedDevice: {
            ...state.selectedDevice,
            info: {
                ...state.selectedDevice.info,
                ...mapIcon(response),
            },
        },
    })),
    on(DevicesActions.assignDeviceSuccess, (state, { zone }) => ({
        ...state,
        selectedDevice: {
            ...state.selectedDevice,
            assigned_fences: [...state.selectedDevice.assigned_fences, zone],
        },
    })),
    on(DevicesActions.unassignDeviceSuccess, (state, { zone }) => ({
        ...state,
        selectedDevice: {
            ...state.selectedDevice,
            assigned_fences: state.selectedDevice.assigned_fences.filter(
                (gzone: IZone) => gzone.id !== zone.id,
            ),
        },
    })),
    on(DevicesActions.getRenewalPlansSuccess, (state, { plan }) => ({
        ...state,
        selectedDevice: {
            ...state.selectedDevice,
            renewalsPlans: plan,
        },
    })),
    on(EventsActions.getSOSDevicesEventsSuccess, (state, { response }) => ({
        ...state,
        devices: state.devices?.map((device) => ({
            ...device,
            allSOSEvents: response.content.filter(
                (event) => event.alarm_type === 'SOS' && event.device_id === device.device_id,
            ),
        })),
    })),
    on(EventsActions.getSOSDevicesEventsSuccess, (state, { response }) => {
        if (state.selectedDevice) {
            return {
                ...state,
                selectedDevice: {
                    ...state.selectedDevice,
                    allSOSEvents: response.content.filter(
                        (event) =>
                            event.alarm_type === 'SOS' &&
                            event.device_id === state.selectedDevice.info.device_id,
                    ),
                },
            };
        }

        return state;
    }),
    on(DevicesActions.updateDeviceEventSuccess, (state) => ({
        ...state,
        selectedDevice: {
            ...state.selectedDevice,
            allSOSEvents: null,
        },
    })),
    on(DevicesActions.removeSelectedDevice, (state, {}) => ({
        ...state,
        selectedDevice: null,
    })),
    on(DevicesActions.getDevicesSubscriptionSuccess, (state, { response }) => ({
        ...state,
        devicesSubscription: response.map((subscription: ISubscription) => ({
            ...subscription,
            icon_type_id: DEVICES_TYPES.find((s) => +s.icon_id === subscription.icon_id)?.icon,
        })),
    })),
    on(DevicesActions.getSBDevicesSubscriptionSuccess, (state, { response }) => ({
        ...state,
        sbDevicesSubscription: response.map((subscription: ISubscription) => ({
            ...subscription,
            icon_type_id: subscription.icon_id !== 1 ? DEVICES_TYPES.find((d) => +d.icon_id === subscription.icon_id)?.icon : '#universal',
        })),
    })),
    on(DevicesActions.pingDeviceLocationSuccess, (state, { response }) => ({
        ...state,
        selectedDevice: {
            ...state.selectedDevice,
            location_ping: response,
        },
    })),
    on(DevicesActions.pingDevicesLocationsSuccess, (state, { response }) => ({
        ...state,
        devices: state.devices?.map((device) => ({
            ...device,
            location_ping: response.find(
                (location: IDeviceLocationEvent) => location.device_id === device.device_id,
            ),
        })),
    })),
    // @TODO AFTER NEW API WILL BE READY
    on(DevicesActions.getDevicePetDetailsSuccess, (state, { response }) => ({
        ...state,
        selectedDevice: {
            ...state.selectedDevice,
            info: {
                ...state.selectedDevice.info,
                petDetailsSettings: response,
            },
        },
    })),
    ///**** Renewals ****///
    on(DevicesActions.increaseSMSLimitSuccess, (state, { smsLimit, haveSelectedDevice }) => {
        if (haveSelectedDevice) {
            return {
                ...state,
                selectedDevice: {
                    ...state.selectedDevice,
                    info: {
                        ...state.selectedDevice.info,
                        sms_limit: state.selectedDevice.info.sms_limit + smsLimit,
                    },
                },
            };
        } else {
            return { ...state };
        }
    }),
    on(
        DevicesActions.renewPlanSuccess,
        DevicesActions.renewPrepaidPlanSuccess,
        DevicesActions.renewPlanByCouponSuccess,
        (state, payload) => {
            if (state.devices) {
                return {
                    ...state,
                    devices: state.devices?.map((device: IDeviceShort) => ({
                        ...device,
                        status: device.device_id === payload.deviceID ? 'active' : device.status,
                    })),
                };
            }
            return state;
        },
    ),
);

const mapIcon = (res) =>
    res.icon_url
        ? { icon_url: res.icon_url }
        : {
              icon_id: res.icon,
              icon: getDeviceIcon(res),
              icon_url: undefined,
          };

const getDeviceIcon = (inDevice) =>
    DEVICES_TYPES.find((divice) => +divice.icon_id === (inDevice.icon || inDevice.info.icon_id))?.icon;
