import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, timer } from 'rxjs';

@Component({
    selector: 'app-account-locked',
    templateUrl: './account-locked.component.html',
    styleUrls: ['./account-locked.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountLockedComponent implements OnInit, OnDestroy {
    countDown: Subscription;
    timeLeft: number;
    tick = 1000;

    constructor(private route: ActivatedRoute, private router: Router, private cdRef: ChangeDetectorRef) {
        this.timeLeft = this.router.getCurrentNavigation().extras?.state?.unblockTime;
        if (!this.timeLeft) {
            this.router.navigate(['/']);
        }
    }

    ngOnInit() {
        this.countDown = timer(0, this.tick).subscribe(() => {
            --this.timeLeft;
            if (this.timeLeft === 0) {
                this.router.navigate(['/']);
            }
            this.cdRef.markForCheck();
        });
    }
    ngOnDestroy() {
        this.countDown = null;
    }
}
