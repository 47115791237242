import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NoGpsSignalService } from '../../store/services';

@Component({
    selector: 'app-no-gps-dialog-info',
    templateUrl: './no-gps-dialog-info.component.html',
    styleUrls: ['./no-gps-dialog-info.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NoGpsDialogInfoComponent {
    constructor(
        public dialogRef: MatDialogRef<NoGpsDialogInfoComponent>,
        private noGPSService: NoGpsSignalService,
        @Inject(MAT_DIALOG_DATA) public data,
    ) {}

    close(): void {
        this.noGPSService.close();
    }
}
