<div class="dialog-wrapper">
    <div class="dialog-title-wrapper" mat-dialog-title>
        <h1 class="title">{{ 'TURN_OFF_SLEEP_MODE_INSTRUCTIONS_TITLE' | translate }}</h1>

        <div class="dialog-content-wrapper" mat-dialog-content>
            <p>{{ 'TURN_OFF_SLEEP_MODE_INSTRUCTIONS_DESC1' | translate }}</p>
            <ul>
                <li>
                    <p>{{ 'TURN_OFF_SLEEP_MODE_INSTRUCTIONS_DESC2' | translate }}</p>
                </li>
                <li>
                    <p>{{ 'TURN_OFF_SLEEP_MODE_INSTRUCTIONS_DESC3' | translate }}</p>
                </li>
            </ul>
        </div>

        <div class="dialog-content-actions" mat-dialog-actions>
            <div class="nav-container">
                <button class="btn basic" (click)="turnOff()" [disabled]="isTurnOffDisabled" mat-flat-button>
                    {{ 'TURN_OFF' | translate }}
                </button>

                <button class="btn primary" (click)="close()" mat-flat-button>
                    {{ 'CANCEL' | translate }}
                </button>
            </div>
        </div>
    </div>
</div>