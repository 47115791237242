<section class="activation-account">
    <app-header></app-header>

    <h1 [ngClass]="{ tnt: skin.whiteLabel === 'TAGANDTRACK' }">{{'CREATE_ACCOUNT' | translate}}</h1>

    <app-create-account-stepper [selectedIndex]="1"></app-create-account-stepper>

    <div class="content confirmation">

        <p class="info">{{'ACCOUNT_ACTIVATION_VIA_EMAIL' | translate}}<br />
            {{'SENT_TO_EMAIL' | translate}}
            <strong>{{ userEmail }}</strong>
        </p>
        <p class="resend">
            {{'CODE_NOT_RECIEVED' | translate}}
            <span class="resend-btn" (click)="resendEmailLink()">{{'RESEND_EMAIL' | translate}}</span>
        </p>
        <a class="btn primary" routerLink="/" mat-flat-button>{{'GO_TO_HOMEPAGE' | translate}}</a>
    </div>
    <app-footer class="footer"></app-footer>
</section>
