import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class LocalStorageService implements WindowLocalStorage {
    localStorage: Storage;

    constructor() {}

    public getItem(key: string): string {
        let data = window.localStorage.getItem(key) || '';
        try {
            data = JSON.parse(data);
        } catch (e) {}

        return data;
    }

    public setItem(key: string, value: string): void {
        if (typeof value !== 'string') {
            value = JSON.stringify(value);
        }
        window.localStorage.setItem(key, value);
    }

    public remove(key: string): void {
        window.localStorage.removeItem(key);
    }
}
