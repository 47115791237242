<div
    class="device-wrapp"
    [tooltip]="$any(statusLabel)"
    contentType="template"
    placement="right"
    theme="light"
    [shadow]="false"
    [offset]="20"
    [hideDelay]="0"
    [display]="device.allSOSEvents?.length && skin.whiteLabel !== 'TAGANDTRACK'"
>
    <div
        class="device"
        [ngClass]="{ inactiveDevice: !device.location_ping?.comm_stat && isHideChevron }"
        *ngIf="device; else loader"
    >
        <div class="device-img">
            <i
                class="icon"
                [ngClass]="{ sos: device.allSOSEvents?.length, inactive: !device.location_ping?.comm_stat }"
                [inlineSVG]="device.icon"
                *ngIf="!device?.icon_url; else deviceImg"
            ></i>
            <ng-template #deviceImg>
                <img class="img" [src]="device?.icon_url" alt="{{ device?.device_id }}" />
            </ng-template>
            <app-device-battery
                class="battery"
                [batteryLevel]="device?.battery || device?.location?.battery"
            ></app-device-battery>
        </div>
        <div class="device-names-wrap">
            <h5
                class="device-title"
                [ngClass]="{ sos: device.allSOSEvents?.length }"
                title="{{ device.device_name ? device.device_name : device.device_id }}"
            >
                {{ device.device_name ? device.device_name : device.device_id }}
            </h5>
            <h5 class="device-address" *ngIf="device.lat && device.lng">
                <ng-container *ngIf="isShowAddressImmediately; else showAddressOnDemand">
                    {{ device.address }}
                </ng-container>
                <ng-template #showAddressOnDemand>
                    <ng-container *ngIf="!device.address; else addressContainer">
                        <span class="show-address" (click)="showDeviceAddress(device, $event)">{{
                            'SHOW_ADDRESS' | translate
                        }}</span>
                    </ng-container>
                    <ng-template #addressContainer>
                        <span>{{ device.address }}</span>
                    </ng-template>
                </ng-template>
            </h5>
            <h5 class="device-address warning" *ngIf="isHideChevron && !(device.lat && device.lng)">
                {{ 'CONNECTION_LOST' | translate }}...
            </h5>
        </div>
    </div>

    <div class="icons-wrapp">
        <i
            class="icon"
            *ngIf="device.status === deviceStatus.EXPIRED"
            [inlineSVG]="'#renew_subscription'"
            (click)="renewSubscription(device?.device_id, $event)"
        ></i>

        <i class="icon" *ngIf="isHideChevron && !(device.lat && device.lng)" [inlineSVG]="'#warning_2'"></i>

        <i class="icon right" *ngIf="!isHideChevron" [inlineSVG]="'#chevron_right'"></i>

        <mat-slide-toggle
            *ngIf="isAddZone"
            [(ngModel)]="device.isChecked"
            (change)="onToggleChange(device, $event)"
            color="primary"
            [ngClass]="{ disabled: device.device_type === eTollDeviceType }"
        >
        </mat-slide-toggle>

        <i
            class="icon no-fill sos-alarm"
            *ngIf="device.allSOSEvents?.length && skin.whiteLabel !== 'TAGANDTRACK'"
            [inlineSVG]="'#alarm_sos'"
        ></i>
    </div>

    <ng-template #statusLabel>
        <app-status-label
            [statusLabel]="sosStatusLabel"
            [address]="device.address"
            [isHoverType]="true"
            *ngIf="device.allSOSEvents?.length"
        >
        </app-status-label>
    </ng-template>
</div>

<ng-template #loader>
    {{ 'LOADING' | translate }}
</ng-template>
