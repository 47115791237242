import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { IBreadcrumb } from 'src/app/store/interfaces/breadcrumb.interface';
import { TNT_USER_GUIDE } from '../../store/constants/common.constants';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import { LocaleService } from '../../store/services';

@Component({
    selector: 'app-user-guide',
    templateUrl: './user-guide.component.html',
    styleUrls: ['./user-guide.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class UserGuideComponent {
    breadcrumbsList: Array<IBreadcrumb> = [
        {
            label: this.translate.instant('USER_GUIDE'),
            path: '/',
        },
    ];
    userGuideInfo = TNT_USER_GUIDE;

    constructor(private route: ActivatedRoute, private translate: TranslateService) {}
}
