<div class="mobile">
    <mat-form-field class="country-code-form" appearance="outline">
        <mat-label>{{ 'COUNTRY_CODE' | translate }}</mat-label>

        <span *ngIf="singleSelect.value" class="flag-icon flag-icon-squared flag-icon-{{singleSelect.value[1]}}"
            matPrefix></span>

        <mat-select class="country-code" [formControl]="countryCtrl" placeholder="{{ 'COUNTRY_CODE' | translate }}"
            #singleSelect>
            <mat-select-trigger>
                (+{{ singleSelect.value[2] }})
            </mat-select-trigger>

            <mat-option rtl>
                <ngx-mat-select-search [formControl]="countryFilterCtrl"
                    placeholderLabel="{{ 'search' | translate }} ..."
                    noEntriesFoundLabel="{{ 'NO_OPTIONS_FOUND' | translate }}">
                </ngx-mat-select-search>
            </mat-option>

            <mat-option *ngFor="let country of filteredCountryCodes | async" [value]="country" rtl>
                <span [ngClass]="'flag-icon flag-icon-squared flag-icon-' + country[1]"></span>
                {{ country[0] }} (+{{ country[2] }})
            </mat-option>
        </mat-select>
    </mat-form-field>


    <mat-form-field class="form-field country-phone" appearance="outline">
        <mat-label>{{ 'PHONE_NUMBER' | translate }}<ng-container *ngIf="isRequired">*</ng-container>
        </mat-label>
        <input [formControl]="phoneNumb" matInput placeholder="{{ 'PHONE_NUMBER' | translate }}" />
    </mat-form-field>
</div>