<div class="info-wrapper">
    <app-header *ngIf="isShowHeader" [user]="user$ | async"></app-header>
    <div class="info-container" rtl>
        <h1 class="page-header">
            <ng-container *ngIf="this.skin.whiteLabel !== 'TAGANDTRACK'; else tntHeading">
                {{ 'FAQS' | translate }}
            </ng-container>
            <ng-template #tntHeading>
                {{ 'FREQ_ASKED_QUESTIONS' | translate }}
            </ng-template>
        </h1>
        <app-breadcrumbs [breadcrumbsList]="breadcrumbsList"> </app-breadcrumbs>
        <mat-accordion class="tnt-accordion">
            <mat-expansion-panel class="expansion-item" *ngFor="let item of faqsList">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        {{ item.header | translate: { brand: skin.brandName } }}
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <p *ngFor="let answer of item.body">{{ answer | translate: { brand: skin.brandName } }}</p>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
    <app-footer class="footer"></app-footer>
</div>
