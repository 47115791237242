<div class="dialog-wrapper">
    <div mat-dialog-title>
        <h1 class="title" rtl>{{ 'ADD_RENEWAL' | translate }}</h1>
    </div>

    <ng-container *ngIf="!isChoiceStepProceeded">
        <h4 rtl>{{ 'CHOOSE_PAYMENT_PLAN' | translate }}:</h4>

        <mat-radio-group [(ngModel)]="selectedPlan">
            <mat-radio-button
                class="plan-radio"
                *ngFor="let plan of (plan$ | async)?.planDTOList"
                [value]="plan"
                color="primary"
            >
                <div class="plan-radio-info" rtl>
                    <h5>{{ plan.name }}</h5>
                </div>
            </mat-radio-button>
        </mat-radio-group>

        <div mat-dialog-actions>
            <div class="nav-container">
                <button class="btn basic" (click)="close()" mat-flat-button>
                    {{ 'CANCEL' | translate }}
                </button>

                <button
                    class="btn primary"
                    [disabled]="!selectedPlan"
                    (click)="choosePlanOption()"
                    mat-flat-button
                >
                    {{ 'NEXT' | translate }}
                </button>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="isProceededToPrepaid">
        <ng-container *ngIf="(paymentStatus$ | async)?.labelType === 'success'">
            <ng-container *ngTemplateOutlet="congrats"></ng-container>
        </ng-container>

        <div mat-dialog-actions>
            <div class="nav-container">
                <button
                    *ngIf="(paymentStatus$ | async)?.labelType !== 'success'"
                    class="btn basic"
                    (click)="close()"
                    mat-flat-button
                >
                    {{ 'CANCEL' | translate }}
                </button>

                <button
                    *ngIf="(paymentStatus$ | async)?.labelType !== 'success'"
                    class="btn primary"
                    [disabled]="(paymentProcessing$ | async) || !selectedPlan"
                    mat-flat-button
                >
                    <ng-container *ngIf="(paymentProcessing$ | async) !== true; else spinner">
                        {{ 'NEXT' | translate }}
                    </ng-container>

                    <ng-template #spinner>
                        <mat-spinner class="spinner" diameter="30"></mat-spinner>
                    </ng-template>
                </button>

                <button
                    class="btn primary"
                    (click)="closeWithConfirm()"
                    *ngIf="(paymentStatus$ | async)?.labelType === 'success'"
                    mat-flat-button
                >
                    {{ 'DONE' | translate }}
                </button>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="isProceededToBraintree">
        <div mat-dialog-content>
            <ng-container *ngIf="(paymentStatus$ | async)?.labelType !== 'success'; else successMsg">
                <app-status-label
                    *ngIf="paymentStatus$ | async"
                    [statusLabel]="paymentStatus$ | async"
                ></app-status-label>
                <div
                    id="#dropin-container"
                    class="dropin-container"
                    [ngClass]="{ success: (paymentStatus$ | async)?.labelType === 'success' }"
                    style="position: relative"
                ></div>
            </ng-container>
            <ng-template #successMsg>
                <ng-container *ngTemplateOutlet="congrats"></ng-container>
            </ng-template>
        </div>

        <div mat-dialog-actions>
            <div class="nav-container">
                <button
                    *ngIf="(paymentStatus$ | async)?.labelType !== 'success'"
                    class="btn basic"
                    (click)="close()"
                    mat-flat-button
                >
                    {{ 'CANCEL' | translate }}
                </button>

                <button
                    class="btn primary"
                    (click)="goToPayment()"
                    *ngIf="!isBrainTreeToken"
                    [disabled]="!selectedPlan"
                    mat-flat-button
                >
                    {{ 'GOTO_PAYMENT' | translate }}
                </button>
                <ng-container *ngIf="!isShowPayButton">
                    <button
                        class="btn primary"
                        id="#submit-button"
                        (click)="renewPlan()"
                        [disabled]="paymentProcessing$ | async"
                        *ngIf="isBrainTreeToken && (paymentStatus$ | async)?.labelType !== 'success'"
                        mat-flat-button
                    >
                        <ng-container *ngIf="(paymentProcessing$ | async) !== true; else spinner">
                            {{ 'PAY' | translate }}
                        </ng-container>
                        <ng-template #spinner>
                            <mat-spinner class="spinner" diameter="30"></mat-spinner>
                        </ng-template>
                    </button>
                </ng-container>
                <button
                    class="btn primary"
                    (click)="closeWithConfirm()"
                    *ngIf="(paymentStatus$ | async)?.labelType === 'success'"
                    mat-flat-button
                >
                    {{ 'DONE' | translate }}
                </button>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="isProceededToCouponForm">
        <div mat-dialog-content>
            <ng-container *ngIf="(paymentStatus$ | async)?.labelType === 'success'">
                <ng-container *ngTemplateOutlet="congrats"></ng-container>
            </ng-container>

            <ng-container
                *ngIf="isProceededToCouponForm && (paymentStatus$ | async)?.labelType !== 'success'"
            >
                <form [formGroup]="couponForm" (ngSubmit)="renewPlanByCouponCode(couponForm.valid)">
                    <mat-form-field class="form-field" appearance="outline">
                        <mat-label>{{ 'COUPON_CODE' | translate }}</mat-label>
                        <input
                            formControlName="couponValue"
                            matInput
                            placeholder="{{ 'ENTER_COUPON_CODE' | translate }}"
                        />
                    </mat-form-field>
                </form>
            </ng-container>
        </div>

        <div mat-dialog-actions>
            <div class="nav-container">
                <button
                    *ngIf="(paymentStatus$ | async)?.labelType !== 'success'"
                    class="btn basic"
                    (click)="close()"
                    mat-flat-button
                >
                    {{ 'CANCEL' | translate }}
                </button>

                <button
                    class="btn primary"
                    (click)="renewPlanByCouponCode(couponForm.valid)"
                    [disabled]="paymentProcessing$ | async"
                    *ngIf="isProceededToCouponForm && (paymentStatus$ | async)?.labelType !== 'success'"
                    mat-flat-button
                >
                    <ng-container *ngIf="(paymentProcessing$ | async) !== true; else spinner">
                        {{ 'NEXT' | translate }}
                    </ng-container>

                    <ng-template #spinner>
                        <mat-spinner class="spinner" diameter="30"></mat-spinner>
                    </ng-template>
                </button>

                <button
                    class="btn primary"
                    (click)="closeWithConfirm()"
                    *ngIf="(paymentStatus$ | async)?.labelType === 'success'"
                    mat-flat-button
                >
                    {{ 'DONE' | translate }}
                </button>
            </div>
        </div>
    </ng-container>
</div>

<ng-template #congrats>
    <div class="congrats-msg">
        {{ 'RENEW_SUB_SUCCESS_MSG' | translate: { paymentPlanName: congratsMessage.planDesc } }}
    </div>
</ng-template>
