import { IZone } from '../interfaces';

export interface IZoneState {
    zones: IZone[];
    selectedZone: IZone;
    showZoneTooltip: boolean;
}

export const initialZoneState: IZoneState = {
    zones: [],
    selectedZone: undefined,
    showZoneTooltip: false,
};
