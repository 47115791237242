<div class="dialog-wrapper">
    <div mat-dialog-content>
        <app-terms-and-conditions [isModalView]="true" [isShowTNTPrivacyPolicy]="true"></app-terms-and-conditions>
        <i class="icon" (click)="closeDialog(false)" [inlineSVG]="'#close-white'"></i>
    </div>
    <div mat-dialog-actions>
        <mat-checkbox class="form-field checkbox primary terms-and-conditions" color="primary" [checked]='data.checked'
            (click)="closeDialog(true)">
            {{'I_ACCEPT_THE' | translate}} {{'PRIVACY_POLICY' | translate}}
        </mat-checkbox>
    </div>
</div>
