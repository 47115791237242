import { createReducer, on } from '@ngrx/store';
import { ZonesActions } from '../actions/zones.actions';
import { initialZoneState } from '../state/zones.stete';

export const zonesReducer = createReducer(
    initialZoneState,
    on(ZonesActions.getZonesError, () => initialZoneState),
    on(ZonesActions.getZonesSuccess, (state, { response }) => ({
        ...state,
        zones: response.map((zone) => ({ ...zone, type: zone.type.toUpperCase() })),
    })),
    on(ZonesActions.getZoneByIDSuccess, (state, { response }) => ({
        ...state,
        selectedZone: response,
    })),
    on(ZonesActions.removeSelectedZone, (state) => ({
        ...state,
        selectedZone: undefined,
    })),
    on(ZonesActions.showZoneTooltip, (state) => ({
        ...state,
        showZoneTooltip: true,
    })),
    on(ZonesActions.hideZoneTooltip, (state) => ({
        ...state,
        showZoneTooltip: false,
    })),
);
