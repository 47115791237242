import { env } from '../assets/env';

export const environment = {
    production: false,
    whiteLabel: env.whiteLabel,
    baseApiUrl: env.baseApiUrl,
    baseRedirectUrl: env.baseRedirectUrl,
    clientId: env.clientId,
    clientSecret: env.clientSecret,
    googleMapApiKey: env.googleMapApiKey,
};
