import { Pipe, PipeTransform } from '@angular/core';
import { DateFormatService } from '../../store/services';
import { DatePipe } from '@angular/common';
import { DATE_FORMATS } from '../../store/constants/common.constants';

@Pipe({ name: 'dateFormat' })
export class DatFormatPipe implements PipeTransform {
    constructor(private dateFormatService: DateFormatService) {}
    transform(innerDate: Date | string | number, args?: string): Date | string {
        let appDateFormat = this.dateFormatService.dateFormat
            ? this.dateFormatService.dateFormat
            : DATE_FORMATS.DEFAULT;
        const pipe = new DatePipe('en-US');
        innerDate = new Date(+innerDate);

        if (args) {
            return pipe.transform(innerDate, `${appDateFormat}, ${args}`);
        } else {
            return pipe.transform(innerDate, appDateFormat);
        }
    }
}
