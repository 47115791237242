import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import {
    API_VERSION_1,
    API_VERSION_2,
    API_VERSION_3,
    API_VERSION_4,
    API_VERSION_5,
    BASE_URL,
} from '../../constants/api.constants';
import {
    ILogin,
    IAuthLogin,
    ISigupVerify,
    IUser,
    IDeviceShort,
    IZone,
    IDeviceHistory,
    IPlan,
    ISigupResendLink,
    IResetPassword,
    IShareDeviceLocationRequest,
    IShareDeviceLocationResponse,
    IBeep,
    IDeviceSettings,
    IRenewPlan,
    IDeviceByToken,
    IResetPasswordMatch,
    IContact,
    IContactPayload,
    IContactAssignments,
    IDeviceLocationEvent,
    IUserChangePassPayload,
    ISMSOptions,
    ISubscription,
    IDeviceLeicaStatus,
    IEventsResponse,
    ISocialUserData,
    IDeviceUsage,
} from '../../interfaces';
import { skin } from 'src/white-labels';
import { LocalStorageService } from '../local-storage.service';
import {
    DEFAULT_LANGUAGE,
    JAPANESE_LANGUAGE,
    SOCIAL_NETWORKS_PROVIDERS,
} from '../../constants/common.constants';
import { IDescendant } from '../../interfaces/descendants.interface';
import { SortDirection } from '@angular/material/sort';

@Injectable({ providedIn: 'root' })
export class ApiService {
    constructor(private http: HttpClient, private localStorage: LocalStorageService) {}

    login(requestData: ILogin): Observable<{}> {
        const requestURL =
            skin.whiteLabel === 'TAGANDTRACK'
                ? `${BASE_URL}/${API_VERSION_3}/user/login`
                : `${BASE_URL}/internal/${API_VERSION_2}/user/login`;

        return this.http.post(requestURL, requestData, {
            withCredentials: true,
        });
    }

    loginAuth(auth: IAuthLogin): Observable<{}> {
        return this.http.get(
            `${BASE_URL}/${API_VERSION_3}/oauth2/auth?client_id=${auth.client_id}&redirect_uri=${auth.redirect_uri}&response_type=${auth.response_type}&scope=${auth.scope}`,
            { withCredentials: true },
        );
    }

    loginToken(tokenReq: any): Observable<{}> {
        return this.http.post(`${BASE_URL}/${API_VERSION_3}/oauth2/token`, tokenReq);
    }

    logout(): Observable<{}> {
        return this.http.post(`${BASE_URL}/internal/${API_VERSION_1}/user/logout`, null, {
            withCredentials: true,
        });
    }

    createAccount(user: any): Observable<{}> {
        const requestURL =
            skin.whiteLabel === 'TAGANDTRACK'
                ? `${BASE_URL}/${API_VERSION_3}/user/signupWithUserIcon`
                : `${BASE_URL}/internal/${API_VERSION_2}/user/signupWithUserIcon`;

        return this.http.post(requestURL, user);
    }

    resendLink(resendData: ISigupResendLink): Observable<{}> {
        const apiVersion = skin.whiteLabel === 'TAGANDTRACK' ? API_VERSION_3 : `internal/${API_VERSION_2}`;
        return this.http.post(`${BASE_URL}/${apiVersion}/user/resend`, resendData);
    }

    resetPassword(data: IResetPassword): Observable<{}> {
        const requestURL = `${BASE_URL}/internal/${API_VERSION_1}/user/mobile/password/reset`;

        if (data.password) {
            return this.http.put(`${requestURL}`, data);
        }
        return this.http.post(`${requestURL}`, data);
    }

    resetPasswordMatch(data: IResetPasswordMatch): Observable<{}> {
        return this.http.post(`${BASE_URL}/internal/${API_VERSION_1}/user/mobile/password/reset/match`, data);
    }

    changePassword(payload: IUserChangePassPayload): Observable<{}> {
        return this.http.put(`${BASE_URL}/${API_VERSION_3}/user/password`, payload);
    }

    verifyAccount(verificationData: ISigupVerify): Observable<{}> {
        return this.http.post(`${BASE_URL}/${API_VERSION_3}/user/verify`, verificationData);
    }

    getUser(): Observable<IUser> {
        return this.http.get<IUser>(`${BASE_URL}/${API_VERSION_3}/user`);
    }

    setUserPreferences(accountID: number, preferences): Observable<IUser> {
        return this.http.put<IUser>(`${BASE_URL}/${API_VERSION_3}/accounts/${accountID}/update`, preferences);
    }

    getDescendants(accountID: number): Observable<IDescendant> {
        return this.http.get<IDescendant>(`${BASE_URL}/${API_VERSION_5}/accounts/${accountID}/descendants`);
    }

    removeDescendant(accountID: number, email: string): Observable<IDescendant> {
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
            body: [email],
        };
        return this.http.delete<IDescendant>(
            `${BASE_URL}/${API_VERSION_3}/accounts/${accountID}/parent`,
            options,
        );
    }

    getDevicesShort(accountID: number): Observable<IDeviceShort[]> {
        return this.http.get<IDeviceShort[]>(
            `${BASE_URL}/${API_VERSION_3}/accounts/${accountID}/devices/list/details/short`,
        );
    }

    getDeviceDetails(accountID: number, deviceID: number): Observable<{}> {
        return this.http.get(
            `${BASE_URL}/${API_VERSION_4}/accounts/${accountID}/devices/${deviceID}/details/full`,
        );
    }

    getDeviceDetailsByToken(token: string): Observable<IDeviceByToken> {
        return this.http.get<IDeviceByToken>(`${BASE_URL}/${API_VERSION_3}/public/devices/${token}`);
    }

    sendBeepToDevice(peyload: IBeep, accountID: number): Observable<{}> {
        return this.http.post(`${BASE_URL}/${API_VERSION_3}/accounts/${accountID}/devices/ops/beep`, peyload);
    }

    getDeviceContactAssignments(accountID: number, deviceID: number): Observable<{}> {
        return this.http.get(
            `${BASE_URL}/${API_VERSION_3}/accounts/${accountID}/devices/${deviceID}/contacts`,
        );
    }

    setDeviceContactAssignments(
        accountID: number,
        deviceID: number,
        dataRequest: IContactAssignments,
    ): Observable<{}> {
        return this.http.put(
            `${BASE_URL}/${API_VERSION_3}/accounts/${accountID}/devices/${deviceID}/contacts`,
            dataRequest,
        );
    }

    getZones(accountID: number): Observable<IZone[]> {
        const url = this.localStorage.getItem('COMPLEX_GZONES')
            ? `server-geozone/${BASE_URL}/${API_VERSION_1}/${accountID}/geozones`
            : `${BASE_URL}/${API_VERSION_3}/accounts/${accountID}/geozones?limit=1000&page=1`;

        return this.http.get<IZone[]>(url);
    }

    getZoneByID(accountID: number, geozoneID: number): Observable<IZone> {
        const url = this.localStorage.getItem('COMPLEX_GZONES')
            ? `server-geozone/${BASE_URL}/${API_VERSION_1}/${accountID}/geozoneWithDevices/${geozoneID}`
            : `${BASE_URL}/${API_VERSION_3}/accounts/${accountID}/geozoneWithDevices/${geozoneID}`;

        return this.http.get<IZone>(url);
    }

    createNewZone(accountID: number, zone: IZone): Observable<IZone> {
        const url = this.localStorage.getItem('COMPLEX_GZONES')
            ? `server-geozone/${BASE_URL}/${API_VERSION_1}/${accountID}/geozoneWithDevices`
            : `${BASE_URL}/${API_VERSION_3}/accounts/${accountID}/geozoneWithDevices`;

        return this.http.post<IZone>(url, zone);
    }

    updateZone(accountID: number, zone: IZone): Observable<IZone> {
        const url = this.localStorage.getItem('COMPLEX_GZONES')
            ? `server-geozone/${BASE_URL}/${API_VERSION_1}/${accountID}/geozoneWithDevices/${zone.id}`
            : `${BASE_URL}/${API_VERSION_3}/accounts/${accountID}/geozoneWithDevices/${zone.id}`;

        return this.http.put<IZone>(url, zone);
    }

    removeZone(accountID: number, zoneID: number): Observable<IZone> {
        const url = this.localStorage.getItem('COMPLEX_GZONES')
            ? `server-geozone/${BASE_URL}/${API_VERSION_1}/${accountID}/geozoneWithDevices/${zoneID}`
            : `${BASE_URL}/${API_VERSION_3}/accounts/${accountID}/geozones/${zoneID}`;

        return this.http.delete<IZone>(url);
    }

    getAssigedZones(deviceID: number): Observable<IZone[]> {
        return this.http.get<IZone[]>(
            `server-geozone/${BASE_URL}/${API_VERSION_1}/assigned/geozones/${deviceID}`,
        );
    }

    assignDevice(accountID: number, zoneID: number, deviceID: number): Observable<{}> {
        const url = this.localStorage.getItem('COMPLEX_GZONES')
            ? `server-geozone/${BASE_URL}/${API_VERSION_1}/${accountID}/geozones/assign/${zoneID}`
            : `${BASE_URL}/${API_VERSION_3}/accounts/${accountID}/geozones/assign/${zoneID}`;

        return this.http.put(url, [deviceID]);
    }

    unassignDevice(accountID: number, zoneID: number, deviceID: number): Observable<{}> {
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
            body: [deviceID],
        };

        const url = this.localStorage.getItem('COMPLEX_GZONES')
            ? `server-geozone/${BASE_URL}/${API_VERSION_1}/${accountID}/geozones/unassign/${zoneID}`
            : `${BASE_URL}/${API_VERSION_3}/accounts/${accountID}/geozones/assign/${zoneID}`;

        return this.http.delete(url, options);
    }

    getNotReadEvents(accountID: number, alarmType = 'all', deviceIDs: number[]): Observable<IEventsResponse> {
        return this.http.post<IEventsResponse>(
            `${BASE_URL}/${API_VERSION_3}/accounts/${accountID}/events/search?alarm_types=${alarmType}&read_status=unread`,
            { device_ids: deviceIDs },
        );
    }

    getCSVEvents(accountID: number, deviceIDs: number[]) {
        return this.http.post<{ file_url: string }>(
            `${BASE_URL}/${API_VERSION_3}/accounts/${accountID}/eventsSearchCsvExport`,
            { device_ids: deviceIDs },
        );
    }

    getEventsWithTypes(
        accoundID: number,
        alarmType: string,
        sort: string,
        order: SortDirection,
        page: number,
    ): Observable<any> {
        return this.http.get<any>(
            `${BASE_URL}/${API_VERSION_3}/accounts/${accoundID}/events?alarm_types=${alarmType}&sort=${sort}&order=${order}&page=${
                page + 1
            }`,
            { observe: 'response' as 'body' },
        );
    }

    getNotReadSOSEventsByDeviceID(accountID: number, deviceID: number): Observable<IEventsResponse> {
        return this.http.post<IEventsResponse>(
            `${BASE_URL}/${API_VERSION_3}/accounts/${accountID}/events/search?alarm_types=SOS&read_status=unread`,
            { device_ids: [deviceID] },
        );
    }

    getDeviceHistory(
        accountID: number,
        deviceID: number,
        from: number,
        to: number,
        types: number[],
    ): Observable<IDeviceHistory[]> {
        const apiVersion = skin.whiteLabel === 'TAGANDTRACK' ? API_VERSION_3 : API_VERSION_4;
        return this.http.get<IDeviceHistory[]>(
            `${BASE_URL}/${apiVersion}/accounts/${accountID}/devices/${deviceID}/history/details?from=${from}&to=${to}&limit=2000&page=1&types=${types}`,
        );
    }

    deleteDeviceLocationHistory(
        accountID: number,
        deviceID: number,
        from: number,
        to: number,
    ): Observable<{}> {
        return this.http.delete(
            `${BASE_URL}/${API_VERSION_3}/accounts/${accountID}/devices/${deviceID}/locationHistory?from=${from}&to=${to}`,
        );
    }

    valideteDeviceAssignments(deviceID: number, imei: number): Observable<{ assigned: string }> {
        return this.http.get<{ assigned: string }>(
            `${BASE_URL}/internal/${API_VERSION_1}/devices/${deviceID}/assigned?challenge=${imei}`,
        );
    }

    valideteDeviceAssignmentsEtoll(deviceID: number, imei: number): Observable<{ assigned: boolean }> {
        return this.http.get<{ assigned: boolean }>(
            `${BASE_URL}/internal/${API_VERSION_3}/devices/${0}/assigned?challenge=${imei}`,
        );
    }

    getDevicePlans(deviceID: number): Observable<IPlan> {
        return this.http.get<IPlan>(
            `${BASE_URL}/internal/${API_VERSION_1}/devices/${deviceID}/plans_group_nonce`,
        );
    }

    setDeviceToUser(accountID: number, deviceID: number, planID: number): Observable<{}> {
        return this.http.put(
            `${BASE_URL}/internal/${API_VERSION_1}/activation/accounts/${accountID}/devices/${deviceID}/plan/${planID}/activate/prepaid`,
            {},
        );
    }

    updateDeviceName(accountID: number, deviceID: number, name: string): Observable<{}> {
        return this.http.put(`${BASE_URL}/${API_VERSION_3}/accounts/${accountID}/devices/${deviceID}/name`, {
            name,
        });
    }

    setDeviceUsage(accountID: number, deviceID: number, usageInfo: string): Observable<{}> {
        return this.http.post(
            `${BASE_URL}/${API_VERSION_3}/accounts/${accountID}/devices/${deviceID}/usage/info?usageInfo=${usageInfo}`,
            null,
        );
    }

    setPetInfo(deviceUsageDTO: IDeviceUsage): Observable<{}> {
        return this.http.post(`pets/api/${API_VERSION_1}/pet`, deviceUsageDTO);
    }

    getPetInfo(deviceID): Observable<{}> {
        return this.http.get(`pets/api/${API_VERSION_1}/pet/deviceId/${deviceID}`);
    }

    shareDeviceLink(
        accountID: number,
        deviceID: number,
        duration: IShareDeviceLocationRequest,
    ): Observable<IShareDeviceLocationResponse> {
        return this.http.post<IShareDeviceLocationResponse>(
            `${BASE_URL}/${API_VERSION_3}/accounts/${accountID}/devices/${deviceID}/share`,
            duration,
        );
    }

    deactivateShareLink(accountID: number, deviceID: number): Observable<{}> {
        return this.http.delete(
            `${BASE_URL}/${API_VERSION_3}/accounts/${accountID}/devices/${deviceID}/share`,
        );
    }

    updateDeviceImage(accountID: number, deviceID: number, imageToUpload: File): Observable<{}> {
        const formData: FormData = new FormData();
        formData.append('file', imageToUpload);

        return this.http.post(
            `${BASE_URL}/${API_VERSION_3}/accounts/${accountID}/devices/${deviceID}/icon`,
            formData,
        );
    }

    updateDeviceIcon(accountID: number, deviceID: number, iconId: number): Observable<{}> {
        return this.http.put(`${BASE_URL}/${API_VERSION_3}/accounts/${accountID}/devices/${deviceID}/icon`, {
            icon_id: iconId,
        });
    }

    updateUserPhoto(accountID: number, imageToUpload: File): Observable<{}> {
        const formData: FormData = new FormData();
        formData.append('file', imageToUpload);

        return this.http.post(`${BASE_URL}/${API_VERSION_3}/user/${accountID}/icon`, formData);
    }

    getDevicesLocations(accountID: number): Observable<IDeviceLocationEvent[]> {
        return this.http.get<IDeviceLocationEvent[]>(
            `${BASE_URL}/${API_VERSION_3}/accounts/${accountID}/locations?comm_stat=1&limit=2500&page=1`,
        );
    }

    getDeviceLocation(accountID: number, deviceID: number): Observable<{}> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
        return this.http.get(
            `${BASE_URL}/${API_VERSION_4}/accounts/${accountID}/locations/filter?comm_stat=1&device_ids=${deviceID}`,
            { headers, responseType: 'text' },
        );
    }

    triggerDevicesLocations(accountID: number, devicesIDs: number[]): Observable<{}> {
        return this.http.post(`${BASE_URL}/${API_VERSION_3}/accounts/${accountID}/devices/ops/getLocation`, {
            devices: devicesIDs,
            forceGpsRead: true,
            sendGsmBeforeLock: true,
        });
    }

    setDeviceSettings(accountID: number, deviceID: number, settings: IDeviceSettings): Observable<{}> {
        return this.http.put(
            `${BASE_URL}/${API_VERSION_3}/accounts/${accountID}/devices/${deviceID}/settings`,
            settings,
        );
    }

    getRenewalPlans(deviceID: number): Observable<IPlan> {
        return this.http.get<IPlan>(
            `${BASE_URL}/internal/${API_VERSION_1}/devices/${deviceID}/default_renewal_plans_group_nonce`,
        );
    }

    getBrainTreeDropInToken(accountID: number, deviceID: number, planID: number): Observable<string> {
        const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');

        return this.http.get(
            `${BASE_URL}/internal/${API_VERSION_1}/activation/accounts/${accountID}/paypal/token?device_id=${deviceID}&plan_id=${planID}`,
            { headers, responseType: 'text' },
        );
    }

    setDeviceToTNTUser(accountID: number, deviceID: number, code: number): Observable<{}> {
        return this.http.put(
            `${BASE_URL}/internal/${API_VERSION_2}/accounts/${accountID}/devices/${deviceID}/activate/prepaid?challenge=${code}`,
            { code },
        );
    }

    updateNotificationStatus(accountID: number, eventID: number[] | number): Observable<{}> {
        return this.http.put(`${BASE_URL}/${API_VERSION_3}/accounts/${accountID}/events`, {
            eventIds: Array.isArray(eventID) ? eventID : [eventID],
            priority: 'NONE',
            read: true,
        });
    }

    deleteAllNotifications(accountID: number): Observable<{}> {
        return this.http.delete<IZone>(`${BASE_URL}/${API_VERSION_3}/accounts/${accountID}/events`);
    }

    getContacts(accountID: number): Observable<IContact[]> {
        return this.http.get<IContact[]>(`${BASE_URL}/${API_VERSION_3}/accounts/${accountID}/contacts`);
    }

    addContact(accountID: number, payload: IContactPayload): Observable<IContact> {
        return this.http.post<IContact>(
            `${BASE_URL}/${API_VERSION_3}/accounts/${accountID}/contacts`,
            payload,
        );
    }

    deleteContact(accountID: number, contactId: number): Observable<{}> {
        return this.http.delete<IContact>(
            `${BASE_URL}/${API_VERSION_3}/accounts/${accountID}/contacts/${contactId}`,
        );
    }

    updateContact(accountID: number, contactId: number, payload: IContactPayload): Observable<{}> {
        return this.http.put<IContact>(
            `${BASE_URL}/${API_VERSION_3}/accounts/${accountID}/contacts/${contactId}`,
            payload,
        );
    }

    getAddress(latitude: number, longitude: number, isDeviceListPage?: boolean): Observable<string> {
        let language = this.localStorage.getItem('language');
        if (language !== JAPANESE_LANGUAGE) {
            return this.getAddressByLocationIQ(latitude, longitude, language, isDeviceListPage);
        }

        return this.http.get(`/geolocation/${BASE_URL}/${API_VERSION_1}/geolocation/getaddress`, {
            responseType: 'text',
            params: {
                latitude: latitude,
                longitude: longitude,
                language: language ? language : DEFAULT_LANGUAGE,
            },
        });
    }

    getAddressByLocationIQ(
        latitude: number,
        longitude: number,
        language: string,
        isDeviceListPage?: boolean,
    ): Observable<string> {
        return this.http
            .get(
                `https://us1.locationiq.com/v1/reverse.php?key=a95b22fef8c52c&lat=${latitude}&lon=${longitude}&format=json&accept-language=${language}&normalizeaddress=1`,
            )
            .pipe(
                map((resonse: any) => {
                    if (isDeviceListPage) {
                        const address = resonse.address;
                        return `${address.road ? address.road : ''} ${
                            address.house_number ? address.house_number + ',' : ''
                        } ${address.city}, ${address.country}`;
                    }
                    return resonse['display_name'];
                }),
            );
    }

    getSmsOptions(countryCode: number): Observable<ISMSOptions[]> {
        return this.http.get<ISMSOptions[]>(`${BASE_URL}/${API_VERSION_3}/country/${countryCode}/smsoptions`);
    }

    activateSmsPlanBraintree(accountID: number, deviceID: number, payload: any): Observable<{}> {
        return this.http.post(
            `${BASE_URL}/internal/${API_VERSION_1}/activation/accounts/${accountID}/devices/${deviceID}/paypal/sms`,
            payload,
        );
    }

    activatePlan(accountID: number, deviceID: number, payload: any): Observable<{}> {
        return this.http.post(
            `${BASE_URL}/internal/${API_VERSION_1}/activation/accounts/${accountID}/devices/${deviceID}/paypal/activation`,
            payload,
        );
    }

    activateByCoupon(accountID: number, deviceID: number, coupon: string): Observable<{}> {
        return this.http.put(
            `${BASE_URL}/internal/${API_VERSION_1}/activation/accounts/${accountID}/devices/${deviceID}/activate/coupon`,
            { coupon: coupon },
        );
    }

    addNewApp(payload: any): Observable<{}> {
        return this.http.post(`${BASE_URL}/internal/${API_VERSION_1}/oauth2/clients`, payload);
    }

    getClients(): Observable<{}> {
        return this.http.get(`${BASE_URL}/internal/${API_VERSION_1}/oauth2/clients`);
    }

    deleteClient(clientID: number): Observable<{}> {
        return this.http.delete(`${BASE_URL}/internal/${API_VERSION_1}/oauth2/clients/${clientID}`);
    }

    getSubscriptions(accountID: number): Observable<ISubscription[]> {
        return this.http.get<ISubscription[]>(
            `${BASE_URL}/${API_VERSION_5}/accounts/${accountID}/devices/details`,
        );
    }

    getAccountSettings(accountID: number): Observable<{}> {
        return this.http.get(`${BASE_URL}/${API_VERSION_3}/accounts/${accountID}`);
    }

    setPreciseLocation(accountID: number, payload): Observable<{}> {
        return this.http.post(
            `${BASE_URL}/internal/${API_VERSION_1}/accounts/${accountID}/accurate?setAccurate=${payload}`,
            null,
        );
    }

    addSubAccountInvitation(accountID: number, payload): Observable<{}> {
        return this.http.post(`${BASE_URL}/${API_VERSION_3}/accounts/${accountID}/parentrequest`, payload);
    }

    addSubAccountNewUser(payload): Observable<{}> {
        return this.http.post(`${BASE_URL}/internal/${API_VERSION_1}/accounts/newsub`, payload);
    }

    checkLeicaStatus(accountID: number, deviceID: number): Observable<IDeviceLeicaStatus> {
        return this.http.get<IDeviceLeicaStatus>(
            `${BASE_URL}/${API_VERSION_3}/accounts/${accountID}/devices/${deviceID}/leica/status`,
        );
    }

    updateLeicaStatus(accountID: number, deviceID: number, payload): Observable<{}> {
        return this.http.put(
            `${BASE_URL}/${API_VERSION_3}/accounts/${accountID}/devices/${deviceID}/leica/status`,
            payload,
        );
    }

    checkSomitomoStatus(accountID: number, deviceID: number): Observable<IDeviceLeicaStatus> {
        return this.http.get<IDeviceLeicaStatus>(
            `${BASE_URL}/${API_VERSION_4}/accounts/${accountID}/devices/${deviceID}/somitomo/status`,
        );
    }

    triggerSomitomoStatusUpdate(accountID: number, deviceID: number): Observable<{}> {
        return this.http.post(
            `${BASE_URL}/${API_VERSION_3}/accounts/${accountID}/sendStatusMessage/${deviceID}`,
            {},
        );
    }

    updateSomitomoStatus(accountID: number, deviceID: number, payload): Observable<{}> {
        return this.http.put(
            `${BASE_URL}/${API_VERSION_3}/accounts/${accountID}/devices/${deviceID}/somitomo/status`,
            payload,
        );
    }

    renewPlan(accountID: number, deviceID: number, dto: IRenewPlan): Observable<{}> {
        return this.http.post<IShareDeviceLocationResponse>(
            `${BASE_URL}/internal/${API_VERSION_1}/activation/accounts/${accountID}/devices/${deviceID}/paypal/renew`,
            dto,
        );
    }

    renewPrepaidPlan(accountID: number, deviceID: number, planID: number): Observable<{}> {
        return this.http.put(
            `${BASE_URL}/internal/${API_VERSION_1}/activation/accounts/${accountID}/devices/${deviceID}/plan/${planID}/activate/prepaid`,
            {},
        );
    }

    renewPlanByCoupon(accountID: number, deviceID: number, coupon: string): Observable<{}> {
        return this.http.put(
            `${BASE_URL}/internal/${API_VERSION_1}/activation/accounts/${accountID}/devices/${deviceID}/renew/coupon`,
            { coupon: coupon },
        );
    }

    signInWithSocialNetwork(userData: ISocialUserData, socialNetwork: string): Observable<{}> {
        const apiVersion = socialNetwork === SOCIAL_NETWORKS_PROVIDERS.apple ? API_VERSION_2 : API_VERSION_1;
        return this.http.post(`${BASE_URL}/${apiVersion}/social/login/${socialNetwork}`, userData);
    }

    deleteMyAccount(userEmail: string): Observable<{}> {
        return this.http.post(`${BASE_URL}/${API_VERSION_3}/accounts/markedBlockDeleted`, {
            email: userEmail,
        });
    }

    getDeviceSubscriptionDetails(accountID: number, deviceID: number): Observable<{}> {
        return this.http.get(
            `${BASE_URL}/${API_VERSION_3}/accounts/${accountID}/devices/details/graphql?device_ids=${deviceID}&requireDataList=all_active_plans, device_info, device_details_plan, sms_details, extra_param:device_status;icon_url;icon_id`,
        );
    }

    getSBDevicesSubscriptions(accountID: number): Observable<ISubscription[]> {
        return this.http.get<ISubscription[]>(
            `${BASE_URL}/${API_VERSION_5}/accounts/${accountID}/devices/details_new?only_sub_accounts_devices=true`,
        );
    }
}
