import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-delete-account-info-dialog',
    templateUrl: './delete-account-info-dialog.component.html',
    styleUrls: ['./delete-account-info-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeleteAccountInfoDialogComponent {
    constructor(
        public dialogRef: MatDialogRef<DeleteAccountInfoDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data,
    ) {}

    close(): void {
        this.dialogRef.close();
    }

    confirm() {
        this.dialogRef.close(true);
    }
}
