import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { IClientPayload } from '../interfaces';

const prefix = '[Clients]';

const getClients = createAction(`${prefix} Get Clients`);
const getClientsSuccess = createAction(`${prefix} Get Clients Success`, props<{ response: any }>());
const getClientsError = createAction(`${prefix} Get Clients Error`, props<{ error: HttpErrorResponse }>());

const addClient = createAction(`${prefix} Add Client`, props<{ payload: IClientPayload }>());
const addClientSuccess = createAction(`${prefix} Add Client Success`, props<{ msg: string }>());
const addClientError = createAction(`${prefix} Add Client Error`, props<{ error: HttpErrorResponse }>());

const deleteClient = createAction(`${prefix} Delete Client`, props<{ payload: any }>());
const deleteClientSuccess = createAction(`${prefix} Delete Client Success`, props<{ msg: string }>());
const deleteClientError = createAction(
    `${prefix} Delete Client Error`,
    props<{ error: HttpErrorResponse }>(),
);

export const ClientsActions = {
    getClients,
    getClientsSuccess,
    getClientsError,

    addClient,
    addClientSuccess,
    addClientError,

    deleteClient,
    deleteClientSuccess,
    deleteClientError,
};
