import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';

const prefix = '[Descendants API]';

const getDescendants = createAction(`${prefix} Get Descendants`);
const getDescendantsSuccess = createAction(`${prefix} Get Descendants Success`, props<{ response: any }>());
const getDescendantsError = createAction(
    `${prefix} Get Descendants Error`,
    props<{ error: HttpErrorResponse }>(),
);

const removeDescendant = createAction(`${prefix} Remove Descendant`, props<{ email: string }>());
const removeDescendantSuccess = createAction(`${prefix} Remove Descendant Success`);
const removeDescendantError = createAction(
    `${prefix} Remove Descendant Error`,
    props<{ error: HttpErrorResponse }>(),
);

const clearDescendants = createAction(`${prefix} Clear Descendants`);

export const DescendantsActions = {
    getDescendants,
    getDescendantsSuccess,
    getDescendantsError,

    removeDescendant,
    removeDescendantSuccess,
    removeDescendantError,

    clearDescendants,
};
