import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { skin } from 'src/white-labels';

@Component({
    selector: 'app-create-account-stepper',
    templateUrl: './create-account-stepper.component.html',
    styleUrls: ['./create-account-stepper.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateAccountStepperComponent implements OnInit {
    @Input() selectedIndex: number;
    skin = skin;

    constructor() {}

    ngOnInit(): void {}
}
