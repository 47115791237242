<div class="snackbar-wrapper">
    <div class="snackbar-block icon-wrap">
        <i class="icon" [inlineSVG]="getIcon"></i>
    </div>

    <div class="snackbar-block message" rtl>
        {{ data.message | translate }}
    </div>

    <div class="snackbar-block close-button-wrap">
        <i class="close-icon" [inlineSVG]="'#close'" (click)="closeSnackBar()"></i>
    </div>
</div>
