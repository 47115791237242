import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
    selector: 'app-delete-account-contact-us',
    templateUrl: './delete-account-contact-us.component.html',
    styleUrls: ['./delete-account-contact-us.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeleteAccountContactUsComponent {
    constructor(
        private router: Router,
        public dialogRef: MatDialogRef<DeleteAccountContactUsComponent>,
        @Inject(MAT_DIALOG_DATA) public data,
    ) {}

    close(): void {
        this.dialogRef.close();
    }

    contactUs() {
        this.dialogRef.close();
        this.router.navigate(['/about/contact-support']);
    }
}
