import { ChangeDetectionStrategy, Component } from '@angular/core';
import { skin } from 'src/white-labels';
@Component({
    selector: 'app-public',
    templateUrl: './public.component.html',
    styleUrls: ['./public.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PublicComponent {
    skin = skin;
    photo = `white-labels/${skin.whiteLabel}/public_bg.png`;
}
