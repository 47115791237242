import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Injectable({
    providedIn: 'root',
})
export class PasswordValidatorService {
    passwordsValidator(password: string, passwordConfirmed: string) {
        return (formGroup: FormGroup) => {
            const control = formGroup.controls[password];
            const matchingControl = formGroup.controls[passwordConfirmed];
            if (matchingControl.errors && !matchingControl.errors.confirmedValidator) {
                return null;
            }

            if (control.value !== matchingControl.value) {
                matchingControl.setErrors({ confirmedValidator: true });
                return { confirmedValidator: true };
            } else {
                matchingControl.setErrors(null);
                return null;
            }
        };
    }
}
