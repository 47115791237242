import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
    REX_EXP_DIGIT,
    REX_EXP_LOWER_CASE,
    REX_EXP_SPECIAL_CHAR,
    REX_EXP_UPPER_CASE,
} from '../../store/constants/common.constants';
import { IPasswordHints } from '../../store/interfaces/password-hint.interface';
import { skin } from 'src/white-labels';

@Component({
    selector: 'app-password-hints',
    templateUrl: './password-hints.component.html',
    styleUrls: ['./password-hints.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PasswordHintsComponent implements OnInit {
    @Input() formControlValue;
    passwordHints$: Observable<IPasswordHints>;
    skin = skin;

    constructor() {}

    ngOnInit(): void {
        this.passwordHints$ = this.formControlValue.valueChanges.pipe(
            map((value: string) =>
                skin.whiteLabel !== 'TAGANDTRACK'
                    ? {
                          correctLength: value.length >= 6 && value.length <= 24,
                          containUpperCase: REX_EXP_UPPER_CASE.test(value),
                          containDigit: REX_EXP_DIGIT.test(value),
                      }
                    : {
                          correctLength: value.length >= 8 && value.length <= 24,
                          containUpperCase: REX_EXP_UPPER_CASE.test(value),
                          containLowerCase: REX_EXP_LOWER_CASE.test(value),
                          containDigit: REX_EXP_DIGIT.test(value),
                          containSpecialChar: REX_EXP_SPECIAL_CHAR.test(value),
                      },
            ),
        );
    }
}
