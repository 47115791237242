import { ChangeDetectionStrategy, Component } from '@angular/core';
import { skin } from 'src/white-labels';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LocaleService } from '../../store/services';
import { selectUser } from '../../store/selectors/user.selector';
import { Store } from '@ngrx/store';
import { IAppState } from '../../store/state/app.state';

export interface navLink {
    label: string;
    link: string;
}

@Component({
    selector: 'app-common-support',
    templateUrl: './common-support.component.html',
    styleUrls: ['./common-support.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommonSupportComponent {
    skin = skin;
    navLinks: navLink[];
    user$ = this.store.select(selectUser);

    constructor(
        private route: ActivatedRoute,
        private translate: TranslateService,
        private localeService: LocaleService,
        private store: Store<IAppState>,
    ) {
        route.firstChild.params.subscribe((params) => {
            if (params.lang) {
                this.localeService.changeLanguageFromUrl(params.lang);
            }
        });
        this.navLinks = [
            {
                label: 'FAQS',
                link: './faqs',
            },
            {
                label: 'CONTACT_US',
                link: './contact-support',
            },
            {
                label: 'USER_GUIDE',
                link: './user-guide',
            },
            {
                label: 'TERMS_AND_CONDITIONS',
                link: './terms-and-conditions',
            },
            {
                label: 'PRIVACY_POLICY',
                link: './privacy-policy',
            },
        ];
    }
}
