<mat-horizontal-stepper rtl class="stepper" [ngClass]="{trackimo: skin.whiteLabel !== 'TAGANDTRACK'}"
    [selectedIndex]="selectedIndex" [linear]="true" #stepper>
    <mat-step [editable]="false" [completed]="selectedIndex >= 1">
        <ng-template matStepLabel>{{ 'ACCOUNT_DETAILS' | translate }}</ng-template>
    </mat-step>

    <mat-step [editable]="false" [completed]="selectedIndex > 1" *ngIf="skin.whiteLabel === 'TAGANDTRACK'">
        <ng-template matStepLabel>{{ 'VERIFY_ACCOUNT' | translate }}</ng-template>
    </mat-step>

    <mat-step [editable]="false">
        <ng-template matStepLabel>{{ 'CONFIRMATION' | translate }}</ng-template>
    </mat-step>
</mat-horizontal-stepper>
