import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PrivacyAndPolicyDialog } from '../privacy-and-policy-dialog/privacy-and-policy-dialog';

@Component({
    selector: 'app-edit-photo-dialog',
    templateUrl: './edit-photo-dialog.component.html',
    styleUrls: ['./edit-photo-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditPhotoDialogComponent {
    isFileUploaded: boolean;
    imageFile: File;
    previouslySelectedFile: File;

    constructor(
        public dialogRef: MatDialogRef<PrivacyAndPolicyDialog>,
        @Inject(MAT_DIALOG_DATA) public data,
    ) {}

    onFileSelect(file: File): void {
        if (file instanceof File) {
            this.isFileUploaded = true;
            this.imageFile = file;
        }

        if (!this.isFileUploaded) {
            this.imageFile = file;
            this.previouslySelectedFile = file;
        }
    }

    onRemovePhoto() {
        this.isFileUploaded = false;
        this.imageFile = this.previouslySelectedFile;
    }

    closeDialog(): void {
        this.dialogRef.close();
    }

    onSave() {
        this.dialogRef.close(this.imageFile);
    }
}
