<div class="dialog-wrapper" rtl>
    <div mat-dialog-title>
        <h1 class="title" [class.title-delete-type]="data.deleteType">{{ data.title | translate }}</h1>
    </div>
    <div class="content" mat-dialog-content *ngIf="data.content">
        <div>{{ data.content }}</div>
    </div>
</div>

<div mat-dialog-actions>
    <div class="nav-container">
        <ng-container *ngIf="!data.isHideButtons; else confirmBtnsTemplate">
            <button class="btn basic" (click)="close()" mat-flat-button>{{ 'CANCEL' | translate }}</button>
            <button class="btn primary" (click)="confirm()" mat-flat-button>
                {{ (data.deleteBtnTxt ? 'delete' : 'CONFIRM') | translate }}
            </button>
        </ng-container>
        <ng-template #confirmBtnsTemplate>
            <button class="btn primary" (click)="close()" mat-flat-button>{{ 'OK' | translate }}</button>
        </ng-template>
    </div>
</div>
