import { CommonModule } from '@angular/common';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { InlineSVGModule } from 'ng-inline-svg';

import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { StatusLabelComponent } from './status-label/status-label.component';
import { LanguageSelectComponent } from './language-select/language-select.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatTabsModule } from '@angular/material/tabs';
import { CookiesService } from '../store/services/cookie/cookie.service';
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { CookieBarComponent } from './cookie-bar/cookie-bar.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatMenuModule } from '@angular/material/menu';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { MatDialogModule } from '@angular/material/dialog';
import { TermsAndConditionsDialog } from '../dialogs/terms-and-conditions-dialog/terms-and-conditions-dialog';
import { PrivacyAndPolicyDialog } from '../dialogs/privacy-and-policy-dialog/privacy-and-policy-dialog';
import { PrivacyPolicyComponent } from '../public/privacy-policy/privacy-policy.component';
import { TermsAndConditionsComponent } from '../public/terms-and-conditions/terms-and-conditions.component';
import { PasswordHintsComponent } from './password-hints/password-hints.component';
import { PasswordValidatorService } from '../store/services/password-validator/password-validator.service';
import { BackBtnComponent } from './back-btn/back-btn.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { EditPhotoComponent } from './edit-photo/edit-photo.component';
import { EditPhotoDialogComponent } from '../dialogs/edit-photo-dialog/edit-photo-dialog.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { WINDOW_PROVIDERS } from '../store/providers/window-provider';
import { GoogleMapsModule } from '@angular/google-maps';
import { ConfirmDialogComponent } from '../dialogs/confirm-dialog/confirm-dialog.component';
import { UpdatePlanDialogComponent } from '../dialogs/update-plan-dialog/update-plan-dialog.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { TextMaskModule } from 'angular2-text-mask';
import { AddContactDialogComponent } from '../dialogs/add-contact-dialog/add-contact-dialog.component';
import { PhoneInputComponent } from './phone-input/phone-input.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { TooltipModule } from 'ng2-tooltip-directive';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { FormatCountdownTimePipe } from './pipes/format-countdown.pipe';
import { SearchFilterPipe } from './pipes/search-filter.pipe';
import { TextAlertLimitDialogComponent } from '../dialogs/text-alert-limit-dialog/text-alert-limit-dialog.component';
import { PaymentCongratsMsgComponent } from './payment-congrats-msg/payment-congrats-msg.component';
import { DevicePreviewComponent } from '../dashboard/devices/device-preview/device-preview.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { SnackbarComponent } from './snackbar/snackbar.component';
import { TourMatMenuModule } from 'ngx-ui-tour-md-menu';
import { AddSubAccountDialogComponent } from '../dialogs/add-sub-account-dialog/add-sub-account-dialog.component';
import { StatusDialogComponent } from '../dialogs/status-dialog/status-dialog.component';
import { NoGPSSignalDialogComponent } from '../dialogs/no-gps-dialog/no-gps-dialog.component';
import { NoGpsDialogInfoComponent } from '../dialogs/no-gps-dialog-info/no-gps-dialog-info.component';
import { ScheduleSleepTurnOffComponent } from '../dialogs/schedule-sleep-turn-off/schedule-sleep-turn-off.component';
import { OwlMomentDateTimeModule } from '@danielmoncada/angular-datetime-picker-moment-adapter';
import { OwlDateTimeModule } from '@danielmoncada/angular-datetime-picker';
import { OrderByPipe } from './pipes/order-by-pipe.pipe';
import { DatFormatPipe } from './pipes/date-format.pipe';
import { RtlSupportDirective } from './directives/rtl.directive';
import { AutocompletePositionDirective } from './directives/scrollMatSelect.directive';
import { FeatureFlagsDialogComponent } from '../dialogs/feature-flags-dialog/feature-flags-dialog.components';
import { GoogleplaceDirective } from './directives/googleplace.directive';
import { DeviceBatteryComponent } from './device-battery/device-battery.component';
import { DeleteAccountInfoDialogComponent } from '../dialogs/delete-account-info-dialog/delete-account-info-dialog.component';
import { DeleteAccountContactUsComponent } from '../dialogs/delete-account-contact-us/delete-account-contact-us.component';

function getIconPath() {
    const isPlus12 = window.location.pathname.includes('plus12');
    return `${isPlus12 ? '/plus12' : ''}/assets/images/sprite.svg`;
}

@NgModule({
    imports: [
        CommonModule,
        InlineSVGModule.forRoot({ baseUrl: getIconPath() }),
        MatSelectModule,
        FormsModule,
        TranslateModule,
        MatExpansionModule,
        RouterModule,
        MatMenuModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatCheckboxModule,
        MatRadioModule,
        GoogleMapsModule,
        TextMaskModule,
        MatAutocompleteModule,
        MatToolbarModule,
        MatIconModule,
        MatProgressSpinnerModule,
        NgxMatSelectSearchModule,
        TooltipModule,
        MatProgressBarModule,
        ScrollingModule,
        TourMatMenuModule,
        MatSlideToggleModule,
        OwlDateTimeModule,
        OwlMomentDateTimeModule,
    ],
    declarations: [
        StatusLabelComponent,
        LanguageSelectComponent,
        BreadcrumbsComponent,
        CookieBarComponent,
        FooterComponent,
        HeaderComponent,
        TermsAndConditionsDialog,
        PrivacyAndPolicyDialog,
        EditPhotoDialogComponent,
        ConfirmDialogComponent,
        UpdatePlanDialogComponent,
        AddContactDialogComponent,
        TermsAndConditionsComponent,
        PrivacyPolicyComponent,
        PasswordHintsComponent,
        EditPhotoComponent,
        BackBtnComponent,
        PhoneInputComponent,
        FormatCountdownTimePipe,
        SearchFilterPipe,
        TextAlertLimitDialogComponent,
        PaymentCongratsMsgComponent,
        DevicePreviewComponent,
        SnackbarComponent,
        AddSubAccountDialogComponent,
        StatusDialogComponent,
        NoGPSSignalDialogComponent,
        NoGpsDialogInfoComponent,
        DeleteAccountInfoDialogComponent,
        DeleteAccountContactUsComponent,
        ScheduleSleepTurnOffComponent,
        OrderByPipe,
        DatFormatPipe,
        RtlSupportDirective,
        GoogleplaceDirective,
        AutocompletePositionDirective,
        FeatureFlagsDialogComponent,
        DeviceBatteryComponent,
    ],
    exports: [
        CommonModule,
        HttpClientModule,
        HttpClientJsonpModule,
        FormsModule,
        RouterModule,
        ReactiveFormsModule,
        InlineSVGModule,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        MatIconModule,
        MatCheckboxModule,
        MatRadioModule,
        MatSlideToggleModule,
        MatSelectModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatStepperModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        MatTabsModule,
        MatTooltipModule,
        MatSnackBarModule,
        MatProgressSpinnerModule,
        TranslateModule,
        MatExpansionModule,
        MatProgressBarModule,
        ClipboardModule,
        ScrollingModule,
        OwlDateTimeModule,
        OwlMomentDateTimeModule,
        LanguageSelectComponent,
        StatusLabelComponent,
        BreadcrumbsComponent,
        CookieBarComponent,
        MatMenuModule,
        FooterComponent,
        HeaderComponent,
        MatDialogModule,
        TermsAndConditionsDialog,
        PrivacyAndPolicyDialog,
        EditPhotoDialogComponent,
        ConfirmDialogComponent,
        AddContactDialogComponent,
        TermsAndConditionsComponent,
        UpdatePlanDialogComponent,
        PrivacyPolicyComponent,
        PasswordHintsComponent,
        EditPhotoComponent,
        BackBtnComponent,
        GoogleMapsModule,
        MatAutocompleteModule,
        TextMaskModule,
        PhoneInputComponent,
        TooltipModule,
        FormatCountdownTimePipe,
        SearchFilterPipe,
        TextAlertLimitDialogComponent,
        PaymentCongratsMsgComponent,
        DevicePreviewComponent,
        SnackbarComponent,
        TourMatMenuModule,
        AddSubAccountDialogComponent,
        StatusDialogComponent,
        NoGPSSignalDialogComponent,
        NoGpsDialogInfoComponent,
        DeleteAccountInfoDialogComponent,
        DeleteAccountContactUsComponent,
        ScheduleSleepTurnOffComponent,
        OrderByPipe,
        DatFormatPipe,
        RtlSupportDirective,
        GoogleplaceDirective,
        AutocompletePositionDirective,
        FeatureFlagsDialogComponent,
        DeviceBatteryComponent,
    ],
    providers: [WINDOW_PROVIDERS, MatDatepickerModule, CookiesService, PasswordValidatorService],
})
export class SharedModule {}
