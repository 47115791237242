<div class="dialog-wrapper">
    <div class="dialog-title-wrapper" mat-dialog-title>
        <h1 class="title" rtl>{{ 'SMS_PURCHASE' | translate }}</h1>
        <div class="alerts-usage-info" rtl>
            <ng-container *ngIf="!isFinalStep; else finalStepText">
                {{
                    'USED_TEXT_ALERTS_IN_MONTH'
                        | translate: { usedCount: data.smsUsed, totalCount: data.smsLimit }
                }}
            </ng-container>
            <ng-template #finalStepText>
                {{ 'PLAN_FOR_AMOUNT_SMS' | translate: { smsAmount: selectedSMSOption.smsLimit } }}
            </ng-template>
        </div>
    </div>

    <div class="dialog-content-wrapper" mat-dialog-content>
        <ng-container *ngIf="!isFinalStep; else finalStepPayment">
            <div class="increase-action-text" rtl>
                {{ 'INCREASE_YOUR_MONTHLY_TEXT_ALERT_LIMIT' | translate }}
            </div>
            <div class="ta-selection-wrapper" rtl>
                <mat-form-field class="code-selection-form" appearance="outline">
                    <mat-label>{{ 'COUNTRY_CODE' | translate }}</mat-label>
                    <span
                        *ngIf="singleSelect.value"
                        class="flag-icon flag-icon-squared flag-icon-{{ singleSelect.value[1] }}"
                        matPrefix
                    ></span>
                    <mat-select
                        (selectionChange)="onSelectionChange($event)"
                        [formControl]="countryCodeCtrl"
                        placeholder="{{ 'CHOOSE_A_COUNTRY_CODE_FOR_TEXT_ALERTS' | translate }}"
                        #singleSelect
                    >
                        <mat-option rtl>
                            <ngx-mat-select-search
                                [formControl]="countryFilterCtrl"
                                placeholderLabel="{{ 'Search' | translate }} ..."
                            >
                            </ngx-mat-select-search>
                        </mat-option>

                        <mat-option *ngFor="let country of filteredCountryCodes | async" [value]="country" rtl>
                            <span [ngClass]="'flag-icon flag-icon-squared flag-icon-' + country[1]"></span>
                            {{ country[0] }} (+{{ country[2] }})
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="sms-options-wrapper" *ngIf="smsOptions?.length">
                <mat-radio-group class="sms-options-radio-group">
                    <mat-radio-button
                        class="sms-option"
                        color="primary"
                        *ngFor="let option of smsOptions; let i = index"
                        [value]="option"
                        [checked]="option.isChecked"
                        (change)="radioChange($event, option)"
                    >
                        <div class="left-side">{{ option.smsLimit }} {{ 'SMS_MONTHLY' | translate }}</div>
                        <div class="right-side" [class.selected]="option.isChecked">
                            {{ option.price }} {{ option.currency }}
                        </div>
                    </mat-radio-button>
                </mat-radio-group>
            </div>
            <div class="sms-options-wrapper" *ngIf="smsOptions && !smsOptions?.length">
                {{ 'NO_SMS_OPTIONS_FIND' | translate }}
            </div>
        </ng-container>
        <ng-template #finalStepPayment>
            <ng-container *ngIf="(paymentStatus$ | async)?.labelType !== 'success'; else successMsg">
                <app-status-label
                    *ngIf="paymentStatus$ | async"
                    [statusLabel]="paymentStatus$ | async"
                ></app-status-label>
                <div
                    id="#dropin-container"
                    class="dropin-container"
                    style="position: relative"
                    [ngClass]="{
                        success:
                            (paymentStatus$ | async)?.labelType === 'success' ||
                            (paymentProcessing$ | async) === true
                    }"
                ></div>
            </ng-container>
            <ng-template #successMsg>
                <app-payment-congrats-msg
                    [congratsMessage]="congratsMessage"
                    [isSMS]="true"
                ></app-payment-congrats-msg>
            </ng-template>
        </ng-template>
    </div>

    <div class="dialog-content-actions" mat-dialog-actions>
        <div class="nav-container">
            <button
                *ngIf="(paymentStatus$ | async)?.labelType !== 'success'"
                class="btn basic"
                (click)="close()"
                mat-flat-button
            >
                {{ 'CANCEL' | translate }}
            </button>

            <button
                *ngIf="!isFinalStep"
                class="btn primary"
                [disabled]="!isBrainTreeToken && !selectedSMSOption && !smsOptions?.length"
                (click)="goToPayment()"
                mat-flat-button
            >
                {{ 'GOTO_PAYMENT' | translate }}
            </button>

            <button
                *ngIf="isBrainTreeToken && (paymentStatus$ | async)?.labelType !== 'success'"
                [disabled]="paymentProcessing$ | async"
                class="btn primary"
                id="#submit-button"
                (click)="payForSms()"
                mat-flat-button
            >
                <ng-container *ngIf="(paymentProcessing$ | async) !== true; else spinner">
                    {{ 'PAY' | translate }}
                </ng-container>

                <ng-template #spinner>
                    <mat-spinner class="spinner" diameter="30"></mat-spinner>
                </ng-template>
            </button>

            <button
                *ngIf="isBrainTreeToken && (paymentStatus$ | async)?.labelType === 'success'"
                class="btn primary"
                (click)="closeWithConfirm()"
                mat-flat-button
            >
                {{ 'GOT_IT' | translate }}
            </button>
        </div>
    </div>
</div>
