<ng-container *ngIf="skin.whiteLabel !== 'MYTRACKI'">
    <div class="device-imgs-container" *ngIf="!isHiddenDevicesBar">
        <div
            class="device-item"
            [ngClass]="{ active: device.isSelected }"
            *ngFor="let device of devicesTypes"
            (click)="selectDeviceImg(device)"
        >
            <div class="device-img">
                <i class="icon" [inlineSVG]="device.icon"></i>
            </div>
            <p>{{ device.device_name }}</p>
        </div>
    </div>
</ng-container>

<app-status-label *ngIf="isMaxSizeError" [statusLabel]="statusLabel"> </app-status-label>

<div class="drop-container" (drop)="onDrop($event)" (dragover)="onDragOver($event)">
    <i class="icon" [inlineSVG]="'#upload'" *ngIf="!imgURL"></i>

    <div class="img-container">
        <img [src]="imgURL" *ngIf="imgURL" />
        <span class="icon-wrapp" *ngIf="imgURL" (click)="onRemovePhoto()">
            <i class="icon no-fill remove-icon" [inlineSVG]="'#trash'"></i>
        </span>
    </div>

    <p>{{ 'DRAG_AND_DROP_IMAGE_HERE' | translate }}</p>

    <input type="file" accept="image/*" hidden="true" (change)="onFileSelect($event)" #file />
    <button class="btn primary-light" (click)="file.click()" mat-flat-button>
        {{ 'BROWSE_FILES' | translate }}
    </button>
</div>
