import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { WindowService } from '../../store/services';
import { skin } from 'src/white-labels';

@Component({
    selector: 'app-download-app',
    templateUrl: './download-app.component.html',
    styleUrls: ['./download-app.component.scss'],
})
export class DownloadAppComponent implements OnInit {
    skin = skin;
    constructor(private route: ActivatedRoute, private router: Router, private window: WindowService) {}

    ngOnInit() {
        if (this.route.routeConfig.path === 'tnt_download') {
            if (/android/i.test(navigator.userAgent)) {
                this.window.getWindow.location.href =
                    'https://play.google.com/store/apps/details?id=com.trackimo.tagandtrack';
            } else if (/iPad|iPhone|iPod|iPad/.test(navigator.userAgent)) {
                this.window.getWindow.location.href =
                    'https://apps.apple.com/app/vodafone-business-tag-track/id1477489342';
            } else {
                this.window.getWindow.location.href = 'https://tagandtrack.iot.vodafone.com';
            }
        } else if (this.route.routeConfig.path === 'download') {
            if (/android/i.test(navigator.userAgent)) {
                this.window.getWindow.location.href =
                    'https://play.google.com/store/apps/details?id=com.trackimo.app2';
            } else if (/iPad|iPhone|iPod|iPad/.test(navigator.userAgent)) {
                this.window.getWindow.location.href = 'https://apps.apple.com/us/app/trackimo/id1515561581';
            } else {
                this.window.getWindow.location.href = 'https://plus.trackimo.com/';
            }
        }
    }
}
