<section>
    <app-header></app-header>
    <google-map height="100%" width="100%" *ngIf="mapApiLoaded" #GoogleMap></google-map>

    <div class="device-info" *ngIf="device$ | async as device">
        <div class="device-info-head">
            <h5>
                <i class="icon no-fill icon-map-point" [inlineSVG]="'#map-point'"></i>
                {{ device.deviceName ? device.deviceName : device.deviceId }}
            </h5>
            <div class="date" *ngIf="device?.lastUpdated">
                <span class="date-suffix">{{ 'DATE' | translate }}:</span>
                <b> {{ device?.lastUpdated | dateFormat: 'HH:mm' }}</b>
                <h3></h3>
            </div>
        </div>

        <div class="location-info">
            <p class="location">{{ address$ | async }}</p>
            <span class="geo">Lat: {{ device.geoLocation.latitude }}</span>
            <span class="geo">Lat: {{ device.geoLocation.longitude }}</span>
        </div>

        <div class="device-info-footer">
            <div class="item" *ngIf="device.batteryLevel">
                <i class="icon no-fill" [inlineSVG]="'#battery'"></i>{{ device.batteryLevel }}%
            </div>
            <div class="item" *ngIf="device.geoLocation.type">
                <i class="icon" [inlineSVG]="'#gps'"></i>{{ device.geoLocation.type }}
            </div>
        </div>
    </div>
</section>