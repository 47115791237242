import { createSelector } from '@ngrx/store';
import { IAppState } from '../state/app.state';
import { IUnreadEventsState } from '../state/unread-events.state';

export const selectFeature = (appState: IAppState) => appState.unreadEvents;

export const selectUnreadEventsList = createSelector(
    selectFeature,
    (appState: IUnreadEventsState) => appState.unreadEvents,
);
