<ng-container *ngIf="skin.whiteLabel !== 'TAGANDTRACK'; else whiteLabelTNT">
    <section class="privacy" [innerHTML]="'PRIVACY_POLICY_BODY' | translate"></section>
</ng-container>

<ng-template #whiteLabelTNT>
    <div class="tnt-container">
        <h1 class="page-header">{{ 'PRIVACY_POLICY' | translate }}</h1>
        <app-breadcrumbs [breadcrumbsList]="breadcrumbsList"> </app-breadcrumbs>
        <mat-accordion class="tnt-accordion">
            <mat-expansion-panel class="expansion-item" *ngFor="let item of privacyPoliciesTNT">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        {{ item.title | translate }}
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <ng-container *ngFor="let itemInfo of item.info">
                    <p [innerHTML]="itemInfo | translate"></p>
                </ng-container>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</ng-template>

<app-footer *ngIf="isShowFooter" class="footer"></app-footer>
