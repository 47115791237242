<div class="spinner-wrapper" *ngIf="loginProcessing$ | async">
    <mat-spinner class="spinner" diameter="60"></mat-spinner>
</div>

<div class="flex-container" [ngClass]="{ loading: (loginProcessing$ | async) === true }">
    <div>
        <app-status-label *ngIf="loginError$ | async" [statusLabel]="loginError$ | async"></app-status-label>

        <form [formGroup]="form" (ngSubmit)="onSubmit(form.valid)">
            <mat-form-field class="form-field" appearance="outline">
                <mat-label>{{ 'EMAIL' | translate }}</mat-label>
                <input formControlName="email" matInput placeholder="{{ 'EMAIL' | translate }}" />
            </mat-form-field>

            <mat-form-field class="form-field" appearance="outline">
                <mat-label>{{ 'PASSWORD' | translate }}</mat-label>
                <input formControlName="password" matInput [type]="showPassword ? 'text' : 'password'"
                    placeholder="{{ 'PASSWORD' | translate }}" />

                <i class="icon" (click)="changeVisiblePassword()" matSuffix
                    [inlineSVG]="showPassword ? '#eye' : '#eye-close'"></i>
            </mat-form-field>

            <mat-checkbox class="form-field checkbox" color="primary" formControlName="isKeepLogged">
                {{ 'KEEP_ME_LOGGED' | translate }}
            </mat-checkbox>

            <button class="btn primary form-btn" mat-flat-button>
                {{ 'LOG_IN' | translate }}
            </button>
        </form>

        <span class="link" routerLink="/forgot-password"> {{ 'FORGOT_PASSWORD' | translate }} </span>
    </div>

    <ng-container *ngIf="localStorage.getItem('SOCIAL_LOGIN') && skin.whiteLabel === 'TRACKIMO'">
        <div class="socials-login-wrapper">
            <div class="social-item" (click)="logInWithFacebook()">
                <i class="icon" [inlineSVG]="'#facebookIcon'"></i>
            </div>
            <div class="social-item" (click)="logInWithGoogle()">
                <i class="icon" [inlineSVG]="'#googleIcon'"></i>
            </div>
            <div class="social-item" (click)="logInWithIOS()">
                <i class="icon" [inlineSVG]="'#iosIcon'"></i>
            </div>
        </div>
    </ng-container>

    <div class="login-footer">
        <p class="label">{{ 'NEW_TO' | translate }} {{ skin.brandName }}?</p>
        <button class="btn primary-light" routerLink="/create-account" mat-flat-button>
            {{ (skin.whiteLabel !== 'TAGANDTRACK' ? 'ADD_ACCOUNT' : 'CREATE_ACCOUNT') | translate }}
        </button>
    </div>
</div>