export const NOTIFICATION_TYPES = [
    {
        alarmTypeAsInt: 0,
        alarm_type: 'GEOZONE',
        alarm_icon: '#alarm_zone',
    },
    {
        alarmTypeAsInt: 1,
        alarm_type: 'SPEED',
        alarm_icon: '#alarm_speed',
        isNoFill: true,
    },
    {
        alarmTypeAsInt: 2, // for both START/STOP MOVEMENT EVENT
        alarm_type: 'MOVEMENT',
        alarm_icon: '#alarm_movement',
        isNoFill: true,
    },
    {
        alarmTypeAsInt: 3,
        alarm_type: 'BATTERY',
        alarm_icon: '#alarm_battery',
    },
    {
        alarmTypeAsInt: 4,
        alarm_type: 'SHAKING',
        alarm_icon: '#alarm_shaking',
    },
    {
        alarmTypeAsInt: 5,
        alarm_type: 'BLIND',
        alarm_icon: '#alarm_blind',
    },
    {
        alarmTypeAsInt: 6,
        alarm_type: 'RIGHT',
        alarm_icon: '#alarm_right_button',
    },
    {
        alarmTypeAsInt: 7,
        alarm_type: 'LEFT',
        alarm_icon: '#alarm_left_button',
    },
    {
        alarmTypeAsInt: 8,
        alarm_type: 'SOS',
        alarm_icon: '#alarm_sos',
    },
    {
        alarmTypeAsInt: 9,
        alarm_type: 'LIGHT',
        alarm_icon: '#alarm_light',
    },
    {
        alarmTypeAsInt: 10,
        alarm_type: 'POI',
        alarm_icon: '#alarm_poi',
    },
    {
        alarmTypeAsInt: 11,
        alarm_type: 'FLIGHT',
        alarm_icon: '#alarm_flight',
    },
    {
        alarmTypeAsInt: 12,
        alarm_type: 'alarm_bluetooth', // Bluetooth Proximity
        alarm_icon: '#alarm_sos',
    },
    {
        alarmTypeAsInt: 13,
        alarm_type: 'WIFI_SAFEZONE',
        alarm_icon: '#alarm_wi-fi',
    },
    {
        alarmTypeAsInt: 14,
        alarm_type: 'LOCK_UNLOCK_EVENT',
        alarm_icon: '#alarm_locker',
    },
    {
        alarmTypeAsInt: 15,
        alarm_type: 'GEOZONE_ENTRY', // This EventType is used only from server side.
        alarm_icon: '#alarm_geozone',
    },
    {
        alarmTypeAsInt: 16,
        alarm_type: 'GEOZONE_EXIT', // This EventType is used only from server side.
        alarm_icon: '#alarm_geozone',
    },
    {
        alarmTypeAsInt: 17,
        alarm_type: 'CHARGE_START',
        alarm_icon: '#alarm_charging_start',
    },
    {
        alarmTypeAsInt: 18,
        alarm_type: 'CHARGE_DONE',
        alarm_icon: '#alarm_charging_done',
    },
    {
        alarmTypeAsInt: 19,
        alarm_type: 'CHARGE_STOP',
        alarm_icon: '#alarm_charging_stop',
    },
    {
        alarmTypeAsInt: 20,
        alarm_type: 'SHUTDOWN',
        alarm_icon: '#alarm_power_off',
    },
    {
        alarmTypeAsInt: 21,
        alarm_type: 'WAKE_UP',
        alarm_icon: '#alarm_sleep_mode',
    },
    {
        alarmTypeAsInt: 22,
        alarm_type: 'NON_TRACKING',
        alarm_icon: '#alarm_warning',
    },
    {
        alarmTypeAsInt: 23,
        alarm_type: 'TEMPERATURE',
        alarm_icon: '#alarm_temperature',
    },
    {
        alarmTypeAsInt: 24,
        alarm_type: 'TAMPERING',
        alarm_icon: '#alarm_warning',
    },
];

export const SOS_ALARM_TYPE_ID = 8;
