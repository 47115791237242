<div class="dialog-wrapper" rtl>
    <div mat-dialog-title>
        <h1 class="title">{{ 'DEACTIVATE_TITLE' | translate }}</h1>
    </div>

    <div class="content" mat-dialog-content>
        <p>{{ data.msg }}</p>
    </div>
</div>

<div mat-dialog-actions>
    <div class="nav-container">
        <button class="btn basic" (click)="close()" mat-flat-button>{{ 'CANCEL' | translate }}</button>
        <button class="btn primary" (click)="contactUs()" mat-flat-button>{{ 'CONTACT_US' | translate }}</button>
    </div>
</div>