import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { AuthActions } from 'src/app/store/actions/auth.actions';
import { IAppState } from 'src/app/store/state/app.state';
import { skin } from 'src/white-labels';

@Component({
    selector: 'app-signup-confirm',
    templateUrl: './signup-confirm.component.html',
    styleUrls: ['./signup-confirm.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignupConfirmComponent {
    skin = skin;

    constructor(private store: Store<IAppState>) {}

    goToAddTracker(): void {
        if (skin.whiteLabel === 'TAGANDTRACK') {
            this.store.dispatch(AuthActions.loginByVerificationCode({ redirectPageURL: '/add-device' }));
        } else {
            this.store.dispatch(AuthActions.loginByVerificationCode({ redirectPageURL: '/map/add-device' }));
        }
    }
}
