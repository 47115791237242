<div class="auth-container">
    <div class="auth-container-bg" [ngStyle]="{'background-image': 'url(' + photo + ')'}"></div>
    <div class="auth-container-form" rtl>
        <div class="container">
            <img class="logo" src="white-labels/{{ skin.whiteLabel }}/logo-full.svg" [alt]="skin.whiteLabel" />
            <p class="discover-location-title">{{ 'DISCOVER_LOCATION' | translate }}</p>
            <router-outlet></router-outlet>
        </div>
    </div>
</div>

<app-footer></app-footer>
