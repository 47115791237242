import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { skin } from 'src/white-labels';
import { IAppleSignInResponse, ILogin, IStatusLabel } from 'src/app/store/interfaces';
import { Store } from '@ngrx/store';
import { IAppState } from 'src/app/store/state/app.state';
import { selectIsLoginProcessing, selectIsStatusLabel } from 'src/app/store/selectors/status.selectors';
import { from, Observable, Subject, takeUntil } from 'rxjs';
import {
    EMAIL_VALIDATOR_REG_EXP,
    SOCIAL_NETWORKS_PROVIDERS,
    StatusLabelType,
} from 'src/app/store/constants/common.constants';
import { StatusLabelActions } from 'src/app/store/actions/status-label.actions';
import { AuthActions } from 'src/app/store/actions/auth.actions';
import { BASE_REDIRECT_URL, OAUTH_CLIET_ID } from 'src/app/store/constants';
import { ApiService, LocalStorageService } from '../../store/services';
import {
    FacebookLoginProvider,
    GoogleLoginProvider,
    SocialAuthService,
    SocialUser,
} from 'angularx-social-login';
import { SnackbarService } from '../../store/services/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';

declare var AppleID;

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginComponent implements OnInit, OnDestroy {
    loginError$: Observable<IStatusLabel>;
    loginProcessing$: Observable<boolean>;
    form: FormGroup = this.formBuilder.group({
        email: ['', [Validators.required, Validators.pattern(EMAIL_VALIDATOR_REG_EXP)]],
        password: ['', [Validators.required]],
        isKeepLogged: [true],
    });
    showPassword = false;
    skin = skin;
    private destroyed$ = new Subject<void>();

    constructor(
        public localStorage: LocalStorageService,
        private formBuilder: FormBuilder,
        private store: Store<IAppState>,
        private apiService: ApiService,
        private socialAuthService: SocialAuthService,
        private snackBar: SnackbarService,
        private translate: TranslateService,
        private renderer2: Renderer2,
        @Inject(DOCUMENT) private document: Document,
        private router: Router,
        private route: ActivatedRoute,
    ) { }

    ngOnInit() {
        this.loginError$ = this.store.select(selectIsStatusLabel);
        this.loginProcessing$ = this.store.select(selectIsLoginProcessing);

        this.route.queryParams.subscribe((params) => {
            if (params.info) {
                const decodedToken = atob(params.info);
                if (this.localStorage.getItem('token')) {
                    this.localStorage.remove('token');
                }
                this.localStorage.setItem('token', `Bearer ${decodedToken}`);
                this.router.navigate(['/map']);
            }
        });

        if (skin.whiteLabel === 'TRACKIMO') {
            let script = this.renderer2.createElement('script');
            script.type = `text/javascript`;
            script.src = `https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js`;
            this.renderer2.appendChild(this.document.body, script);
        }
    }

    ngOnDestroy(): void {
        this.store.dispatch(StatusLabelActions.hideStatusLabel());
        this.destroyed$.next();
        this.destroyed$.complete();
    }

    changeVisiblePassword(): void {
        this.showPassword = !this.showPassword;
    }

    onSubmit(isValid: boolean): void {
        if (isValid) {
            const userData: ILogin = {
                username: this.form.value.email.trim(),
                password: this.form.value.password.trim(),
                remember_me: this.form.value.isKeepLogged,
                whitelabel: skin.whiteLabel,
            };

            if (skin.whiteLabel === 'TAGANDTRACK') {
                userData.client_id = OAUTH_CLIET_ID; //this.rdStr();
            }

            this.store.dispatch(StatusLabelActions.hideStatusLabel());
            this.store.dispatch(AuthActions.login({ userData }));
        }
    }

    logInWithGoogle() {
        this.store.dispatch(StatusLabelActions.hideStatusLabel());
        this.store.dispatch(AuthActions.startSocialLogin());
        from(this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID))
            .pipe(takeUntil(this.destroyed$))
            .subscribe({
                next: (googleUser: SocialUser) => {
                    const userData = {
                        firstName: googleUser.firstName,
                        redirect_uri: `${BASE_REDIRECT_URL}/api/internal/v1/oauth_redirect`,
                        whitelabel: skin.whiteLabel,
                        lastName: googleUser.lastName,
                        accessToken: googleUser.authToken,
                        client_id: OAUTH_CLIET_ID,
                    };
                    this.store.dispatch(
                        AuthActions.loginWithSocial({
                            userData,
                            provider: SOCIAL_NETWORKS_PROVIDERS.google,
                        }),
                    );
                },
                error: ({ error }) => {
                    if (error === 'popup_closed_by_user') {
                        this.store.dispatch(AuthActions.endSocialLogin());
                    } else {
                        this.store.dispatch(
                            StatusLabelActions.showStatusLabel({
                                statusLabel: {
                                    status: this.translate.instant('SERVICE_CURRENTLY_UNAVAILABLE'),
                                    labelType: StatusLabelType.WARNING,
                                },
                            }),
                        );
                    }
                },
            });
    }

    logInWithFacebook() {
        this.store.dispatch(StatusLabelActions.hideStatusLabel());
        this.store.dispatch(AuthActions.startSocialLogin());
        from(this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID))
            .pipe(takeUntil(this.destroyed$))
            .subscribe({
                next: (facebookUser: SocialUser) => {
                    const userData = {
                        firstName: facebookUser.firstName,
                        redirect_uri: `${BASE_REDIRECT_URL}/api/internal/v1/oauth_redirect`,
                        whitelabel: skin.whiteLabel,
                        lastName: facebookUser.lastName,
                        accessToken: facebookUser.authToken,
                        client_id: OAUTH_CLIET_ID,
                    };
                    this.store.dispatch(
                        AuthActions.loginWithSocial({
                            userData,
                            provider: SOCIAL_NETWORKS_PROVIDERS.facebook,
                        }),
                    );
                },
                error: (error) => {
                    if (error === 'User cancelled login or did not fully authorize.') {
                        this.store.dispatch(AuthActions.endSocialLogin());
                    } else {
                        this.store.dispatch(
                            StatusLabelActions.showStatusLabel({
                                statusLabel: {
                                    status: this.translate.instant('SERVICE_CURRENTLY_UNAVAILABLE'),
                                    labelType: StatusLabelType.WARNING,
                                },
                            }),
                        );
                    }
                },
            });
    }

    public async logInWithIOS() {
        this.store.dispatch(StatusLabelActions.hideStatusLabel());
        this.store.dispatch(AuthActions.startSocialLogin());
        AppleID.auth.init({
            clientId: 'com.web.trackimo.authclient',
            scope: 'name email',
            redirectURI: `${BASE_REDIRECT_URL}/auth/apple/callback`,
            state: 'init',
            nonce: 'test',
            usePopup: true,
        });

        from(AppleID.auth.signIn())
            .pipe(takeUntil(this.destroyed$))
            .subscribe({
                next: (response: IAppleSignInResponse) => {
                    const userData = {
                        whitelabel: skin.whiteLabel,
                        client_id: OAUTH_CLIET_ID,
                        redirect_uri: `${BASE_REDIRECT_URL}/api/internal/v1/oauth_redirect`,
                        sourceAppleLogin: 'web',
                        appleCode: response.authorization.code,
                    };
                    this.store.dispatch(
                        AuthActions.loginWithSocial({
                            userData,
                            provider: SOCIAL_NETWORKS_PROVIDERS.apple,
                        }),
                    );
                },
                error: ({ error }) => {
                    if (error === 'popup_closed_by_user') {
                        this.store.dispatch(AuthActions.endSocialLogin());
                    } else {
                        this.store.dispatch(
                            StatusLabelActions.showStatusLabel({
                                statusLabel: {
                                    status: this.translate.instant('SERVICE_CURRENTLY_UNAVAILABLE'),
                                    labelType: StatusLabelType.WARNING,
                                },
                            }),
                        );
                    }
                },
            });
    }

    // TODO ask Colin about unique user hash on login
    // rdStr() {
    //     const length = 30;
    //     const chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    //     let result = '';
    //     for (let i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
    //     if (this.localStorage.getItem('TNTCLIENT')) return this.localStorage.getItem('TNTCLIENT');
    //     const tntClient = result + Date.now();
    //     this.localStorage.setItem('TNTCLIENT', tntClient);
    //     return tntClient;
    // }
}
