import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LANGUAGES, LANGUAGES_MAMORI, LANGUAGES_TNT } from '../../store/constants/common.constants';
import { ILanguage } from '../../store/interfaces';
import { LocalStorageService } from '../../store/services/local-storage.service';
import { skin } from 'src/white-labels';

@Component({
    selector: 'app-language-select',
    templateUrl: './language-select.component.html',
    styleUrls: ['./language-select.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LanguageSelectComponent implements OnInit {
    languagesList: ILanguage[];
    skin = skin;
    currentLanguage: string;

    constructor(private localStorage: LocalStorageService, private translate: TranslateService) {}

    ngOnInit(): void {
        switch (this.skin.whiteLabel) {
            case 'TAGANDTRACK':
                this.languagesList = LANGUAGES_TNT;
                break;
            case 'MAMORI':
                this.languagesList = LANGUAGES_MAMORI;
                break;
            default:
                this.languagesList = LANGUAGES;
                break;
        }

        this.currentLanguage = this.localStorage.getItem('language');
    }

    onLanguageChange(language: string): void {
        this.localStorage.setItem('language', language);
        this.translate.use(language);
    }
}
