import { IDeviceFull, IDeviceShort, ISubscription } from '../interfaces';

export interface IDeviceState {
    devices: IDeviceShort[];
    selectedDevice: IDeviceFull;
    devicesSubscription?: ISubscription[];
    sbDevicesSubscription?: ISubscription[];
}

export const initialDeviceState: IDeviceState = {
    devices: undefined,
    selectedDevice: undefined,
};
