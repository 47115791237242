import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NoGPSSignalDialogComponent } from '../../dialogs/no-gps-dialog/no-gps-dialog.component';
import { LocalStorageService } from './local-storage.service';
import { NoGpsDialogInfoComponent } from '../../dialogs/no-gps-dialog-info/no-gps-dialog-info.component';
import { getTime, isSameWeek } from 'date-fns';

@Injectable({
    providedIn: 'root',
})
export class NoGpsSignalService {
    constructor(public dialog: MatDialog, private localStorage: LocalStorageService) {}

    checkIfNoGPSSignal(devicesList: (string | number)[]) {
        const currentDate = getTime(new Date());
        const storageDate = this.localStorage.getItem('userCheckOneWeekDate');
        const isTheSameWeek = isSameWeek(+storageDate, currentDate);
        if (!storageDate || !isTheSameWeek) {
            this.openNoGPSModal(devicesList);
        } else {
            return;
        }
    }

    openNoGPSModal(devicesList: (string | number)[]) {
        this.dialog.open(NoGPSSignalDialogComponent, {
            data: { devicesNames: devicesList },
            autoFocus: false,
            width: '800px',
            backdropClass: 'dialogBackground',
        });
    }

    openMoreInfoDialog() {
        this.dialog.closeAll();
        this.dialog.open(NoGpsDialogInfoComponent, {
            autoFocus: false,
            width: '800px',
            backdropClass: 'dialogBackground',
        });
    }

    close() {
        this.localStorage.setItem('userCheckOneWeekDate', getTime(new Date()).toString());
        this.dialog.closeAll();
    }
}
