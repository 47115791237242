import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import {
    catchError,
    map,
    mergeMap,
    switchMap,
    tap,
    withLatestFrom,
    combineLatestWith,
    filter,
} from 'rxjs/operators';
import { DevicesActions } from '../actions/devices.actions';
import { MapActions } from '../actions/map.actions';
import { StatusLabelActions } from '../actions/status-label.actions';
import { ZonesActions } from '../actions/zones.actions';
import { StatusLabelType } from '../constants/common.constants';
import { selectDevicesList, selectSectedDevice } from '../selectors/devices.selectors';
import { selectUser } from '../selectors/user.selector';
import { ApiService, GoogleMapService, LocalStorageService } from '../services';
import { IAppState } from '../state/app.state';
import { TranslateService } from '@ngx-translate/core';
import { AddDeviceService } from '../../dashboard/add-device-form/add-device.service';
import { SnackbarService } from '../services/snackbar.service';
import { IDeviceFull, IDeviceLocationEvent, IDeviceShort, IEventsResponse, IZone } from '../interfaces';
import { ContactsActions } from '../actions/contacts.actions';
import { SoundService } from '../services/sound.service';
import { selectDescendantsList } from '../selectors/descendants.selectors';

@Injectable()
export class DevicesEffects {
    getDevices$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DevicesActions.getDevices, ZonesActions.getZoneByID, MapActions.initNewZone),
            withLatestFrom(this.store.select(selectUser)),
            switchMap(([, user]) => {
                let devices: IDeviceShort[];
                return this.apiService.getDevicesShort(user.account_id).pipe(
                    tap((inDevices: IDeviceShort[]) => (devices = inDevices['data'])),
                    mergeMap(() => this.apiService.getDevicesLocations(user.account_id)),
                    tap((locations: IDeviceLocationEvent[]) => {
                        devices = devices.map((device) => ({
                            ...device,
                            location_ping: locations.find(
                                (location: IDeviceLocationEvent) => location.device_id === device.device_id,
                            ),
                        }));
                    }),
                    mergeMap(() => this.apiService.getNotReadEvents(user.account_id, 'SOS', [])),
                    map((events: IEventsResponse) => {
                        const isSOS = events.content.some((event) => !event.read);
                        if (isSOS) {
                            this.soundService.playAudio();
                        }
                        return DevicesActions.getDevicesSuccess({
                            response: devices.map((device) => ({
                                ...device,
                                allSOSEvents: events.content.filter(
                                    (event) => event.device_id === device.device_id,
                                ),
                            })),
                        });
                    }),
                    catchError((error) => of(DevicesActions.getDevicesError({ error }))),
                );
            }),
        ),
    );

    getDeviceByID$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DevicesActions.getDeviceByID),
            map((action) => action.deviceID),
            withLatestFrom(this.store.select(selectUser)),
            switchMap(([deviceID, user]) => {
                let device: IDeviceFull;
                return this.apiService.getDeviceDetails(user.account_id, deviceID).pipe(
                    tap((inDevice: IDeviceFull) => (device = inDevice)),
                    mergeMap(() => this.apiService.getDeviceLocation(user.account_id, deviceID)),
                    tap((locations: string) => {
                        device = {
                            ...device,
                            location_ping: JSON.parse(locations)[0],
                        };
                    }),
                    mergeMap(() => this.apiService.getNotReadSOSEventsByDeviceID(user.account_id, deviceID)),
                    tap((event: IEventsResponse) => {
                        device = {
                            ...device,
                            allSOSEvents: event.content,
                        };
                    }),
                    mergeMap(() => {
                        if (this.localStorage.getItem('COMPLEX_GZONES')) {
                            return this.apiService.getAssigedZones(deviceID);
                        } else {
                            return of(null);
                        }
                    }),
                    mergeMap((zones: IZone[]) => {
                        if (this.localStorage.getItem('COMPLEX_GZONES')) {
                            device.assigned_fences = zones;
                        }

                        if (device.location_ping) {
                            return this.apiService.getAddress(
                                device.location_ping.lat,
                                device.location_ping.lng,
                            );
                        } else {
                            return of(null);
                        }
                    }),
                    map((address: string) => {
                        if (address) {
                            device.location.address = address;
                        }
                        if (device.allSOSEvents.length) {
                            this.soundService.playAudio();
                        }
                        if (device.info.usage?.toLowerCase() === 'pet') {
                            this.store.dispatch(
                                DevicesActions.getDevicePetDetails({ deviceID: device.info.device_id }),
                            );
                        }

                        return DevicesActions.getDeviceByIDSuccess({ device, user });
                    }),
                    catchError((error) => of(DevicesActions.getDeviceByIDError({ error }))),
                );
            }),
        ),
    );

    getDeviceDetailsByToken$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DevicesActions.getDeviceDetailsByToken),
            map((action) => action.token),
            switchMap((token) =>
                this.apiService.getDeviceDetailsByToken(token).pipe(
                    map((device) => DevicesActions.getDeviceDetailsByTokenSuccess({ device })),
                    catchError((error) => of(DevicesActions.getDeviceDetailsByTokenError({ error }))),
                ),
            ),
        ),
    );

    getDeviceDetailsByTokenSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(DevicesActions.getDeviceDetailsByTokenSuccess),
                tap(({ device }) => this.googleMapService.initDeviceMarker(device[0])),
            ),
        { dispatch: false },
    );

    getDeviceHistory$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DevicesActions.getDeviceHistory),
            withLatestFrom(this.store.select(selectUser), this.store.select(selectSectedDevice)),
            switchMap(([reqData, user, device]) =>
                this.apiService
                    .getDeviceHistory(
                        user.account_id,
                        device.info.device_id,
                        reqData.from,
                        reqData.to,
                        reqData.types,
                    )
                    .pipe(
                        map((response) => DevicesActions.getDeviceHistorySuccess({ response })),
                        catchError((error) => of(DevicesActions.getDeviceHistoryError({ error }))),
                    ),
            ),
        ),
    );

    deleteDeviceHistory$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DevicesActions.deleteDeviceHistory),
            withLatestFrom(this.store.select(selectUser), this.store.select(selectSectedDevice)),
            switchMap(([reqData, user, device]) =>
                this.apiService
                    .deleteDeviceLocationHistory(
                        user.account_id,
                        device.info.device_id,
                        reqData.from,
                        reqData.to,
                    )
                    .pipe(
                        map((response) =>
                            DevicesActions.deleteDeviceHistorySuccess({
                                msg: this.translate.instant('DELETE_HISTORY_SUCCESS', {
                                    deviceId: device.info.device_id,
                                }),
                            }),
                        ),
                        catchError((error) => of(DevicesActions.deleteDeviceHistoryError({ error }))),
                    ),
            ),
        ),
    );

    sendBeep$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DevicesActions.sendBeep),
            map((action) => action.peyload),
            withLatestFrom(this.store.select(selectUser)),
            switchMap(([peyload, user]) =>
                this.apiService.sendBeepToDevice(peyload, user.account_id).pipe(
                    map((response) => DevicesActions.sendBeepSuccess({ response })),
                    catchError((error) => of(DevicesActions.sendBeepError({ error }))),
                ),
            ),
        ),
    );

    sendBeepSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(DevicesActions.sendBeepSuccess),
                tap(() => this.snackBar.success(this.translate.instant('BEEP_HAS_BEEN_SENT_TO_DEVICE'))),
            ),
        { dispatch: false },
    );

    setDeviceSettings$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DevicesActions.setDeviceSettings),
            map((action) => action.peyload),
            withLatestFrom(this.store.select(selectUser), this.store.select(selectSectedDevice)),
            switchMap(([peyload, user, device]) =>
                this.apiService.setDeviceSettings(user.account_id, device.info.device_id, peyload).pipe(
                    map(() => {
                        return DevicesActions.setDeviceSettingsSuccess({
                            deviceName: peyload.device_name || device.info.nick_name,
                            preferences: peyload.preferences,
                            msg: this.translate.instant('SAVED'),
                            usageName: peyload.usage || device.info.usage,
                        });
                    }),
                    catchError(({ error }) =>
                        of(
                            StatusLabelActions.showStatusLabel({
                                statusLabel: {
                                    status: error.message_key
                                        ? this.translate.instant(error.message_key)
                                        : error.message,
                                    labelType: StatusLabelType.WARNING,
                                },
                            }),
                        ),
                    ),
                ),
            ),
        ),
    );

    shareDeviceLink$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DevicesActions.shareDeviceLink),
            map((action) => action.peyload),
            withLatestFrom(this.store.select(selectUser), this.store.select(selectSectedDevice)),
            switchMap(([peyload, user, device]) =>
                this.apiService.shareDeviceLink(user.account_id, device.info.device_id, peyload).pipe(
                    map((response) => DevicesActions.shareDeviceLinkSuccess(response[0])),
                    catchError((error) => of(DevicesActions.shareDeviceLinkError({ error }))),
                ),
            ),
        ),
    );

    shareDeviceLinkSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(DevicesActions.shareDeviceLinkSuccess),
                withLatestFrom(this.store.select(selectSectedDevice)),
                tap(([res, device]) =>
                    this.router.navigate([`/map/devices/${device.info.device_id}/location-info`], {
                        queryParams: { token: res.token, expires: res.expires },
                    }),
                ),
            ),
        { dispatch: false },
    );

    deactivateShareLink$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DevicesActions.deactivateShareLink),
            withLatestFrom(this.store.select(selectUser), this.store.select(selectSectedDevice)),
            switchMap(([, user, device]) =>
                this.apiService.deactivateShareLink(user.account_id, device.info.device_id).pipe(
                    map(() =>
                        DevicesActions.deactivateShareLinkSuccess({ msg: this.translate.instant('deleted') }),
                    ),
                    catchError((error) => of(DevicesActions.deactivateShareLinkError({ error }))),
                ),
            ),
        ),
    );

    deactivateShareLinkSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(DevicesActions.deactivateShareLinkSuccess),
                withLatestFrom(this.store.select(selectSectedDevice)),
                tap(([, device]) => this.router.navigate([`/map/devices/${device.info.device_id}`])),
            ),
        { dispatch: false },
    );

    updateDeviceImage$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DevicesActions.updateDeviceImage),
            map((action) => action.peyload),
            withLatestFrom(this.store.select(selectUser), this.store.select(selectSectedDevice)),
            switchMap(([peyload, user, device]) => {
                if (peyload instanceof File) {
                    return this.apiService
                        .updateDeviceImage(user.account_id, device.info.device_id, peyload)
                        .pipe(
                            map((response) => DevicesActions.updateDeviceImageSuccess(response)),
                            catchError((error) => of(DevicesActions.shareDeviceLinkError({ error }))),
                        );
                } else {
                    return this.apiService
                        .updateDeviceIcon(user.account_id, device.info.device_id, peyload)
                        .pipe(
                            map((response) => DevicesActions.updateDeviceImageSuccess(response)),
                            catchError((error) => of(DevicesActions.shareDeviceLinkError({ error }))),
                        );
                }
            }),
        ),
    );

    getDeviceContactAssignments$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ContactsActions.getContactsSuccess),
            filter(() => this.router.url.indexOf('share') > -1),
            combineLatestWith(this.actions$.pipe(ofType(DevicesActions.getDeviceByIDSuccess))),
            withLatestFrom(this.store.select(selectUser), this.store.select(selectSectedDevice)),
            mergeMap(([, user, device]) =>
                this.apiService.getDeviceContactAssignments(user.account_id, device.info.device_id).pipe(
                    map((response) => DevicesActions.getDeviceContactAssignmentsSuccess({ response })),
                    catchError((error) => of(DevicesActions.getDeviceContactAssignmentsError({ error }))),
                ),
            ),
        ),
    );

    triggerDevicesLocations$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DevicesActions.getDevicesLocations),
            withLatestFrom(
                this.store.select(selectUser),
                this.store.select(selectDevicesList),
                this.store.select(selectSectedDevice),
                this.store.select(selectDescendantsList),
            ),
            mergeMap(([, user, devices, selectedDevice, descendants]) => {
                let devicesIDs = [];
                const url = this.router.url;

                if (url === '/map/sub-accounts') {
                    devicesIDs = [].concat.apply(
                        [],
                        descendants.map((descendant) => descendant.devices.map((device) => device.device_id)),
                    );
                } else if (url === '/map/devices') {
                    devicesIDs = devices?.map((device) => device.device_id);
                } else if (selectedDevice) {
                    devicesIDs = [selectedDevice.info.device_id];
                }

                return this.apiService.triggerDevicesLocations(user.account_id, devicesIDs).pipe(
                    map(() =>
                        DevicesActions.triggerDevicesLocationsSuccess({
                            msg: this.translate.instant('LOCATION_UPDATED'),
                        }),
                    ),
                    catchError((error) =>
                        of(
                            DevicesActions.triggerDevicesLocationsError({
                                error,
                                msg: this.translate.instant('ERROR'),
                            }),
                        ),
                    ),
                );
            }),
        ),
    );

    setDeviceContactAssignments$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DevicesActions.setDeviceContactAssignments),
            map((action) => action.dataRequest),
            withLatestFrom(this.store.select(selectUser), this.store.select(selectSectedDevice)),
            mergeMap(([dataRequest, user, device]) =>
                this.apiService
                    .setDeviceContactAssignments(user.account_id, device.info.device_id, dataRequest)
                    .pipe(
                        map(() =>
                            DevicesActions.setDeviceContactAssignmentsSuccess({
                                msg: this.translate.instant('SAVED'),
                            }),
                        ),
                        catchError((error) => of(DevicesActions.setDeviceContactAssignmentsError({ error }))),
                    ),
            ),
        ),
    );

    updateDeviceImageSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(DevicesActions.updateDeviceImageSuccess, DevicesActions.setDeviceSettingsSuccess),
                withLatestFrom(this.store.select(selectSectedDevice)),
                tap(() => this.googleMapService.removeMarkers()),
                tap(([, device]) => this.googleMapService.initDeviceMarker(device)),
                tap(() => this.snackBar.success(this.translate.instant('SAVED'))),
            ),
        { dispatch: false },
    );

    assignDevice$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DevicesActions.assignDevice),
            map((action) => action.zone),
            withLatestFrom(this.store.select(selectUser), this.store.select(selectSectedDevice)),
            mergeMap(([zone, user, device]) =>
                this.apiService
                    .assignDevice(
                        device?.info?.owner_id ? device?.info?.owner_id : user.account_id,
                        zone.id,
                        device.info.device_id,
                    )
                    .pipe(
                        map(() =>
                            DevicesActions.assignDeviceSuccess({
                                zone,
                                msg: this.translate.instant('ASSIGNED'),
                            }),
                        ),
                        catchError((error) => of(DevicesActions.assignDeviceError({ error }))),
                    ),
            ),
        ),
    );

    assignDeviceSuccess = createEffect(
        () =>
            this.actions$.pipe(
                ofType(DevicesActions.assignDeviceSuccess),
                map((action) => action.zone),
                withLatestFrom(this.store.select(selectSectedDevice), this.store.select(selectUser)),
                filter(([, , user]) => user.preferences.show_device_zones),
                tap(() => this.googleMapService.removeZones()),
                tap(() => this.googleMapService.removeInfoWindows()),
                tap(([newZone, device]) =>
                    this.googleMapService.addZones([...device.assigned_fences, newZone]),
                ),
                tap(([newZone, device]) =>
                    this.googleMapService.fitBounds([], [...device.assigned_fences, newZone], device),
                ),
            ),
        { dispatch: false },
    );

    unassignDevice$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DevicesActions.unassignDevice),
            map((action) => action.zone),
            withLatestFrom(this.store.select(selectUser), this.store.select(selectSectedDevice)),
            mergeMap(([zone, user, device]) =>
                this.apiService
                    .unassignDevice(
                        device?.info?.owner_id ? device?.info?.owner_id : user.account_id,
                        zone.id,
                        device.info.device_id,
                    )
                    .pipe(
                        map(() =>
                            DevicesActions.unassignDeviceSuccess({
                                zone,
                                msg: this.translate.instant('UNASSIGNED'),
                            }),
                        ),
                        catchError((error) => of(DevicesActions.unassignDeviceError({ error }))),
                    ),
            ),
        ),
    );

    unassignDeviceSuccess = createEffect(
        () =>
            this.actions$.pipe(
                ofType(DevicesActions.unassignDeviceSuccess),
                withLatestFrom(this.store.select(selectSectedDevice), this.store.select(selectUser)),
                filter(([, , user]) => user.preferences.show_device_zones),
                tap(() => this.googleMapService.removeZones()),
                tap(() => this.googleMapService.removeInfoWindows()),
                tap(([, device]) => this.googleMapService.addZones(device.assigned_fences)),
                tap(([, device]) => this.googleMapService.fitBounds([], device.assigned_fences, device)),
            ),
        { dispatch: false },
    );

    getRenewalPlans$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DevicesActions.getRenewalPlans),
            map((action) => action.deviceID),
            mergeMap((deviceID) =>
                this.apiService.getRenewalPlans(deviceID).pipe(
                    map((plan) => DevicesActions.getRenewalPlansSuccess({ plan })),
                    catchError((error) => of(DevicesActions.getRenewalPlansError({ error }))),
                ),
            ),
        ),
    );

    getBrainTreeDropInToken$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DevicesActions.getBrainTreeDropInToken),
            map((action) => action.payload),
            withLatestFrom(this.store.select(selectUser)),
            mergeMap(([payload, user]) =>
                this.apiService
                    .getBrainTreeDropInToken(
                        this.addDeviceService.SBAccountID
                            ? this.addDeviceService.SBAccountID
                            : user.account_id,
                        payload.deviceID ? payload.deviceID : this.addDeviceService.deviceID,
                        payload.planID,
                    )
                    .pipe(
                        map((inToken) => DevicesActions.getBrainTreeDropInTokenSuccess({ token: inToken })),
                        catchError((error) => of(DevicesActions.getBrainTreeDropInTokenError({ error }))),
                    ),
            ),
        ),
    );

    activatePrepaidPlan$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DevicesActions.activatePrepaidPlan),
            map((action) => action.payload),
            withLatestFrom(this.store.select(selectUser)),
            mergeMap(([payload, user]) =>
                this.apiService
                    .setDeviceToUser(
                        payload.accountID ? payload.accountID : user.account_id,
                        payload.deviceID,
                        payload.planID,
                    )
                    .pipe(
                        map(() => DevicesActions.activatePrepaidPlanSuccess()),
                        catchError((error) => of(DevicesActions.activatePrepaidPlanError(error))),
                    ),
            ),
        ),
    );

    activatePrepaidPlanSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(DevicesActions.activatePrepaidPlanSuccess),
                tap(() => {
                    this.store.dispatch(
                        StatusLabelActions.showStatusLabel({
                            statusLabel: {
                                status: this.translate.instant('ADD_TRACKER_SUCCESS_MSG'),
                                labelType: StatusLabelType.SUCCESS,
                                isHideClose: true,
                            },
                        }),
                    );
                }),
            ),
        { dispatch: false },
    );

    renewPlan$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DevicesActions.renewPlan),
            map((action) => action.payload),
            withLatestFrom(this.store.select(selectUser)),
            mergeMap(([payload, user]) =>
                this.apiService
                    .renewPlan(
                        payload.masterAccountID ? payload.masterAccountID : user.account_id,
                        payload.deviceID,
                        payload.plan,
                    )
                    .pipe(
                        map(() =>
                            DevicesActions.renewPlanSuccess({
                                deviceID: payload.deviceID,
                                msg: 'paymentAccepted',
                            }),
                        ),
                        catchError((error) => of(DevicesActions.renewPlanError(error))),
                    ),
            ),
        ),
    );

    renewPrepaidPlan$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DevicesActions.renewPrepaidPlan),
            map((action) => action.payload),
            withLatestFrom(this.store.select(selectUser)),
            mergeMap(([payload, user]) =>
                this.apiService
                    .renewPrepaidPlan(
                        payload.masterAccountID ? payload.masterAccountID : user.account_id,
                        payload.deviceID,
                        payload.planID,
                    )
                    .pipe(
                        map(() =>
                            DevicesActions.renewPrepaidPlanSuccess({
                                deviceID: payload.deviceID,
                                msg: 'SubscriptionRenewed',
                            }),
                        ),
                        catchError((error) => of(DevicesActions.renewPrepaidPlanError(error))),
                    ),
            ),
        ),
    );

    renewPlanByCoupon$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DevicesActions.renewPlanByCoupon),
            map((action) => action.payload),
            withLatestFrom(this.store.select(selectUser)),
            mergeMap(([payload, user]) =>
                this.apiService
                    .renewPlanByCoupon(
                        payload.masterAccountID ? payload.masterAccountID : user.account_id,
                        payload.deviceID,
                        payload.coupon,
                    )
                    .pipe(
                        map(() =>
                            DevicesActions.renewPlanByCouponSuccess({
                                deviceID: payload.deviceID,
                                msg: 'SubscriptionRenewed',
                            }),
                        ),
                        catchError((error) => of(DevicesActions.renewPlanByCouponError(error))),
                    ),
            ),
        ),
    );

    renewPlanSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(
                    DevicesActions.renewPlanSuccess,
                    DevicesActions.renewPrepaidPlanSuccess,
                    DevicesActions.renewPlanByCouponSuccess,
                ),
                withLatestFrom(this.store.select(selectSectedDevice)),
                tap(([action]) => {
                    this.store.dispatch(
                        StatusLabelActions.showStatusLabel({
                            statusLabel: {
                                status: this.translate.instant(action.msg),
                                labelType: StatusLabelType.SUCCESS,
                            },
                        }),
                    );
                }),
                tap(([action, selectedDevice]) => {
                    if (selectedDevice) {
                        this.store.dispatch(DevicesActions.getDeviceByID({ deviceID: action.deviceID }));
                    }
                }),
            ),
        { dispatch: false },
    );

    increaseSMSLimit$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DevicesActions.increaseSMSLimit),
            withLatestFrom(this.store.select(selectUser), this.store.select(selectSectedDevice)),
            mergeMap(([action, user, device]) =>
                this.apiService
                    .activateSmsPlanBraintree(
                        action.accountID ? action.accountID : user.account_id,
                        action.deviceID ? action.deviceID : device.info.device_id,
                        action.payload,
                    )
                    .pipe(
                        map(() =>
                            DevicesActions.increaseSMSLimitSuccess({
                                smsLimit: action.smsLimit,
                                haveSelectedDevice: action.deviceID ? false : true,
                            }),
                        ),
                        catchError((error) => of(DevicesActions.increaseSMSLimitError(error))),
                    ),
            ),
        ),
    );

    increaseSMSLimitSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(DevicesActions.increaseSMSLimitSuccess),
                tap(() => {
                    this.store.dispatch(
                        StatusLabelActions.showStatusLabel({
                            statusLabel: {
                                status: this.translate.instant('paymentAccepted'),
                                labelType: StatusLabelType.SUCCESS,
                            },
                        }),
                    );
                }),
            ),
        { dispatch: false },
    );

    activatePlanByCard$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DevicesActions.activatePlanByCard),
            map((action) => action.payload),
            withLatestFrom(this.store.select(selectUser)),
            mergeMap(([payload, user]) =>
                this.apiService
                    .activatePlan(
                        this.addDeviceService.SBAccountID
                            ? this.addDeviceService.SBAccountID
                            : user.account_id,
                        this.addDeviceService.deviceID,
                        payload,
                    )
                    .pipe(
                        map(() => DevicesActions.activatePlanByCardSuccess()),
                        catchError((error) => of(DevicesActions.activatePlanByCardError(error))),
                    ),
            ),
        ),
    );

    activatePlanByCardSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(DevicesActions.activatePlanByCardSuccess),
                tap(() => {
                    this.store.dispatch(
                        StatusLabelActions.showStatusLabel({
                            statusLabel: {
                                status: this.translate.instant('paymentAccepted'),
                                labelType: StatusLabelType.SUCCESS,
                            },
                        }),
                    );
                }),
            ),
        { dispatch: false },
    );

    activatePlanByCoupon$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DevicesActions.activatePlanByCoupon),
            map((action) => action.coupon),
            withLatestFrom(this.store.select(selectUser)),
            mergeMap(([coupon, user]) =>
                this.apiService
                    .activateByCoupon(
                        this.addDeviceService.SBAccountID
                            ? this.addDeviceService.SBAccountID
                            : user.account_id,
                        this.addDeviceService.deviceID,
                        coupon,
                    )
                    .pipe(
                        map(() => DevicesActions.activatePlanByCouponSuccess()),
                        catchError((error) => of(DevicesActions.activatePlanByCouponError(error))),
                    ),
            ),
        ),
    );

    activatePlanByCouponSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(DevicesActions.activatePlanByCouponSuccess),
                tap(() => {
                    this.store.dispatch(
                        StatusLabelActions.showStatusLabel({
                            statusLabel: {
                                status: this.translate.instant('COUPON_SUCCESSFULLY_ACTIVATED'),
                                labelType: StatusLabelType.SUCCESS,
                                isHideClose: true,
                            },
                        }),
                    );
                }),
            ),
        { dispatch: false },
    );

    renewPlanError$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(
                    DevicesActions.renewPrepaidPlanError,
                    DevicesActions.renewPlanByCouponError,
                    DevicesActions.renewPlanError,
                    DevicesActions.activatePrepaidPlanError,
                    DevicesActions.activatePlanByCouponError,
                    DevicesActions.activatePlanByCardError,
                    DevicesActions.increaseSMSLimitError,
                ),
                tap(({ error }) => {
                    this.store.dispatch(
                        StatusLabelActions.showStatusLabel({
                            statusLabel: {
                                status: error['message_key']
                                    ? this.translate.instant(error['message_key'])
                                    : error.message,
                                labelType: StatusLabelType.WARNING,
                            },
                        }),
                    );
                }),
            ),
        { dispatch: false },
    );

    getAddressForDevice$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DevicesActions.getAddressForDevice),
            mergeMap((request) =>
                this.apiService.getAddress(request.lat, request.lng).pipe(
                    map((address) =>
                        DevicesActions.getAddressForDeviceSuccess({ address, deviceID: request.deviceID }),
                    ),
                    catchError((error) =>
                        of(
                            DevicesActions.getAddressForDeviceError({
                                error,
                                deviceID: request.deviceID,
                                errorText: this.translate.instant('UNABLE_TO_GEOCODE'),
                            }),
                        ),
                    ),
                ),
            ),
        ),
    );

    setDeviceSettingsSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(
                    DevicesActions.setDeviceSettingsSuccess,
                    DevicesActions.deactivateShareLinkSuccess,
                    DevicesActions.assignDeviceSuccess,
                    DevicesActions.unassignDeviceSuccess,
                    DevicesActions.deleteDeviceHistorySuccess,
                    DevicesActions.setDeviceContactAssignmentsSuccess,
                    DevicesActions.triggerDevicesLocationsSuccess,
                    DevicesActions.updateDeviceEventSuccess,
                ),
                tap((resonse) => this.snackBar.success(resonse.msg)),
            ),
        { dispatch: false },
    );

    setDeviceSettingsErrpr$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(DevicesActions.deleteDeviceHistoryError),
                tap(({ error }) => this.snackBar.error(error.error.message_key.toUpperCase())),
            ),
        { dispatch: false },
    );

    triggerDevicesLocationsError$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(DevicesActions.triggerDevicesLocationsError),
                tap((resonse) => this.snackBar.error(resonse.msg)),
            ),
        { dispatch: false },
    );

    getDevicesSubscription$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DevicesActions.getDevicesSubscription),
            withLatestFrom(this.store.select(selectUser)),
            switchMap(([, user]) =>
                this.apiService.getSubscriptions(user.account_id).pipe(
                    map((response) => DevicesActions.getDevicesSubscriptionSuccess({ response })),
                    catchError((error) => of(DevicesActions.getDevicesSubscriptionError({ error }))),
                ),
            ),
        ),
    );

    getSBDevicesSubscription$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DevicesActions.getSBDevicesSubscription),
            withLatestFrom(this.store.select(selectUser)),
            switchMap(([, user]) =>
                this.apiService.getSBDevicesSubscriptions(user.account_id).pipe(
                    map((response) => DevicesActions.getSBDevicesSubscriptionSuccess({ response })),
                    catchError((error) => of(DevicesActions.getSBDevicesSubscriptionError({ error }))),
                ),
            ),
        ),
    );

    readDeviceSOSEvent$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DevicesActions.updateDeviceEvent),
            withLatestFrom(this.store.select(selectUser), this.store.select(selectSectedDevice)),
            switchMap(([, user, device]) => {
                const events: number[] = device.allSOSEvents.map((inEvent) => inEvent.id);
                return this.apiService.updateNotificationStatus(user.account_id, events).pipe(
                    map(() =>
                        DevicesActions.updateDeviceEventSuccess({
                            msg: this.translate.instant('SOS_READ'),
                            eventsCount: events.length,
                        }),
                    ),
                    catchError((error) => of(DevicesActions.updateDeviceEventError({ error }))),
                );
            }),
        ),
    );
    // @TODO REMOVED AFTER NEW API WILL BE READY
    getPetDetails$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DevicesActions.getDevicePetDetails),
            map((action) => action.deviceID),
            switchMap((deviceID) =>
                this.apiService.getPetInfo(deviceID).pipe(
                    map((response) => DevicesActions.getDevicePetDetailsSuccess({ response })),
                    catchError((error) => of(DevicesActions.getDevicePetDetailsError({ error }))),
                ),
            ),
        ),
    );

    setPetDetails$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DevicesActions.setDevicePetDetails),
            map((action) => action.deviceUsageDTO),
            switchMap((deviceUsageDTO) =>
                this.apiService.setPetInfo(deviceUsageDTO).pipe(
                    map((response) => DevicesActions.getDevicePetDetailsSuccess({ response })),
                    catchError((error) => of(DevicesActions.getDevicePetDetailsError({ error }))),
                ),
            ),
        ),
    );

    constructor(
        private actions$: Actions,
        private apiService: ApiService,
        private store: Store<IAppState>,
        private snackBar: SnackbarService,
        private router: Router,
        private googleMapService: GoogleMapService,
        private translate: TranslateService,
        private addDeviceService: AddDeviceService,
        private soundService: SoundService,
        private localStorage: LocalStorageService,
    ) {}
}
