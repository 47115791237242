import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { FAQS, TNT_FAQS } from 'src/app/store/constants/common.constants';
import { IBreadcrumb } from 'src/app/store/interfaces/breadcrumb.interface';
import { skin } from 'src/white-labels';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { selectUser } from '../../store/selectors/user.selector';
import { Store } from '@ngrx/store';
import { IAppState } from '../../store/state/app.state';
import { IUser } from '../../store/interfaces';

@Component({
    selector: 'app-faq',
    templateUrl: './faq.component.html',
    styleUrls: ['./faq.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FaqComponent {
    user$: Observable<IUser>;
    isShowHeader: boolean;
    faqsList;
    skin = skin;

    breadcrumbsList: Array<IBreadcrumb> = [
        {
            label: this.skin.whiteLabel !== 'TAGANDTRACK' ? 'FAQS' : 'FREQ_ASKED_QUESTIONS',
            path: '/',
        },
    ];

    constructor(private route: ActivatedRoute, private store: Store<IAppState>) {}

    ngOnInit(): void {
        this.user$ = this.store.select(selectUser);
        this.faqsList = this.skin.whiteLabel === 'TAGANDTRACK' ? TNT_FAQS : FAQS;
        this.route.data.subscribe(({ isShowHeader, isShowFooter }) => {
            this.isShowHeader = isShowHeader;
        });
    }
}
