import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'privacy-and-policy-dialog',
    templateUrl: 'privacy-and-policy-dialog.html',
    styleUrls: ['./privacy-and-policy-dialog.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrivacyAndPolicyDialog {
    constructor(
        public dialogRef: MatDialogRef<PrivacyAndPolicyDialog>,
        @Inject(MAT_DIALOG_DATA) public data,
    ) {}

    closeDialog(checked: boolean): void {
        checked
            ? this.dialogRef.close((this.data.checked = !this.data.checked))
            : this.dialogRef.close(this.data.checked);
    }
}
