import { environment } from 'src/environments/environment';

import { TRACKIMO } from './TRACKIMO';
import { TAGANDTRACK } from './TAGANDTRACK';
import { MAMORI } from './MAMORI';
import { MYTRACKI } from './MYTRACKI';

let skinConfig;

if (window.location.hostname.indexOf('mamori') > -1) {
    skinConfig = MAMORI;
} else if (window.location.hostname.indexOf('mytracki') > -1) {
    skinConfig = MYTRACKI;
} else if (environment.whiteLabel === 'TAGANDTRACK') {
    skinConfig = TAGANDTRACK;
} else {
    skinConfig = TRACKIMO;
}

export const skin = {
    whiteLabel: skinConfig.title,
    brandName: skinConfig.brandName,
    primaryColor: skinConfig.primaryColor,
    androidAppLink: skinConfig.androidAppLink,
    iosAppLink: skinConfig.iosAppLink,
    localizeLink: skinConfig.localizeLink,
};
