import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService } from 'src/app/store/services';
import { IBreadcrumb } from '../../store/interfaces/breadcrumb.interface';

@Component({
    selector: 'app-breadcrumbs',
    templateUrl: './breadcrumbs.component.html',
    styleUrls: ['./breadcrumbs.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BreadcrumbsComponent {
    @Input() breadcrumbsList: Array<IBreadcrumb>;
    constructor(private localStorage: LocalStorageService, private router: Router) {}

    goHome(): void {
        if (this.localStorage.getItem('token')) {
            this.router.navigate(['/map']);
        } else {
            this.router.navigate(['/']);
        }
    }
}
