import { Injectable, NgZone } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { SnackbarComponent } from '../../shared/snackbar/snackbar.component';

@Injectable({
    providedIn: 'root',
})
export class SnackbarService {
    private readonly config: MatSnackBarConfig;

    constructor(private snackbar: MatSnackBar, private zone: NgZone) {
        this.config = new MatSnackBarConfig();
        this.config.panelClass = ['mat-snack-bar-container'];
        this.config.verticalPosition = 'bottom';
        this.config.horizontalPosition = 'center';
        this.config.data = {
            close: () => {
                this.snackbar.dismiss();
            },
        };
        this.config.duration = 4000;
    }

    error(message: string, snackType = 'error') {
        this.config.panelClass = ['mat-snack-bar-container', 'error'];
        this.config.data = { message: message, snackType: snackType };
        this.show();
    }

    success(message: string, snackType = 'success') {
        this.config.panelClass = ['mat-snack-bar-container', 'success'];
        this.config.data = { message: message, snackType: snackType };
        this.show();
    }

    warning(message: string, snackType = 'warning') {
        this.config.panelClass = ['mat-snack-bar-container', 'warning'];
        this.config.data = { message: message, snackType: snackType };
        this.show();
    }

    closeSnackBar() {
        this.snackbar.dismiss();
    }

    private show(config?: MatSnackBarConfig) {
        config = config || this.config;
        this.zone.run(() => {
            this.snackbar.openFromComponent(SnackbarComponent, config);
        });
    }
}
