import { Inject, Injectable } from '@angular/core';
import { EventManager } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { FeatureFlagsDialogComponent } from '../../dialogs/feature-flags-dialog/feature-flags-dialog.components';
import { FeatureToggleService } from './feature-toggle.service';
import { skin } from '../../../white-labels';

type Options = {
    element: any;
    keys: string;
};

@Injectable({ providedIn: 'root' })
export class HotkeysService {
    defaults: Partial<Options> = {
        element: this.document,
    };

    constructor(
        public dialog: MatDialog,
        private featuresToggleService: FeatureToggleService,
        private eventManager: EventManager,
        @Inject(DOCUMENT) private document: Document,
    ) {
        if (skin.whiteLabel === 'TRACKIMO') {
            this.addShortcut({ keys: 'shift.control.q' }).subscribe(() => {
                this.openFeatureModal();
            });
        }
    }

    addShortcut(options: Partial<Options>) {
        const merged = { ...this.defaults, ...options };
        const event = `keydown.${merged.keys}`;

        return new Observable((observer) => {
            const handler = (e) => {
                e.preventDefault();
                observer.next(e);
            };

            const dispose = this.eventManager.addEventListener(merged.element, event, handler);

            return () => {
                dispose();
            };
        });
    }

    openFeatureModal() {
        this.dialog.closeAll();
        this.dialog.open(FeatureFlagsDialogComponent, {
            autoFocus: false,
            width: '650px',
            backdropClass: 'dialogBackground',
        });
    }
}
