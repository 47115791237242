import { createSelector } from '@ngrx/store';
import { MAX_DEVICE_ZONES_ASSIGNMENTS } from '../constants/common.constants';
import { IDeviceShort, IZone } from '../interfaces';
import { IAppState } from '../state/app.state';
import { IZoneState } from '../state/zones.stete';
import { selectDeviceFences, selectDevicesList } from './devices.selectors';

export const selectFeature = (appState: IAppState) => appState.zones;

export const selectZonesList = createSelector(selectFeature, (appState: IZoneState) => appState.zones);
export const selectSectedZone = createSelector(
    selectFeature,
    (appState: IZoneState) => appState.selectedZone,
);

export const selectSectedZoneWithDevices = createSelector(
    selectSectedZone,
    selectDevicesList,
    (zone: IZone, devices: IDeviceShort[]) => ({
        ...zone,
        devices: devices?.map((device) => ({
            ...device,
            isChecked: zone?.deviceIds?.includes(device.device_id),
        })),
    }),
);

export const selectZonesListWithDeviceFences = createSelector(
    selectZonesList,
    selectDeviceFences,
    (zones, deviceFences) => {
        if (zones.length && deviceFences) {
            return zones
                .map((zone) => ({
                    ...zone,
                    checked: isZoneCheked(deviceFences, zone),
                    disabled:
                        !isZoneCheked(deviceFences, zone) &&
                        deviceFences.length === MAX_DEVICE_ZONES_ASSIGNMENTS,
                }))
                .sort((a, b) => +b.checked - +a.checked);
        }

        return undefined;
    },
);

export const selectShowZoneTooltip = createSelector(
    selectFeature,
    (appState: IZoneState) => appState.showZoneTooltip,
);

const isZoneCheked = (geozones: IZone[], zone: IZone) => geozones.some((gzone) => gzone.id === zone.id);
