import { createReducer, on } from '@ngrx/store';
import { EventsActions } from '../actions/events.actions';
import { initialEventsState } from '../state/events.state';

export const eventsReducer = createReducer(
    initialEventsState,
    on(EventsActions.getEventsError, () => initialEventsState),
    on(EventsActions.getEventsSuccess, (state, { response }) => ({
        ...state,
        events: response,
    })),

    on(EventsActions.getSOSDevicesEventsSuccess, (state, { response }) => ({
        ...state,
        events: response.content,
    })),

    on(EventsActions.deleteAllEventsSuccess, (state) => ({
        ...state,
        events: [],
    })),
    on(EventsActions.clearEvents, (state) => ({
        ...state,
        events: [],
    })),
    on(EventsActions.deleteAllEventsSuccess, () => initialEventsState),
);
