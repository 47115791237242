import { OWL_DATE_TIME_FORMATS } from '@danielmoncada/angular-datetime-picker';
import { IStepOption } from 'ngx-ui-tour-md-menu';
import { skin } from 'src/white-labels';

export const TEXT_INPUT =
    /^(?![\s-])[a-zA-Zåa-öw-яZ0-9_\u0590-\u05FF\uFB1D-\uFB4F\u3000-\u303f\u3040-\u309f\u30a0-\u30ff\uff00-\uff9f\u4e00-\u9faf\u3400-\u4dbf][^.\s]*[^ .]+$/;
// email validation RFC 5322 Standard
export const EMAIL_VALIDATOR_REG_EXP =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const PASSWORD_VALIDATOR_REG_EXP = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d$#_@!%*?&.]{6,24}$/;
export const TNT_PASSWORD_VALIDATOR_REG_EXP =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$#_@!%*?&.])[a-zA-Z\d$#_@!%*?&.]{8,24}$/;
export const REX_EXP_UPPER_CASE = /^(?=.*[A-Z])/;
export const REX_EXP_LOWER_CASE = /^(?=.*[a-z])/;
export const REX_EXP_DIGIT = /^(?=.*[0-9])/;
export const NUMBERS_ONLY = /^\d+$/;
export const REX_EXP_SPECIAL_CHAR = /^(?=.*[$#_@!%*?&.])/;
export const TIME_VALIDATOR_REG_EXP = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/;
export const VERIFICATION_CODE_REX_EXP = /^[0-9]{4,4}$/;
export const VERIFICATION_CODE_FORGOT_PASSWORD_REX_EXP = /^[0-9]{5,5}$/;
export const URL_VALIDATION_PATTERN =
    /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/;
export const API_PING_INTERVAL = 15000;
export const MIN_ZONE_SIZE = 300;
export const MIN_ROAD_DISTANCE = 300;
export const ONE_KM = 1000;
export const MAX_FILE_SIZE_LIMIT = 1000000;
export const MAX_DEVICES_FOR_ADDRESS = 10;
export const PHONE_STRING = /^\+\d{0,15}$/;

export const SOCIAL_NETWORKS_PROVIDERS = {
    google: 'google',
    facebook: 'facebook',
    apple: 'apple',
};

export const PLAN_TYPES = {
    prepaid: 'PREPAID',
    not_prepaid: 'NONCE',
    coupon: 'COUPON',
};

export const FILTER_TYPES = {
    devices: 'DEVICES',
    zones: 'ZONES',
};

export const DEFAULT_LANGUAGE = 'en';

export const LANGUAGES = [
    { value: 'en', viewValue: 'English' },
    { value: 'ja', viewValue: 'Japanese' },
    { value: 'it', viewValue: 'Italian' },
    { value: 'pl', viewValue: 'Polish' },
    { value: 'pt', viewValue: 'Portuguese' },
    { value: 'ru', viewValue: 'Russian' },
    { value: 'es', viewValue: 'Spanish' },
    { value: 'de', viewValue: 'German' },
    { value: 'uk', viewValue: 'Ukrainian' },
    { value: 'fr', viewValue: 'French' },
    { value: 'tr', viewValue: 'Turkish' },
    { value: 'no', viewValue: 'Norwegian' },
    { value: 'he', viewValue: 'Hebrew' },
];

export const JAPANESE_LANGUAGE = 'ja';

export const LANGUAGES_TNT = [
    { value: 'en', viewValue: 'English' },
    { value: 'es', viewValue: 'Spanish' },
    { value: 'it', viewValue: 'Italian' },
    { value: 'pt', viewValue: 'Portuguese' },
    { value: 'el', viewValue: 'Greek' },
];

export const TNT_COUNTRIES_LIST = [
    { value: 'it', viewValue: 'Vodafone Italy' },
    { value: 'es', viewValue: 'Vodafone Spain' },
    { value: 'pt', viewValue: 'Vodafone Portugal' },
    { value: 'al', viewValue: 'Vodafone Albania' },
    { value: 'ie', viewValue: 'Vodafone Ireland' },
    { value: 'gr', viewValue: 'Vodafone Greece' },
    { value: 'uk', viewValue: 'Vodafone UK' },
];

export const LANGUAGES_MAMORI = [
    { value: 'en', viewValue: 'English' },
    { value: 'ja', viewValue: 'Japanese' },
];

export const TRACKER_USAGE = [
    { value: 'CAR', title: 'CAR' },
    { value: 'VEHICLE', title: 'VEHICLE' },
    { value: 'PET', title: 'PET' },
    { value: 'HUMAN', title: 'HUMAN' },
    { value: 'ASSET', title: 'ASSET' },
    { value: 'BAG', title: 'BAG' },
    { value: 'KEYS', title: 'KEYS' },
    { value: 'WALLET', title: 'WALLET' },
    { value: 'BIKE', title: 'BIKE' },
    { value: 'SCOOTER', title: 'SCOOTER' },
    { value: 'BICYCLE', title: 'BICYCLE' },
    { value: 'DRONE', title: 'DRONE' },
];
export const RED_COLOR = '#e53935';
export const GRAY_COLOR = '#999999';
export const SNACKBAR_DURATION = 4000;
export const MAX_DEVICE_ZONES_ASSIGNMENTS = 5;

export const IDEAL_LOCK_RESPONSE_TIME = 45000; // 45 s

export enum ZoneType {
    RECTANGLE = 'RECTANGLE',
    POLYGON = 'POLYGON',
    POLYLINE = 'POLYLINE',
}

export enum Descendants {
    SUBACCOUNTS = 'Subaccounts',
    TRACKERS = 'Trackers',
}

export enum METRIC_SYSTEM {
    MPH = 'mph',
    KPH = 'kph',
}

export enum METRIC_SPEED {
    KPH = 'KM / H',
    MPH = 'MI / H',
}

export enum StatusLabelType {
    SUCCESS = 'success',
    WARNING = 'warning',
    ERROR = 'error',
}

export enum SleepModeRepeatType {
    NOT_REPEAT = 'NOT_REPEAT',
    REPEAT_EVERYDAY = 'REPEAT_EVERYDAY',
    REPEAT_CUSTOM = 'REPEAT_CUSTOM',
}

export enum LocationState {
    WAITING = 'waiting',
    TIMEOUT = 'timeout',
    SUCCESS = 'success',
    TRUE = 'true',
}

export enum DeviceStatus {
    INACTIVE = 'inactive',
    ACTIVE = 'active',
    EXPIRED = 'expired',
}

export enum Size {
    S = 'S',
    M = 'M',
    L = 'L',
    XL = 'XL',
}

export enum Gender {
    FEMALE = 'FEMALE',
    MALE = 'MALE',
}

export enum TrackingModeSettings {
    POWER_SAVING = 240,
    EVERYDAY = 60,
    OFF = 0,
    CUSTOM = -1,
    SECONDS = 5,
}

export enum DATE_FORMATS {
    DEFAULT = 'dd/MM/yyyy',
    EU = 'dd/MM/yyyy',
    US = 'MM/dd/yyyy',
    JP = 'yyyy/MM/dd',
}

export const OWL_DATE_FORMAT_PORVIDER = {
    provide: OWL_DATE_TIME_FORMATS,
    useValue: {
        parseInput: 'DD/MM/YYYY HH:mm',
        fullPickerInput: 'DD/MM/YYYY HH:mm',
        datePickerInput: 'DD/MM/YYYY',
        timePickerInput: 'HH:mm',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

export const DATE_FORMAT_VALUES = [
    {
        value: 'DEFAULT',
        viewValue: 'Default',
    },
    {
        value: 'EU',
        viewValue: 'dd/mm/yyyy',
    },
    {
        value: 'US',
        viewValue: 'mm/dd/yyyy',
    },
    {
        value: 'JP',
        viewValue: 'yyyy/mm/dd',
    },
];

export const DEVICES_TYPES = [
    {
        icon_id: '6',
        device_name: 'Mini',
        desc: 'MINI_DESC',
        icon: '#mini',
        isSelected: false,
    },
    {
        icon_id: '5',
        device_name: 'Guardian',
        desc: 'GUARDIAN_DESC',
        icon: '#guardian',
        isSelected: false,
    },
    {
        icon_id: '7',
        device_name: 'Travel',
        desc: 'TRAVEL_DESC',
        icon: '#travel',
        isSelected: false,
    },
    {
        icon_id: '3',
        device_name: skin.whiteLabel === 'TAGANDTRACK' ? 'Tag and Track' : 'Tracki Pro',
        desc: 'TRACKI_DESC',
        icon: '#tracki_pro',
        isSelected: false,
    },
    {
        icon_id: '2',
        device_name: 'Watch',
        desc: 'WATCH_DESC',
        icon: '#watch',
        isSelected: false,
    },
    {
        icon_id: '4',
        device_name: 'Universal',
        desc: 'UNIVERSAL_DESC',
        icon: '#universal',
        isSelected: false,
    },
    {
        icon_id: '9',
        device_name: 'Curve',
        desc: 'CURVE_DESC',
        icon: '#curve',
        isSelected: false,
    },
    {
        icon_id: '12',
        device_name: 'E-Toll',
        desc: 'ETOLL_DESC',
        icon: '#eToll',
        isSelected: false,
    },
    {
        icon_id: '10',
        device_name: 'Male',
        desc: '',
        icon: '#boyAvatar',
        isSelected: false,
    },
    {
        icon_id: '11',
        device_name: 'Female',
        desc: '',
        icon: '#girlAvatar',
        isSelected: false,
    },
    {
        icon_id: '14',
        device_name: 'Pet',
        desc: '',
        icon: '#pawPrint',
        isSelected: false,
    },
];

export const ETOLL_DEVICE_TYPE = 'e-toll';
export const ETOLL_ICON_TYPE = 12;

export const DEFAULT_DEVICE_TYPE = DEVICES_TYPES[DEVICES_TYPES.length - 1];

export const FAQS = [
    {
        header: 'FAQS_HEADER_1',
        body: ['FAQS_BODY_1'],
    },
    {
        header: 'FAQS_HEADER_2',
        body: ['FAQS_BODY_2'],
    },
    {
        header: 'FAQS_HEADER_3',
        body: ['FAQS_BODY_3'],
    },
    {
        header: 'FAQS_HEADER_4',
        body: ['FAQS_BODY_4'],
    },
    {
        header: 'FAQS_HEADER_5',
        body: ['FAQS_BODY_5'],
    },
    {
        header: 'FAQS_HEADER_6',
        body: ['FAQS_BODY_6'],
    },
    {
        header: 'FAQS_HEADER_7',
        body: ['FAQS_BODY_7'],
    },
    {
        header: 'FAQS_HEADER_8',
        body: ['FAQS_BODY_8'],
    },
    {
        header: 'FAQS_HEADER_9',
        body: ['FAQS_BODY_9'],
    },
    {
        header: 'FAQS_HEADER_10',
        body: ['FAQS_BODY_10'],
    },
    {
        header: 'FAQS_HEADER_11',
        body: ['FAQS_BODY_11'],
    },
    {
        header: 'FAQS_HEADER_12',
        body: ['FAQS_BODY_12'],
    },
];

export const TNT_FAQS = [
    {
        header: 'faqHead1',
        body: ['faqPar1'],
    },
    {
        header: 'faqHead2',
        body: ['faqPar2'],
    },
    {
        header: 'faqHead3',
        body: ['faqPar3'],
    },
    {
        header: 'faqHead4',
        body: ['faqPar4'],
    },
    {
        header: 'faqHead5',
        body: ['faqPar5'],
    },
    {
        header: 'faqHead6',
        body: ['faqPar6'],
    },
    {
        header: 'faqHead7',
        body: ['faqPar7'],
    },
    {
        header: 'faqHead8',
        body: ['faqPar8'],
    },
    {
        header: 'faqHead9',
        body: ['faqPar9'],
    },
    {
        header: 'faqHead10',
        body: ['faqPar10'],
    },
    {
        header: 'faqHead11',
        body: ['faqPar11'],
    },
    {
        header: 'faqHead12',
        body: ['faqPar12'],
    },
    {
        header: 'faqHead13',
        body: ['faqPar13'],
    },
    {
        header: 'faqHead14',
        body: ['faqPar14'],
    },
    {
        header: 'faqHead15',
        body: ['faqPar15'],
    },
    {
        header: 'faqHead16',
        body: ['faqPar16_1', 'faqPar16_2', 'faqPar16_3'],
    },
    {
        header: 'faqHead17',
        body: ['faqPar17'],
    },
    {
        header: 'faqHead18',
        body: ['faqPar18_1'],
    },
    {
        header: 'faqHead19',
        body: ['faqPar19'],
    },
    {
        header: 'faqHead20',
        body: ['faqPar20'],
    },
    {
        header: 'faqHead21',
        body: ['faqPar21_1', 'faqPar21_2', 'faqPar21_3'],
    },
    {
        header: 'faqHead22',
        body: ['faqPar22'],
    },
    {
        header: 'faqHead23',
        body: ['faqPar23'],
    },
    {
        header: 'faqHead24',
        body: ['faqPar24'],
    },
    {
        header: 'faqHead25',
        body: ['faqPar25'],
    },
    {
        header: 'faqHead26',
        body: ['faqPar26'],
    },
    {
        header: 'faqHead27',
        body: ['faqPar27_1', 'faqPar27_2', 'faqPar27_3', 'faqPar27_4'],
    },
    {
        header: 'faqHead28',
        body: ['faqPar28'],
    },
    {
        header: 'faqHead29',
        body: ['faqPar29'],
    },
    {
        header: 'faqHead30',
        body: ['faqPar30'],
    },
    {
        header: 'faqHead31',
        body: ['faqPar31'],
    },
    {
        header: 'faqHead32',
        body: ['faqPar32'],
    },
    {
        header: 'faqHead34',
        body: ['faqPar34'],
    },
    {
        header: 'faqHead35',
        body: ['faqPar35'],
    },
    {
        header: 'faqHead36',
        body: ['faqPar36'],
    },
    {
        header: 'ACCESSING_THE_APPLICATION_QUESTION_1',
        body: ['ACCESSING_THE_APPLICATION_ANSWER_1'],
    },
    {
        header: 'ACCESSING_THE_APPLICATION_QUESTION_2',
        body: ['ACCESSING_THE_APPLICATION_ANSWER_2'],
    },
    {
        header: 'MAPS_MY_ZONES_QUESTION_1',
        body: ['MAPS_MY_ZONES_ANSWER_1'],
    },
    {
        header: 'MY_TRACKER_QUESTION_1',
        body: ['MY_TRACKER_ANSWER_1'],
    },
    {
        header: 'MY_TRACKER_LOCATION_HISTORY_QUESTION_1',
        body: ['MY_TRACKER_LOCATION_HISTORY_ANSWER_1'],
    },
    {
        header: 'MY_TRACKER_MAPS_QUESTION_1',
        body: ['MY_TRACKER_MAPS_ANSWER_1'],
    },
    {
        header: 'MY_ZONES_QUESTION_1',
        body: ['MY_ZONES_ANSWER_1'],
    },
    {
        header: 'MY_ZONES_QUESTION_2',
        body: ['MY_ZONES_ANSWER_2'],
    },
    {
        header: 'NOTIFICATIONS_QUESTION_1',
        body: ['NOTIFICATIONS_ANSWER_1'],
    },
    {
        header: 'NOTIFICATIONS_QUESTION_2',
        body: ['NOTIFICATIONS_ANSWER_2'],
    },
    {
        header: 'NOTIFICATIONS_QUESTION_3',
        body: ['NOTIFICATIONS_ANSWER_3'],
    },
    {
        header: 'NOTIFICATIONS_QUESTION_4',
        body: ['NOTIFICATIONS_ANSWER_4'],
    },
    {
        header: 'NOTIFICATIONS_QUESTION_5',
        body: ['NOTIFICATIONS_ANSWER_5'],
    },
    {
        header: 'NOTIFICATIONS_QUESTION_6',
        body: ['NOTIFICATIONS_ANSWER_6'],
    },
    {
        header: 'SHARING_TRACKER_INFORMATION_QUESTION_1',
        body: ['SHARING_TRACKER_INFORMATION_ANSWER_1'],
    },
    {
        header: 'CREATING_A_CONTACT_QUESTION_1',
        body: ['CREATING_A_CONTACT_ANSWER_1'],
    },
];

export const TNT_CONTACT_US = [
    {
        location: 'Vodafone Italy',
        label_1: 'CONTACTS_LABEL_1_ITA',
        label_2: 'CONTACTS_LABEL_2_ITA',
        label_3: 'CONTACTS_LABEL_3_ITA',
        label_4: 'CONTACTS_LABEL_4_ITA',
    },
    {
        location: 'Vodafone Spain',
        label_1: 'CONTACTS_LABEL_1_SPA',
        label_2: 'CONTACTS_LABEL_2_SPA',
        label_3: 'CONTACTS_LABEL_3_SPA',
        label_4: 'CONTACTS_LABEL_4_SPA',
    },

    {
        location: 'Vodafone Portugal',
        label_1: 'CONTACTS_LABEL_1_POR',
        label_2: 'CONTACTS_LABEL_2_POR',
        label_3: 'CONTACTS_LABEL_3_POR',
    },
    {
        location: 'Vodafone Albania',
        label_1: 'CONTACTS_LABEL_1_ALB',
        label_2: 'CONTACTS_LABEL_2_ALB',
        label_3: 'CONTACTS_LABEL_3_ALB',
        label_4: 'CONTACTS_LABEL_4_ALB',
    },
    {
        location: 'Vodafone Ireland',
        label_1: 'CONTACTS_LABEL_1_IRL',
    },
    {
        location: 'Vodafone Greece',
        label_1: 'CONTACTS_LABEL_1_GR',
        label_2: 'CONTACTS_LABEL_2_GR',
        label_3: 'CONTACTS_LABEL_3_GR',
        label_4: 'CONTACTS_LABEL_4_GR',
    },
    {
        location: 'Vodafone UK',
        label_1: 'CONTACTS_LABEL_1_UK',
    },
];

export const TNT_PRIVACY_POLICY = [
    {
        title: 'PP_CHANGES_POLICY',
        info: ['PP_CHANGES_POLICY_TEXT'],
    },
    {
        title: 'PP_APPLIES',
        info: ['PP_APPLIES_P1', 'PP_APPLIES_P2', 'PP_APPLIES_P3'],
    },
    {
        title: 'PP_OUR_PRINCIPLE',
        info: ['PP_OUR_PRINCIPLE_P1', 'PP_OUR_PRINCIPLE_P2'],
    },
    {
        title: 'PP_BASICS',
        info: ['PP_BASICS_INFO'],
    },
    {
        title: 'PP_SUPPLEMENTS',
        info: ['PP_SUPPLEMENTS_INFO'],
    },
    {
        title: 'PP_YOUR_RIGHTS',
        info: ['PP_YOUR_RIGHTS_INFO'],
    },
];

export const TNT_USER_GUIDE = [
    {
        title: 'INTRODUCTION',
        innerText: 'INTRODUCTION_INFO',
    },
    {
        title: 'ZONE_ALERTS',
        innerText: 'ZONE_ALERTS_INFO',
    },
    {
        title: 'MISSED_ALERTS',
        innerText: 'MISSED_ALERTS_INFO',
    },
    {
        title: 'GENERAL_GUIDANCE',
        innerText: 'GENERAL_GUIDANCE_INFO',
    },
];

export const MAIN_TOUR_STEPS: IStepOption[] = [
    {
        anchorId: 'devices',
        content: 'TUTS_DEVICES_TEXT',
        title: 'DEVICES',
    },
    {
        anchorId: 'zones',
        content: 'TUTS_ZONES_TEXT',
        title: 'ZONES',
    },
    {
        anchorId: 'notifications',
        content: 'TUTS_NOTIFS_TEXT',
        title: 'NOTIFICATIONS',
    },
    {
        anchorId: 'addDevice',
        content: 'TUTS_ADD_DEVICE_TEXT',
        title: 'ADD_DEVICE',
        isOptional: true,
        stepId: 'arrowDownCenter',
    },
];

export const DEVICE_PANEL_STEPS: IStepOption[] = [
    {
        anchorId: 'panelHistory',
        content: 'TUTS_HISTORY_TEXT',
        title: 'HISTORY',
        stepId: 'arrowDownLeft',
        placement: { xPosition: 'before', yPosition: 'above' },
    },
    {
        anchorId: 'panelShare',
        content: 'TUTS_SHARE_TEXT',
        title: 'SHARE_LOCATION',
        stepId: 'arrowDownLeft',
        placement: { xPosition: 'before', yPosition: 'above' },
    },
    {
        anchorId: 'panelSettings',
        content: 'TUTS_SETTINGS_TEXT',
        title: 'SETTINGS',
        stepId: 'arrowDownLeft',
        placement: { xPosition: 'before', yPosition: 'above' },
    },
    {
        anchorId: 'panelTrackingMode',
        content: 'TUTS_TRACKING_TEXT',
        title: 'TRACKING_MODE',
        stepId: 'arrowDownLeft',
        placement: { xPosition: 'before', yPosition: 'above' },
    },
    {
        anchorId: 'panelLocationType',
        content: 'TUTS_PRECISION_TEXT',
        title: 'PRECISION_MODE',
        stepId: 'precisionCircle',
        placement: { xPosition: 'before', yPosition: 'below' },
    },
];
