<div class="info-wrapper">
    <app-header [user]="user$ | async"></app-header>
    <div class="info-container" rtl>
        <app-breadcrumbs [breadcrumbsList]="breadcrumbsList">
        </app-breadcrumbs>

        <div class="info">
            <h2 class="info-title">
                {{'ABOUT_TRACKIMO' | translate}}
            </h2>
            <i class="info-icon" [inlineSVG]="'#info-icon'"></i>
            <p class="info-name">
                Name
            </p>
            <h1 class="info-version-title">
                {{'APP_VERSION' | translate}}
            </h1>
            <p class="info-text">
                {{'ABOUT_APLICATION_IS_AVAILABLE' | translate}}
            </p>
            <a href="#" class="info-link">
                {{'PRODUCT_SAFETY' | translate}}
            </a>
            <p class="info-text">
                {{'ABOUT_ALSO_AVAILABLE' | translate}}
            </p>
        </div>

        <nav mat-tab-nav-bar>
            <a mat-tab-link [routerLink]="['/privacy-policy']" routerLinkActive="active"
                [routerLinkActive]="['mat-tab-label-active']">
                {{ 'PRIVACY_POLICY' | translate }}
            </a>
            <a mat-tab-link [routerLink]="['/terms-and-conditions']" routerLinkActive="active"
                [routerLinkActive]="['mat-tab-label-active']">
                {{ 'TERMS_AND_CONDITIONS' | translate }}
            </a>
        </nav>
        <router-outlet></router-outlet>


    </div>
    <app-footer class="footer"></app-footer>
</div>
