import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { DevicesActions } from 'src/app/store/actions/devices.actions';
import { DeviceStatus, ETOLL_DEVICE_TYPE, StatusLabelType } from 'src/app/store/constants/common.constants';
import { IAppState } from 'src/app/store/state/app.state';
import { skin } from 'src/white-labels';
import { UpdatePlanDialogComponent } from '../../../dialogs/update-plan-dialog/update-plan-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { SnackbarService } from '../../../store/services/snackbar.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-device-preview',
    templateUrl: './device-preview.component.html',
    styleUrls: ['./device-preview.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DevicePreviewComponent {
    @Input() device: any;
    @Input() isShowAddressImmediately = true;
    @Input() isAddZone = false;
    @Input() isHideChevron = false;
    @Output() toggleClickEvent = new EventEmitter();
    deviceStatus = DeviceStatus;
    eTollDeviceType = ETOLL_DEVICE_TYPE;
    address: string;
    skin = skin;

    // TODO
    sosStatusLabel = {
        status: this.translate.instant('SOS_BUTTON_IS_PRESSED'),
        labelType: StatusLabelType.ERROR,
    };

    constructor(
        public dialog: MatDialog,
        private store: Store<IAppState>,
        private snackBar: SnackbarService,
        private translate: TranslateService,
    ) {}

    showDeviceAddress(device, event): void {
        event.stopPropagation();
        event.preventDefault();

        if (this.device.location_ping.lat && this.device.location_ping.lng) {
            this.store.dispatch(
                DevicesActions.getAddressForDevice({
                    deviceID: this.device.device_id,
                    lat: this.device.location_ping.lat,
                    lng: this.device.location_ping.lng,
                }),
            );
        }
    }

    onToggleChange(device, event): void {
        if (device.device_type === this.eTollDeviceType) {
            event.source.checked = event.source.source;
            this.snackBar.error('ETOLL_ZONE_UNAVAILABLE');
        } else {
            this.toggleClickEvent.emit(device.device_id);
        }
    }

    renewSubscription(deviceID: number, event: Event) {
        event.stopPropagation();
        this.dialog.open(UpdatePlanDialogComponent, {
            width: '800px',
            backdropClass: 'dialogBackground',
            data: {
                currentDeviceID: deviceID,
            },
        });
    }
}
