<div class="dialog-wrapper">
    <div class="dialog-title-wrapper" mat-dialog-title>
        <h1 class="title">{{ 'NO_GPS_SIGNAL' | translate }}</h1>

        <div class="dialog-content-wrapper" mat-dialog-content>
            <div class="img-wrapper">
                <img src="assets/images/noGPSImg.svg" alt="no GPS Signal image" />
            </div>
            {{ 'ONLY_GSM_INFO' | translate }}
        </div>

        <div class="dialog-content-actions" mat-dialog-actions>
            <div class="nav-container">
                <button class="btn primary" (click)="close()" mat-flat-button>
                    {{ 'close' | translate }}
                </button>
            </div>
        </div>
    </div>
</div>
