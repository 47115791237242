import { createReducer, on } from '@ngrx/store';
import { AuthActions } from '../actions/auth.actions';
import { DevicesActions } from '../actions/devices.actions';
import { EventsActions } from '../actions/events.actions';
import { UserActions } from '../actions/user.actionsts';
import { initialUserState } from '../state/user.stete';

export const userReducer = createReducer(
    initialUserState,
    on(AuthActions.loginSuccess, (state, { response }) => ({
        ...state,
        user: response,
    })),
    on(AuthActions.loginByVerificationCodeSuccess, (state, { response }) => ({
        ...state,
        user: response,
    })),
    on(UserActions.getUserSuccess, (state, { response }) => ({
        ...state,
        user: response,
    }))
);
