import { createSelector } from '@ngrx/store';
import { IDescendant } from '../interfaces';
import { IAppState } from '../state/app.state';
import { IDescendantsState } from '../state/descendants.stete';

let descendants: IDescendant[] = [];

export const selectFeature = (appState: IAppState) => appState.descendant;

export const selectMainAccount = createSelector(selectFeature, (appState: IDescendantsState) => ({
    ...appState.descendants,
}));

export const selectDescendants = createSelector(
    selectFeature,
    (appState: IDescendantsState) => appState.descendants?.descendants,
);

export const selectDescendantsList = createSelector(selectFeature, (appState: IDescendantsState) => {
    descendants = [];
    if (appState.descendants) {
        getDescendantsList(appState.descendants);
    }
    return descendants;
});

export const selectDescendantsDevicesIDs = createSelector(selectFeature, (appState: IDescendantsState) => {
    descendants = [];
    if (appState.descendants) {
        getDescendantsList(appState.descendants);
    }

    return [].concat
        .apply(
            [],
            descendants.map((descendant) => descendant.devices),
        )
        .map((device) => device.device_id);
});

function getDescendantsList(obj) {
    descendants.push(...obj.descendants);
    if (!obj.descendants) {
        return;
    }

    obj.descendants.forEach((descendant) => getDescendantsList(descendant));
}
