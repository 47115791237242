import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Inject,
    OnDestroy,
    OnInit,
    Renderer2,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActionsSubject, Store } from '@ngrx/store';
import { from, Observable, Subject } from 'rxjs';
import { DevicesActions } from 'src/app/store/actions/devices.actions';
import { ICongratsMessage, IPlan, IPlanDTO, IStatusLabel } from 'src/app/store/interfaces';
import { selectDeviceRenewalPlans } from 'src/app/store/selectors/devices.selectors';
import { IAppState } from 'src/app/store/state/app.state';
import { selectBrainTreeToken } from 'src/app/store/selectors/shared.selector';
import { filter, takeUntil } from 'rxjs/operators';
import { selectIsPaymentProcessing, selectIsStatusLabel } from 'src/app/store/selectors/status.selectors';
import { StatusLabelActions } from 'src/app/store/actions/status-label.actions';
import dropin from 'braintree-web-drop-in';
import { ofType } from '@ngrx/effects';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SnackbarService } from '../../store/services/snackbar.service';

@Component({
    selector: 'app-update-plan-dialog',
    templateUrl: './update-plan-dialog.component.html',
    styleUrls: ['./update-plan-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UpdatePlanDialogComponent implements OnInit, OnDestroy {
    plan$: Observable<IPlan>;
    paymentStatus$: Observable<IStatusLabel>;
    paymentProcessing$: Observable<boolean>;
    selectedPlan: IPlanDTO;
    isBrainTreeToken: boolean;
    isProceededToCouponForm: boolean;
    congratsMessage: ICongratsMessage;
    couponForm: FormGroup = this.formBuilder.group({
        couponValue: ['', [Validators.required]],
    });
    dropinInstance: any;
    isChoiceStepProceeded: boolean;
    isProceededToBraintree: boolean;
    isProceededToPrepaid: boolean;
    isShowPayButton: boolean;
    statusLabel$: Observable<IStatusLabel>;
    listener: any;
    private destroyed$ = new Subject<void>();

    constructor(
        public dialogRef: MatDialogRef<UpdatePlanDialogComponent>,
        private formBuilder: FormBuilder,
        private store: Store<IAppState>,
        private cdRef: ChangeDetectorRef,
        @Inject(MAT_DIALOG_DATA) public data,
        private actionsListener$: ActionsSubject,
        private snackBar: SnackbarService,
        private renderer: Renderer2,
    ) {
        this.store.dispatch(DevicesActions.getRenewalPlans({ deviceID: this.data.currentDeviceID }));
    }

    ngOnInit(): void {
        this.statusLabel$ = this.store.select(selectIsStatusLabel);
        this.plan$ = this.store.select(selectDeviceRenewalPlans);
        this.paymentStatus$ = this.store.select(selectIsStatusLabel);
        this.paymentProcessing$ = this.store.select(selectIsPaymentProcessing);

        this.store
            .select(selectBrainTreeToken)
            .pipe(
                filter((token) => Boolean(token)),
                takeUntil(this.destroyed$),
            )
            .subscribe((token) => {
                this.isBrainTreeToken = true;
                this.cdRef.markForCheck();
                this.createBraintreeUI(token);
            });

        this.listener = this.renderer.listen('document', 'click', (evt) => {
            let toShow = document.getElementsByClassName('braintree-options braintree-hidden');
            if (toShow.length) {
                this.isShowPayButton = false;
            } else {
                this.isShowPayButton = true;
            }
            this.cdRef.markForCheck();
        });
    }

    ngOnDestroy(): void {
        this.destroyed$.next();
        this.destroyed$.complete();
        this.renderer.listen('body', 'click', (event) => {
            this.listener();
        });

        this.store.dispatch(DevicesActions.resetPaymentProcessingStatus());
        this.store.dispatch(DevicesActions.removeBrainTreeDropInToken());
        this.store.dispatch(StatusLabelActions.hideStatusLabel());
    }

    choosePlanOption() {
        if (this.selectedPlan) {
            this.isChoiceStepProceeded = true;
            if (this.selectedPlan.paymentPlatform === 'PREPAID') {
                this.isProceededToPrepaid = true;
                this.renewPrepaidPlan();
            } else if (this.selectedPlan.paymentPlatform === 'NONCE') {
                this.isProceededToBraintree = true;
                this.goToPayment();
            } else if (this.selectedPlan.paymentPlatform === 'COUPON') {
                this.isProceededToCouponForm = true;
            } else {
                this.snackBar.error('AN_ERROR_OCCURRED_TRY_AGAIN_LATER_OR_CONTACT_TO_CUSTOMER_SUPPORT');
            }
        } else {
            this.snackBar.warning('CHOOSE_PAYMENT_PLAN');
        }
    }

    close(): void {
        this.dialogRef.close();
    }

    closeWithConfirm(): void {
        this.dialogRef.close(true);
    }

    goToPayment(): void {
        const payload = {
            planID: this.selectedPlan.id,
            deviceID: this.data.currentDeviceID,
        };
        this.store.dispatch(DevicesActions.getBrainTreeDropInToken({ payload }));
    }

    createBraintreeUI(token: string): void {
        from(
            dropin.create({
                authorization: token,
                container: document.getElementById('#dropin-container'),
                paypal: {
                    flow: 'vault',
                    amount: this.selectedPlan.amount,
                    currency: this.selectedPlan.currency,
                },
            }),
        )
            .pipe(takeUntil(this.destroyed$))
            .subscribe((instance) => (this.dropinInstance = instance));
    }

    renewPlan(): void {
        from(this.dropinInstance.requestPaymentMethod())
            .pipe(takeUntil(this.destroyed$))
            .subscribe((reqPayload) => {
                this.congratsMessage = {
                    planID: this.selectedPlan.id,
                    planDesc: this.selectedPlan.name,
                    planPrice: `${this.selectedPlan.amount} ${this.selectedPlan.currency}`,
                };
                const payload = {
                    plan: { nonce: reqPayload['nonce'], plan_id: this.selectedPlan.id },
                    deviceID: this.data.currentDeviceID,
                    masterAccountID: this.data.currentAccountID,
                };
                return this.store.dispatch(DevicesActions.renewPlan({ payload }));
            });
    }

    renewPrepaidPlan(): void {
        const payload = {
            planID: this.selectedPlan.id,
            deviceID: this.data.currentDeviceID,
            masterAccountID: this.data.currentAccountID,
        };
        this.actionsListener$
            .pipe(ofType(DevicesActions.renewPrepaidPlan))
            .pipe(takeUntil(this.destroyed$))
            .subscribe((data: any) => {
                this.congratsMessage = {
                    planID: this.selectedPlan.id,
                    planDesc: this.selectedPlan.name,
                };
            });
        return this.store.dispatch(DevicesActions.renewPrepaidPlan({ payload }));
    }

    renewPlanByCouponCode(isValid: boolean): void {
        if (isValid) {
            const payload = {
                deviceID: this.data.currentDeviceID,
                coupon: this.couponForm.value['couponValue'],
                masterAccountID: this.data.currentAccountID,
            };
            this.actionsListener$
                .pipe(ofType(DevicesActions.renewPlanByCoupon))
                .pipe(takeUntil(this.destroyed$))
                .subscribe((data: any) => {
                    this.congratsMessage = {
                        planID: this.selectedPlan.id,
                        planDesc: this.selectedPlan.name,
                    };
                });
            this.store.dispatch(DevicesActions.renewPlanByCoupon({ payload }));
        }
    }
}
