<div class="forms-container" rtl>
    <div class="forms-title">{{ 'ADD_SUB_ACCOUNT' | translate }}</div>
    <div class="form-wrapper">
        <div class="form-title">{{ 'INVITE_USER' | translate }}</div>
        <form [formGroup]="inviteForm" class="invite-form">
            <div class="form-control-wrapper">
                <mat-form-field class="form-field" appearance="outline">
                    <mat-label>{{ 'EMAIL_ADDRESS' | translate }}</mat-label>
                    <input
                        matInput
                        placeholder="{{ 'ENTER_EMAIL_ADDRESS' | translate }}"
                        formControlName="inviteEmail"
                    />
                </mat-form-field>
            </div>
            <div mat-dialog-actions class="invite-wrapper">
                <div class="dialog-actions">
                    <button class="btn primary" (click)="submitInviteFrom(inviteForm.valid)" mat-flat-button>
                        {{ 'INVITE' | translate }}
                    </button>
                </div>
            </div>
        </form>
    </div>
    <span class="divider"></span>
    <div class="form-wrapper">
        <div class="form-title">{{ 'OR_CREATE_NEW_ACC' | translate }}</div>
        <form [formGroup]="createAccountForm" class="create-account-form">
            <div class="create-account-form-controls">
                <div class="form-control-wrapper">
                    <mat-form-field class="form-field" appearance="outline">
                        <mat-label>{{ 'NAME' | translate }}</mat-label>
                        <input
                            matInput
                            placeholder="{{ 'enterName' | translate }}"
                            formControlName="userName"
                        />
                    </mat-form-field>
                </div>

                <div class="form-control-wrapper">
                    <mat-form-field class="form-field" appearance="outline">
                        <mat-label>{{ 'ADDRESS' | translate }}</mat-label>
                        <input
                            matInput
                            placeholder="{{ 'enterAddress' | translate }}"
                            formControlName="userAddress"
                        />
                    </mat-form-field>
                </div>

                <div class="form-control-wrapper">
                    <mat-form-field class="form-field" appearance="outline">
                        <mat-label>{{ 'EMAIL_ADDRESS' | translate }}</mat-label>
                        <input
                            matInput
                            placeholder="{{ 'ENTER_EMAIL_ADDRESS' | translate }}"
                            formControlName="userEmail"
                        />
                    </mat-form-field>
                </div>

                <div class="form-control-wrapper">
                    <mat-form-field class="form-field" appearance="outline">
                        <mat-label>{{ 'PASSWORD' | translate }}</mat-label>
                        <input
                            matInput
                            placeholder="{{ 'ENTER_PASSWORD' | translate }}"
                            formControlName="userPassword"
                            [type]="showPassword ? 'text' : 'password'"
                        />
                        <i
                            class="icon"
                            (click)="changeVisiblePassword()"
                            matSuffix
                            [inlineSVG]="showPassword ? '#eye' : '#eye-close'"
                        ></i>
                    </mat-form-field>
                </div>

                <app-phone-input class="form-control-wrapper phone-number"></app-phone-input>

                <div class="form-control-wrapper">
                    <mat-form-field class="form-field" appearance="outline">
                        <mat-label>{{ 'CONFIRM_PASSWORD' | translate }}</mat-label>
                        <input
                            matInput
                            placeholder="{{ 'CONFIRM_PASSWORD' | translate }}"
                            formControlName="passwordConfirmed"
                            [type]="showConfirmedPassword ? 'text' : 'password'"
                        />
                        <i
                            class="icon"
                            (click)="changeVisibleConfirmPassword()"
                            matSuffix
                            [inlineSVG]="showConfirmedPassword ? '#eye' : '#eye-close'"
                        ></i>
                    </mat-form-field>
                </div>
            </div>

            <div mat-dialog-actions class="dialog-actions-btns">
                <div class="dialog-actions">
                    <button class="btn basic" (click)="closeDialog()" mat-flat-button>
                        {{ 'CANCEL' | translate }}
                    </button>
                    <button
                        class="btn primary"
                        (click)="submitCreateAccountFrom(createAccountForm.valid)"
                        mat-flat-button
                    >
                        {{ 'ADD' | translate }}
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>
