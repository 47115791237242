import { ChangeDetectionStrategy, Component } from '@angular/core';
import { IBreadcrumb } from 'src/app/store/interfaces/breadcrumb.interface';
import { selectUser } from '../../store/selectors/user.selector';
import { Store } from '@ngrx/store';
import { IAppState } from '../../store/state/app.state';

@Component({
    selector: 'app-about-us-',
    templateUrl: './about-us.component.html',
    styleUrls: ['./about-us.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AboutUsComponent {
    breadcrumbsList: Array<IBreadcrumb> = [
        {
            label: 'ABOUT_US',
            path: '/',
        },
    ];
    user$ = this.store.select(selectUser);

    constructor(private store: Store<IAppState>) {}
}
