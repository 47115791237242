<section class="create-account">
    <app-header></app-header>
    <h1 [ngClass]="{ tnt: skin.whiteLabel === 'TAGANDTRACK' }">
        {{ (skin.whiteLabel !== 'TAGANDTRACK' ? 'ADD_ACCOUNT' : 'CREATE_ACCOUNT') | translate }}
    </h1>

    <app-create-account-stepper [selectedIndex]="0"></app-create-account-stepper>

    <div class="content account-info" rtl>
        <form [formGroup]="accountDetailsForm">
            <div class="form-content">
                <div class="user-img">
                    <i
                        class="icon icon-default-avatar"
                        [inlineSVG]="'#admin'"
                        *ngIf="!profilePhotoSrc; else userPhoto"
                    ></i>

                    <ng-template #userPhoto>
                        <img class="img" [src]="profilePhotoSrc" alt="User photo" />
                    </ng-template>

                    <div class="edit-btn">
                        <i class="icon" [inlineSVG]="'#edit_2'" (click)="openProfilePhotoDialog($event)"></i>
                    </div>
                </div>

                <mat-form-field class="form-field" appearance="outline">
                    <mat-label>{{ 'FIRST_NAME' | translate }}*</mat-label>
                    <input
                        matInput
                        placeholder="{{ 'PLEASE_ENTER_FIRST_NAME' | translate }}"
                        formControlName="firstName"
                    />
                    <mat-error *ngIf="accountDetailsForm.controls.firstName.errors?.maxlength">
                        {{ 'NAME_LENGTH_ERROR' | translate: { amount: 30 } }}
                    </mat-error>
                </mat-form-field>

                <mat-form-field class="form-field" appearance="outline">
                    <mat-label>{{ 'LAST_NAME' | translate }}*</mat-label>
                    <input
                        matInput
                        placeholder="{{ 'PLEASE_ENTER_LAST_NAME' | translate }}"
                        formControlName="lastName"
                    />
                    <mat-error *ngIf="accountDetailsForm.controls.lastName.errors?.maxlength">
                        {{ 'NAME_LENGTH_ERROR' | translate: { amount: 30 } }}
                    </mat-error>
                </mat-form-field>

                <mat-form-field class="form-field" appearance="outline">
                    <mat-label>{{ 'EMAIL' | translate }}*</mat-label>
                    <input matInput placeholder="{{ 'ENTER_EMAIL' | translate }}" formControlName="email" />
                </mat-form-field>
                <!-- <p class="input-text-error"
            *ngIf="accountDetailsForm.controls.email.invalid  && accountDetailsForm.controls.email.touched">
            {{'PLEASE_ENTER_EMAIL_VALID' | translate }}</p> -->

                <app-phone-input [isRequired]="true"></app-phone-input>

                <mat-form-field class="form-field" appearance="outline">
                    <mat-label>{{ 'PASSWORD' | translate }}*</mat-label>
                    <input
                        matInput
                        placeholder="{{ 'ENTER_PASSWORD' | translate }}"
                        formControlName="password"
                        [type]="showPassword ? 'text' : 'password'"
                    />
                    <i
                        class="icon"
                        (click)="changeVisiblePassword()"
                        matSuffix
                        [inlineSVG]="showPassword ? '#eye' : '#eye-close'"
                    ></i>
                </mat-form-field>

                <app-password-hints
                    [formControlValue]="accountDetailsForm.controls.password"
                ></app-password-hints>

                <mat-form-field class="form-field" appearance="outline">
                    <mat-label>{{ 'CONFIRM_NEW_PASSWORD' | translate }}*</mat-label>
                    <input
                        matInput
                        placeholder="{{ 'CONFIRM_NEW_PASSWORD' | translate }}"
                        formControlName="passwordConfirmed"
                        [type]="showConfirmePassword ? 'text' : 'password'"
                    />
                    <i
                        class="icon"
                        (click)="changeVisibleConfirmPassword()"
                        matSuffix
                        [inlineSVG]="showConfirmePassword ? '#eye' : '#eye-close'"
                    ></i>
                </mat-form-field>

                <mat-form-field
                    class="form-field"
                    appearance="outline"
                    *ngIf="skin.whiteLabel === 'TAGANDTRACK'"
                >
                    <mat-label>{{ 'LANGUAGE' | translate }}</mat-label>
                    <mat-select matNativeControl formControlName="language">
                        <mat-option *ngFor="let inLanguage of lenguages" [value]="inLanguage.value"
                            >{{ inLanguage.viewValue }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field
                    class="form-field"
                    appearance="outline"
                    *ngIf="skin.whiteLabel === 'TAGANDTRACK'"
                >
                    <mat-label>{{ 'COUNTRY' | translate }}</mat-label>
                    <mat-select matNativeControl formControlName="country">
                        <mat-option *ngFor="let inCountry of countries" [value]="inCountry.value"
                            >{{ inCountry.viewValue }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-checkbox
                    [class.invalid-checkbox]="isTermsChecked === false"
                    class="form-field checkbox primary terms-and-conditions"
                    color="primary"
                    formControlName="terms"
                >
                    {{ 'I_ACCEPT_THE' | translate }}
                    <ng-container *ngIf="skin.whiteLabel !== 'TAGANDTRACK'; else tntTermsCond">
                        <a
                            target="_blank"
                            href="https://trackimoplus.com/terms-of-service"
                            class="link-primary"
                        >
                            {{ 'TERMS_AND_CONDITIONS' | translate }}
                        </a>
                    </ng-container>
                    <ng-template #tntTermsCond>
                        <span (click)="openDialog($event, true, true)" class="link-primary">
                            {{ 'TERMS_AND_CONDITIONS' | translate }}</span
                        >
                    </ng-template>
                </mat-checkbox>
                <mat-checkbox
                    [class.invalid-checkbox]="isPrivacyChecked === false"
                    class="form-field checkbox primary privacy-policy"
                    color="primary"
                    formControlName="privacy"
                >
                    {{ 'I_ACCEPT_THE' | translate }}
                    <a
                        target="_blank"
                        href="{{
                            skin.whiteLabel !== 'TAGANDTRACK'
                                ? 'https://trackimoplus.com/privacy-policy'
                                : 'https://www.vodafone.com/business/privacy-policy'
                        }}"
                        class="link-primary"
                    >
                        {{ 'PRIVACY_POLICY' | translate }}
                    </a>
                </mat-checkbox>
            </div>

            <app-status-label *ngIf="createAccountError$ | async" [statusLabel]="createAccountError$ | async">
            </app-status-label>

            <div class="nav-container">
                <button class="btn basic" routerLink="'/'" routerLinkActive="router-link-active">
                    {{ 'CANCEL' | translate }}
                </button>
                <button
                    class="btn primary"
                    mat-flat-button
                    (click)="submitAccountDetailsForm(accountDetailsForm.valid)"
                >
                    {{ (skin.whiteLabel === 'TAGANDTRACK' ? 'NEXT' : 'ADD') | translate }}
                </button>
            </div>
        </form>
    </div>
    <app-footer class="footer"></app-footer>
</section>
