import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Inject,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { catchError, of, Subscription, switchMap, timer } from 'rxjs';
import { IDeviceLocationEvent } from 'src/app/store/interfaces';
import { ApiService } from 'src/app/store/services';
import { NoGPSSignalDialogComponent } from '../no-gps-dialog/no-gps-dialog.component';

@Component({
    selector: 'app-schedule-sleep-turn-off',
    templateUrl: './schedule-sleep-turn-off.component.html',
    styleUrls: ['./schedule-sleep-turn-off.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScheduleSleepTurnOffComponent implements OnInit, OnDestroy {
    intervalPeriod: number;
    subscription: Subscription;
    isTurnOffDisabled: boolean;

    constructor(
        public dialogRef: MatDialogRef<NoGPSSignalDialogComponent>,
        private apiService: ApiService,
        private cdRef: ChangeDetectorRef,
        @Inject(MAT_DIALOG_DATA) public data,
    ) {}

    ngOnInit(): void {
        this.subscription = timer(0, 3000)
            .pipe(
                switchMap(() =>
                    this.apiService.getDevicesLocations(this.data.userID).pipe(catchError((err) => of(err))),
                ),
            )
            .subscribe((locations: IDeviceLocationEvent[]) => {
                const deviceLocation = locations.find((device) => device.device_id === this.data.deviceID);
                this.isTurnOffDisabled = !deviceLocation.comm_stat;
                this.cdRef.markForCheck();
            });
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    turnOff(): void {
        this.dialogRef.close(true);
    }

    close(): void {
        this.dialogRef.close();
    }
}
