<ul class="breadcrumbs">
    <li class="breadcrumb">
        <span class="link" (click)="goHome()">{{ 'HOME' | translate }}</span>
    </li>

    <li *ngFor="let item of breadcrumbsList; let last = last" class="breadcrumb">
        <a [routerLink]="!last ? item.path : []" class="link" [ngClass]="{ active: last }">
            {{ item.label | translate }}
        </a>
    </li>
</ul>
