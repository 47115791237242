import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'terms-and-conditions-dialog',
    templateUrl: 'terms-and-conditions-dialog.html',
    styleUrls: ['./terms-and-conditions-dialog.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TermsAndConditionsDialog {
    constructor(
        public dialogRef: MatDialogRef<TermsAndConditionsDialog>,
        @Inject(MAT_DIALOG_DATA) public data,
    ) {}

    closeDialog(checked: boolean): void {
        checked
            ? this.dialogRef.close((this.data.checked = !this.data.checked))
            : this.dialogRef.close(this.data.checked);
    }
}
