import { ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { skin } from 'src/white-labels';
import { IBreadcrumb } from '../../store/interfaces/breadcrumb.interface';
import { TNT_PRIVACY_POLICY } from '../../store/constants/common.constants';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-privacy-policy',
    templateUrl: './privacy-policy.component.html',
    styleUrls: ['./privacy-policy.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class PrivacyPolicyComponent implements OnInit {
    @Input() isShowHeader: boolean;
    @Input() isShowFooter: boolean;
    skin = skin;
    privacyPoliciesTNT = TNT_PRIVACY_POLICY;
    breadcrumbsList: Array<IBreadcrumb> = [
        {
            label: this.translate.instant('PRIVACY_POLICY'),
            path: '/',
        },
    ];

    constructor(private route: ActivatedRoute, private translate: TranslateService) {}

    ngOnInit(): void {
        this.route.data.subscribe(({ isShowHeader, isShowFooter }) => {
            this.isShowHeader = isShowHeader;
            this.isShowFooter = isShowFooter;
        });
    }
}
