import { createAction, props } from '@ngrx/store';
import { IStatusLabel } from '../interfaces';

const prefix = '[Status Label]';

const showStatusLabel = createAction(`${prefix} Show`, props<{ statusLabel: IStatusLabel }>());

const hideStatusLabel = createAction(`${prefix} Hide`);

export const StatusLabelActions = {
    hideStatusLabel,
    showStatusLabel,
};
