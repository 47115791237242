import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { ISigupResendLink, IUser } from '../interfaces';

const prefix = '[User]';

const getUser = createAction(`${prefix}`);
const getUserSuccess = createAction(`${prefix} Success`, props<{ response: IUser }>());
const getUserError = createAction(`${prefix} Error`, props<{ error: HttpErrorResponse }>());

const resendLink = createAction(`${prefix} Resend Link`, props<{ resendData: ISigupResendLink }>());
const resendLinkSuccess = createAction(`${prefix} Resend Link Success`, props<{ msg: string }>());

const updateUser = createAction(`${prefix} Update User`, props<{ userData: any }>());
const updateUserSuccess = createAction(`${prefix} Update User Success`);
const updateUserError = createAction(`${prefix} Update User Error`, props<{ error: HttpErrorResponse }>());

const updateUserPhoto = createAction(`${prefix} Update User Photo`, props<{ userPhoto: File }>());
const updateUserPhotoSuccess = createAction(`${prefix} Update User Photo Success`);
const updateUserPhotoError = createAction(
    `${prefix} Update User Photo Error`,
    props<{ error: HttpErrorResponse }>(),
);

const deleteAccount = createAction(`${prefix} Delete Account`);
const deleteAccountSuccess = createAction(`${prefix} Delete My Account Success`,props<{ accountID: number }>());
const deleteAccountError = createAction(
    `${prefix} Delete My Account Error`,
    props<{ error: HttpErrorResponse }>(),
);

export const UserActions = {
    getUser,
    getUserSuccess,
    getUserError,
    resendLink,
    resendLinkSuccess,
    updateUser,
    updateUserSuccess,
    updateUserError,
    updateUserPhoto,
    updateUserPhotoSuccess,
    updateUserPhotoError,
    deleteAccount,
    deleteAccountSuccess,
    deleteAccountError,
};
