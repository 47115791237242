import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { Observable, of } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class AccountPagesJobsService implements CanActivate {
    constructor(private router: Router) {}

    canActivate(next: ActivatedRouteSnapshot): Observable<boolean> {
        if (localStorage.getItem('token')) {
            this.router.navigate(['/map']);
            return of(false);
        } else {
            return of(true);
        }
    }
}
