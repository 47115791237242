import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ContactsActions } from '../../store/actions/contacts.actions';
import { Store } from '@ngrx/store';
import { IAppState } from '../../store/state/app.state';
import {
    EMAIL_VALIDATOR_REG_EXP,
    TEXT_INPUT,
    URL_VALIDATION_PATTERN,
} from '../../store/constants/common.constants';
import { skin } from 'src/white-labels';
import { MatRadioChange } from '@angular/material/radio';
import { PhoneInputComponent } from 'src/app/shared/phone-input/phone-input.component';
import { Subject, takeUntil } from 'rxjs';
import { Actions, ofType } from '@ngrx/effects';

@Component({
    selector: 'app-add-contact-dialog',
    templateUrl: './add-contact-dialog.component.html',
    styleUrls: ['./add-contact-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddContactDialogComponent implements OnInit, OnDestroy {
    @ViewChild(PhoneInputComponent) phoneInputComponent: PhoneInputComponent;

    addContactForm: FormGroup = this.formBuilder.group({
        contactName: [
            '',
            [
                Validators.required,
                Validators.minLength(3),
                Validators.maxLength(30),
                Validators.pattern('^[a-zA-Z ]*$'),
            ],
        ],
        contactType: ['EMAIL'],
        address: this.formBuilder.group({
            contactEmail: [
                { value: '', disabled: false },
                [Validators.required, Validators.pattern(EMAIL_VALIDATOR_REG_EXP)],
            ],
            contactWebsite: [
                { value: '', disabled: true },
                [Validators.required, Validators.pattern(URL_VALIDATION_PATTERN)],
            ],
        }),
    });
    skin = skin;
    private destroyed$ = new Subject<void>();

    constructor(
        public dialogRef: MatDialogRef<AddContactDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data,
        private formBuilder: FormBuilder,
        private store: Store<IAppState>,
        private readonly actions$: Actions,
    ) {
        // dialogRef.afterOpened().subscribe((result) => {
        //     this.phoneInputComponent.toggleDisableInput();
        // });
    }

    ngOnInit() {
        this.actions$
            .pipe(ofType(ContactsActions.addContactSuccess), takeUntil(this.destroyed$))
            .subscribe(() => this.closeDialog());
    }

    ngOnDestroy(): void {
        this.destroyed$.next();
        this.destroyed$.complete();
    }

    closeDialog(): void {
        this.dialogRef.close();
    }

    selectContactType(event: MatRadioChange) {
        if (event instanceof MatRadioChange) {
            const controls = this.addContactForm.controls.address['controls'];

            switch (event.value) {
                case 'PHONE':
                    this.phoneInputComponent.enableInputs();
                    for (const name in controls) {
                        controls[name].disable();
                    }
                    break;
                case 'EMAIL':
                    controls.contactEmail.enable();
                    controls.contactWebsite.disable();
                    this.phoneInputComponent.disableInputs();
                    break;

                case 'URL':
                    controls.contactEmail.disable();
                    controls.contactWebsite.enable();
                    this.phoneInputComponent.disableInputs();
                    break;

                default:
                    break;
            }
        }
    }

    submitContactFrom(isValid: boolean) {
        this.addContactForm.markAllAsTouched();

        if (skin.whiteLabel !== 'TAGANDTRACK') {
            this.phoneInputComponent.validatePhoneForm();
        }

        if (isValid) {
            const formValues = this.addContactForm.value;
            const address = formValues.address;
            const phoneCountryData = this.phoneInputComponent?.countryCtrl?.value;
            const phoneNumber = this.phoneInputComponent?.phoneNumb?.value;
            const payload = {
                name: formValues.contactName,
                type: formValues.contactType,
                address: null,
            };
            if (formValues.contactType === 'PHONE') {
                payload.address = `+${phoneCountryData[2]}${phoneNumber}`;
                if (this.phoneInputComponent.countryCtrl.valid && this.phoneInputComponent.phoneNumb.valid) {
                    console.log(payload);
                    this.store.dispatch(ContactsActions.addContact({ payload }));
                }
            } else {
                payload.address = address[Object.keys(address)[0]];
                this.store.dispatch(ContactsActions.addContact({ payload }));
            }
        }
    }
}
