import { createReducer, on } from '@ngrx/store';
import { DescendantsActions } from '../actions/descendants';
import { initialDescendantsState } from '../state/descendants.stete';

export const descendantsReducer = createReducer(
    initialDescendantsState,
    on(DescendantsActions.getDescendantsSuccess, (state, { response }) => ({
        ...state,
        descendants: response,
    })),
    on(DescendantsActions.clearDescendants, (state, {}) => ({
        ...state,
        descendants: undefined,
    })),
);
