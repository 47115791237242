import { EventEmitter, Output } from '@angular/core';
import { Component, ChangeDetectionStrategy } from '@angular/core';
import { skin } from 'src/white-labels';

@Component({
    selector: 'app-cookie-bar',
    templateUrl: './cookie-bar.component.html',
    styleUrls: ['./cookie-bar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CookieBarComponent {
    @Output() acceptCookieEvent: EventEmitter<any> = new EventEmitter();
    skin = skin;
    constructor() {}

    acceptCookie() {
        this.acceptCookieEvent.emit();
    }
}
