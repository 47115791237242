<div class='cookie-bar'>
    <div class='cookie-bar-left-side'>
        <span>
            {{'COOKIE_MSG1' | translate}}
        </span>
        <span>
            {{'COOKIE_MSG2' | translate}}
        </span>
        <a *ngIf="skin.whiteLabel === 'TAGANDTRACK'" routerLink="{{ '/about/privacy-policy'}}">
            {{'PRIVACY_POLICY' | translate}}
        </a>
        <a *ngIf="skin.whiteLabel !== 'TAGANDTRACK'" target="_blank" href="https://trackimoplus.com/privacy-policy">
            {{'PRIVACY_POLICY' | translate}}.
        </a>
        <span>
            {{'WE_ALSO_URGE_YOU_TO_READ' | translate}}
        </span>
        <a *ngIf="skin.whiteLabel === 'TAGANDTRACK'" routerLink="{{ '/terms-and-conditions'}}">
            {{'TERMS_AND_CONDITIONS' | translate}}
        </a>
        <a *ngIf="skin.whiteLabel !== 'TAGANDTRACK'" target="_blank" href="https://trackimoplus.com/terms-of-service">
            {{'TERMS_AND_CONDITIONS' | translate}}
        </a>
        <span>
            {{'COOKIE_MSG3' | translate}}
        </span>
    </div>
    <div class="cookie-bar-right-side">
        <button (click)="acceptCookie()" class="btn outline" mat-flat-button>{{'ACCEPT_CLOSE' | translate}}</button>
    </div>
</div>