<div class="info-wrapper">
    <div class="tnt-container">
        <h1 class="page-header">{{ 'USER_GUIDE' | translate }}</h1>
        <app-breadcrumbs [breadcrumbsList]="breadcrumbsList"> </app-breadcrumbs>
        <mat-accordion class="tnt-accordion">
            <mat-expansion-panel class="expansion-item" *ngFor="let item of userGuideInfo">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        {{ item.title | translate }}
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div [innerHTML]="item.innerText | translate"></div>
            </mat-expansion-panel>
        </mat-accordion>
    </div>

    <app-footer class="footer"></app-footer>
</div>
