import { ActionReducer } from '@ngrx/store';
import { IAppState } from '../state/app.state';

export const clearStateMetaReducer = (reducer: ActionReducer<IAppState>): ActionReducer<IAppState> => {
    return (state, action) => {
        if (action.type === '[Auth] Logout') {
            state = {} as IAppState;
            state = {
                ...state,
                status: {
                    ...state.status,
                    mapLoaded: true,
                },
            };
        }
        return reducer(state, action);
    };
};
