<div class="dialog-wrapper add-contact-dialog" rtl>
    <div mat-dialog-title>
        <h1 class="title">{{ 'ADD_CONTACT' | translate }}</h1>
    </div>
    <div mat-dialog-content>
        <form [formGroup]="addContactForm">
            <mat-form-field class="form-field" appearance="outline">
                <mat-label>{{ 'NAME' | translate }}</mat-label>
                <input matInput placeholder="{{ 'NAME' | translate }}" formControlName="contactName" />
                <mat-error *ngIf="addContactForm.controls.contactName.errors?.maxlength">
                    {{ 'NAME_LENGTH_ERROR' | translate: { amount: 30 } }}
                </mat-error>
            </mat-form-field>

            <mat-radio-group formControlName="contactType" (change)="selectContactType($event)">
                <div formGroupName="address">
                    <div class="radio-button">
                        <mat-radio-button class="contact-type-radio" value="EMAIL" color="primary"
                            *ngIf="skin.whiteLabel !== 'TAGANDTRACK'"></mat-radio-button>
                        <mat-form-field class="form-field" appearance="outline">
                            <mat-label>{{ 'EMAIL' | translate }}</mat-label>
                            <input matInput placeholder="{{ 'ENTER_EMAIL' | translate }}"
                                formControlName="contactEmail" />
                        </mat-form-field>
                    </div>

                    <ng-container *ngIf="skin.whiteLabel !== 'TAGANDTRACK'">
                        <div class="radio-button">
                            <mat-radio-button class="contact-type-radio" value="PHONE" color="primary">
                            </mat-radio-button>
                            <app-phone-input class="phone-input" [isDisabled]="true"></app-phone-input>
                        </div>

                        <div class="radio-button">
                            <mat-radio-button class="contact-type-radio" value="URL" color="primary"></mat-radio-button>
                            <mat-form-field class="form-field" appearance="outline">
                                <mat-label>{{ 'WEB_SITE' | translate }}</mat-label>
                                <input matInput placeholder="{{ 'ENTER_WEB_SITE' | translate }}"
                                    formControlName="contactWebsite" />
                            </mat-form-field>
                        </div>
                    </ng-container>
                </div>
            </mat-radio-group>
        </form>
    </div>
    <div mat-dialog-actions>
        <div class="dialog-actions">
            <button class="btn basic" (click)="closeDialog()" mat-flat-button>
                {{ 'CANCEL' | translate }}
            </button>
            <button class="btn primary" (click)="submitContactFrom(addContactForm.valid)" mat-flat-button>
                {{ 'ADD' | translate }}
            </button>
        </div>
    </div>
</div>
