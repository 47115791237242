import { IDeviceByToken, IDeviceHistory } from '../interfaces';

export interface ISharedState {
    historyItem: IDeviceHistory;
    brainTreeToken: string;
    deviecByToken: IDeviceByToken;
}

export const initialSharedState: ISharedState = {
    historyItem: undefined,
    brainTreeToken: undefined,
    deviecByToken: undefined,
};
