import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { selectUser } from '../selectors/user.selector';
import { ApiService } from '../services';
import { IAppState } from '../state/app.state';
import { ContactsActions } from '../actions/contacts.actions';
import { TranslateService } from '@ngx-translate/core';
import { SnackbarService } from '../services/snackbar.service';

@Injectable()
export class ContactsEffects {
    getContacts$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ContactsActions.getContacts),
            withLatestFrom(this.store.select(selectUser)),
            switchMap(([, user]) =>
                this.apiService.getContacts(user.account_id).pipe(
                    map((response) => ContactsActions.getContactsSuccess({ response })),
                    catchError((error) => of(ContactsActions.getContactsError({ error }))),
                ),
            ),
        ),
    );

    addContact$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ContactsActions.addContact),
            map((action) => action.payload),
            withLatestFrom(this.store.select(selectUser)),
            switchMap(([payload, user]) =>
                this.apiService.addContact(user.account_id, payload).pipe(
                    map((response) =>
                        ContactsActions.addContactSuccess({
                            msg: this.translate.instant('CONTACT_ADDED_SUCCESSFULLY'),
                        }),
                    ),
                    catchError(({ error }) => of(ContactsActions.addContactError({ error }))),
                ),
            ),
        ),
    );

    addContactSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(ContactsActions.addContactSuccess),
                tap(() => this.store.dispatch(ContactsActions.getContacts())),
            ),
        { dispatch: false },
    );

    deleteContact$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ContactsActions.deleteContact),
            map((action) => action.payload),
            withLatestFrom(this.store.select(selectUser)),
            switchMap(([payload, user]) =>
                this.apiService.deleteContact(user.account_id, payload.id).pipe(
                    map((response) =>
                        ContactsActions.deleteContactSuccess({
                            msg: this.translate.instant('CONTACT_DELETED', { name: payload.name }),
                        }),
                    ),
                    catchError(({ error }) => of(ContactsActions.deleteContactError({ error }))),
                ),
            ),
        ),
    );

    deleteContactSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(ContactsActions.deleteContactSuccess),
                tap(() => this.store.dispatch(ContactsActions.getContacts())),
            ),
        { dispatch: false },
    );

    editContact$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ContactsActions.editContact),
            map((action) => action),
            withLatestFrom(this.store.select(selectUser)),
            switchMap(([action, user]) =>
                this.apiService.updateContact(user.account_id, action.contactId, action.payload).pipe(
                    map((response) =>
                        ContactsActions.editContactSuccess({
                            msg: this.translate.instant('UPDATE_CONTACT_SUCCESS'),
                        }),
                    ),
                    catchError(({ error }) => of(ContactsActions.editContactError({ error }))),
                ),
            ),
        ),
    );

    editContactSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(ContactsActions.editContactSuccess),
                tap(() => this.store.dispatch(ContactsActions.getContacts())),
            ),
        { dispatch: false },
    );

    contactSuccessSnackbar$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(
                    ContactsActions.addContactSuccess,
                    ContactsActions.deleteContactSuccess,
                    ContactsActions.editContactSuccess,
                ),
                tap((resonse) => this.snackBar.success(resonse.msg)),
            ),
        { dispatch: false },
    );

    contactErrorSnackbar$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(
                    ContactsActions.addContactError,
                    ContactsActions.deleteContactError,
                    ContactsActions.editContactError,
                ),
                tap(({ error }) => this.snackBar.error(error?.message)),
            ),
        { dispatch: false },
    );

    constructor(
        private actions$: Actions,
        private apiService: ApiService,
        private store: Store<IAppState>,
        private snackBar: SnackbarService,
        private translate: TranslateService,
    ) {}
}
