import { DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { skin } from 'src/white-labels';
import { LocalStorageService } from './store/services/local-storage.service';
import { FeatureToggleService, HotkeysService, TutorialsService } from './store/services';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
    favIcon: HTMLLinkElement = this.document.querySelector('#favIcon');
    skin = skin;

    constructor(
        translate: TranslateService,
        public tutorials: TutorialsService,
        private localStorage: LocalStorageService,
        @Inject(DOCUMENT) private document: Document,
        private renderer: Renderer2,
        private titleService: Title,
        private hotkeysService: HotkeysService,
        private featureToggleService: FeatureToggleService,
    ) {
        this.titleService.setTitle(skin.brandName);

        if (skin.whiteLabel === 'TAGANDTRACK') {
            this.favIcon.href = 'assets/tnt-favicon.ico';
        }

        this.renderer.addClass(this.document.body, skin.whiteLabel.toLocaleLowerCase());

        translate.setDefaultLang('en');
        if (!this.localStorage.getItem('language')) {
            this.localStorage.setItem('language', 'en'); // Set default language to English(en)
        } else {
            translate.use(this.localStorage.getItem('language'));
        }
    }

    ngOnInit() {
        if (skin.whiteLabel === 'TRACKIMO') {
            this.featureToggleService.initFeaturesData();
        }
    }
}
