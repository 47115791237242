import { createSelector } from '@ngrx/store';
import { TrackingModeSettings } from '../constants/common.constants';
import { IAppState } from '../state/app.state';
import { IDeviceState } from '../state/devices.stete';

export const selectFeature = (appState: IAppState) => appState.devices || [];

export const selectDevicesList = createSelector(selectFeature, (appState: IDeviceState) =>
    appState.devices?.map((device) => ({
        ...device,
        isChecked: false,
    })),
);

export const selectDevicesIDsList = createSelector(selectFeature, (appState: IDeviceState) =>
    appState.devices?.map((device) => device.device_id),
);

export const selectDevicesWithLocation = createSelector(selectFeature, (appState: IDeviceState) =>
    appState.devices?.filter((device: any) => device.lat && device.lng),
);

export const selectSectedDevice = createSelector(selectFeature, (appState: IDeviceState) => {
    if (appState.selectedDevice && appState.selectedDevice.info) {
        return appState.selectedDevice;
    }
    return null;
});

export const selectDeviceHistory = createSelector(
    selectFeature,
    (appState: IDeviceState) => appState.selectedDevice?.history || [],
);

export const selectDeviceFences = createSelector(
    selectFeature,
    (appState: IDeviceState) => appState.selectedDevice?.assigned_fences,
);

export const selectDeviceRenewalPlans = createSelector(
    selectFeature,
    (appState: IDeviceState) => appState.selectedDevice?.renewalsPlans,
);

export const selectDeviceTrackingMode = createSelector(selectFeature, (appState: IDeviceState) => {
    const _trackingMode = appState.selectedDevice?.settings.preferences.tracking_mode;
    const value = Math.floor(_trackingMode.sample_rate / 60);
    let trackingMode = {
        type: '',
        value: value % 60 ? value : _trackingMode.sample_rate,
        measurment: value % 60 ? 'HOURS' : _trackingMode.tracking_measurment,
    };

    switch (_trackingMode.sample_rate) {
        case TrackingModeSettings.POWER_SAVING:
            trackingMode.type = 'POWER_SAVING';
            break;
        case TrackingModeSettings.EVERYDAY:
            trackingMode.type = 'EVERYDAY';
            break;
        case TrackingModeSettings.OFF:
            trackingMode.type = 'OFF';
            break;
        default:
            trackingMode.type = 'CUSTOM';
            break;
    }

    return trackingMode;
});

export const selectDevicesSubscription = createSelector(
    selectFeature,
    (appState: IDeviceState) => appState.devicesSubscription,
);

export const selectSBDevicesSubscription = createSelector(
    selectFeature,
    (appState: IDeviceState) => appState.sbDevicesSubscription,
);

export const selectDeviceLocatioPing = createSelector(
    selectFeature,
    (appState: IDeviceState) => appState.selectedDevice?.location_ping,
);

export const selectDeviceInfo = createSelector(
    selectFeature,
    (appState: IDeviceState) => appState.selectedDevice?.info,
);

export const selectDeviceActivePlans = createSelector(
    selectFeature,
    (appState: IDeviceState) => appState.selectedDevice?.active_plans,
);

export const selectIsDeviceSOS = createSelector(selectFeature, (appState: IDeviceState) =>
    Boolean(appState.selectedDevice?.allSOSEvents?.length),
);

export const selectDeviceScheduledSleep = createSelector(
    selectFeature,
    (appState: IDeviceState) => appState.selectedDevice?.settings.preferences.scheduled_sleep,
);

export const selectDevicePetSettings = createSelector(
    selectFeature,
    (appState: IDeviceState) => appState.selectedDevice?.info?.petDetailsSettings,
);
