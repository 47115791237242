import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AuthActions } from 'src/app/store/actions/auth.actions';
import {
    PASSWORD_VALIDATOR_REG_EXP,
    TNT_PASSWORD_VALIDATOR_REG_EXP,
} from 'src/app/store/constants/common.constants';
import { IResetPassword } from 'src/app/store/interfaces';
import { PasswordValidatorService } from 'src/app/store/services/password-validator/password-validator.service';
import { IAppState } from 'src/app/store/state/app.state';
import { skin } from 'src/white-labels';

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResetPasswordComponent implements OnInit {
    showPassword: boolean;
    showConfirmePassword: boolean;
    email: string;
    code: string;

    reserPasswordForm: FormGroup = this.formBuilder.group(
        {
            password: [
                '',
                [
                    Validators.required,
                    Validators.pattern(
                        skin.whiteLabel !== 'TAGANDTRACK'
                            ? PASSWORD_VALIDATOR_REG_EXP
                            : TNT_PASSWORD_VALIDATOR_REG_EXP,
                    ),
                ],
            ],
            passwordConfirmed: ['', [Validators.required]],
        },
        {
            validators: [this.validatorService.passwordsValidator('password', 'passwordConfirmed')],
        },
    );

    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private validatorService: PasswordValidatorService,
        private store: Store<IAppState>,
    ) {}

    ngOnInit(): void {
        this.route.queryParams.subscribe((params) => {
            if (params.email) {
                this.email = params.email;
                this.code = params.code;
            } else {
                this.router.navigate(['/']);
            }
        });
    }

    changeVisiblePassword() {
        this.showPassword = !this.showPassword;
    }

    changeVisibleConfirmPassword() {
        this.showConfirmePassword = !this.showConfirmePassword;
    }

    onSubmit(isValid: boolean) {
        this.reserPasswordForm.markAllAsTouched();

        if (isValid) {
            const passwordData: IResetPassword = {
                email: this.email,
                password: this.reserPasswordForm.value.password,
            };

            passwordData.code = this.code;

            this.store.dispatch(AuthActions.resetPassword({ passwordData }));
        }
    }
}
