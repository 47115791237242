<section class="activation-account">
    <app-header></app-header>

    <h1>{{'CREATE_ACCOUNT' | translate}}</h1>

    <app-create-account-stepper [selectedIndex]="1"></app-create-account-stepper>

    <div class="content confirmation" [ngClass]="{tnt: skin.whiteLabel === 'TAGANDTRACK'}">
        <p class="info">{{ 'WE_SENT_YOU_CODE_EMAIL' | translate }}<br />
            {{'SENT_TO_EMAIL' | translate}}
            <strong>{{ userEmail }}</strong>
        </p>

        <app-status-label *ngIf="verifyAccountError$ | async" [statusLabel]="verifyAccountError$ | async">
        </app-status-label>

        <form [formGroup]="form">
            <mat-form-field class="form-field" appearance="outline">
                <mat-label>{{ 'VERIFICATION_CODE' | translate }}</mat-label>
                <input formControlName="code" matInput placeholder="{{ 'PLEASE_ENTER_VERIFICATION_CODE' | translate }}">
            </mat-form-field>
        </form>

        <p class="resend">
            {{'RECEIVE_LINK' | translate}}
            <span class="resend-btn" (click)="resendEmailLink()">{{ 'RESEND_EMAIL' | translate }}</span>
        </p>

        <div class="nav-container" [ngClass]="{tnt: skin.whiteLabel === 'TAGANDTRACK'}">
            <button class="btn basic" routerLink="/create-account" *ngIf="skin.whiteLabel === 'TAGANDTRACK'">
                {{ 'BACK' | translate }}
            </button>
            <button class="btn primary" (click)="onSubmit(form.valid)" mat-flat-button>{{ (skin.whiteLabel ===
                'TAGANDTRACK'? 'NEXT' : 'VERIFY') | translate
                }}</button>
        </div>
    </div>
    <app-footer class="footer"></app-footer>
</section>