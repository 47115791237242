import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap, withLatestFrom, filter } from 'rxjs/operators';
import { selectUser } from '../selectors/user.selector';
import { ApiService, GoogleMapService } from '../services';
import { IAppState } from '../state/app.state';
import { TranslateService } from '@ngx-translate/core';
import { findAllByKey } from '../services/helper';
import { DescendantsActions } from '../actions/descendants';
import { selectDescendants, selectMainAccount } from '../selectors/descendants.selectors';
import { SnackbarService } from '../services/snackbar.service';
import { EventsActions } from '../actions/events.actions';

@Injectable()
export class DescendantsEffects {
    getDescendants$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DescendantsActions.getDescendants, DescendantsActions.removeDescendantSuccess),
            withLatestFrom(this.store.select(selectUser), this.store.select(selectMainAccount)),
            switchMap(([, user]) =>
                this.apiService.getDescendants(user.account_id).pipe(
                    map((response) => DescendantsActions.getDescendantsSuccess({ response })),
                    catchError((error) => of(DescendantsActions.getDescendantsError({ error }))),
                ),
            ),
        ),
    );

    getDescendantsSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(DescendantsActions.getDescendantsSuccess),
                withLatestFrom(this.store.select(selectDescendants)),
                filter(
                    ([, descendants]) => !!descendants.length && findAllByKey(descendants, 'devices').length,
                ),
                tap(() => this.googleMapService.removeMarkers()),
                tap(([, descendants]) =>
                    this.googleMapService.addMarkerCluster(findAllByKey(descendants, 'devices')),
                ),
                tap(([, descendants]) =>
                    this.googleMapService.fitBounds(findAllByKey(descendants, 'devices'), []),
                ),
            ),
        { dispatch: false },
    );

    removeDescendant$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DescendantsActions.removeDescendant),
            map((action) => action.email),
            withLatestFrom(this.store.select(selectUser)),
            mergeMap(([email, user]) =>
                this.apiService.removeDescendant(user.account_id, email).pipe(
                    map(() => DescendantsActions.removeDescendantSuccess()),
                    catchError((error) => of(DescendantsActions.removeDescendantError({ error }))),
                ),
            ),
        ),
    );

    removeDescendantSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(DescendantsActions.removeDescendantSuccess),
                tap(() => this.snackBar.success(this.translate.instant('SUBACCOUNT_DELETED'))),
            ),
        { dispatch: false },
    );

    // Calls immediately
    getEventsOnDevicesSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DescendantsActions.getDescendantsSuccess),
            withLatestFrom(this.store.select(selectUser)),
            switchMap(([, user]) =>
                this.apiService.getNotReadEvents(user.account_id, 'SOS', []).pipe(
                    map((response) => EventsActions.getSOSDevicesEventsSuccess({ response })),
                    catchError((error) => of(EventsActions.getSOSDevicesEventsError({ error }))),
                ),
            ),
        ),
    );

    constructor(
        private actions$: Actions,
        private apiService: ApiService,
        private store: Store<IAppState>,
        private snackBar: SnackbarService,
        private googleMapService: GoogleMapService,
        private translate: TranslateService,
    ) {}
}
