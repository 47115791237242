import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { ApiService } from '../services';
import { Store } from '@ngrx/store';
import { IAppState } from '../state/app.state';
import { TranslateService } from '@ngx-translate/core';
import { ClientsActions } from '../actions/clients.actions';
import { SnackbarService } from '../services/snackbar.service';

@Injectable()
export class ClientsEffects {
    getClients$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ClientsActions.getClients),
            switchMap(() =>
                this.apiService.getClients().pipe(
                    map((response) => ClientsActions.getClientsSuccess({ response })),
                    catchError((error) => of(ClientsActions.getClientsError({ error }))),
                ),
            ),
        ),
    );

    addClient$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ClientsActions.addClient),
            map((action) => action.payload),
            switchMap((payload) =>
                this.apiService.addNewApp(payload).pipe(
                    map((response) =>
                        ClientsActions.addClientSuccess({
                            msg: this.translate.instant('SUCCESS'),
                        }),
                    ),
                    catchError(({ error }) => of(ClientsActions.addClientError({ error }))),
                ),
            ),
        ),
    );

    addClientSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(ClientsActions.addClientSuccess),
                tap(() => this.store.dispatch(ClientsActions.getClients())),
            ),
        { dispatch: false },
    );

    deleteClient$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ClientsActions.deleteClient),
            map((action) => action.payload),
            switchMap((payload) =>
                this.apiService.deleteClient(payload.client_id).pipe(
                    map((response) =>
                        ClientsActions.deleteClientSuccess({
                            msg: this.translate.instant('SUCCESS'),
                        }),
                    ),
                    catchError(({ error }) => of(ClientsActions.deleteClientError({ error }))),
                ),
            ),
        ),
    );

    deleteClientSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(ClientsActions.deleteClientSuccess),
                tap(() => this.store.dispatch(ClientsActions.getClients())),
            ),
        { dispatch: false },
    );

    clientSuccessSnackbar$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(ClientsActions.addClientSuccess, ClientsActions.deleteClientSuccess),
                tap((response) => this.snackBar.success(response.msg)),
            ),
        { dispatch: false },
    );

    clientsErrorSnackbar$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(ClientsActions.addClientError, ClientsActions.deleteClientError),
                tap(({ error }) => this.snackBar.error(error?.message)),
            ),
        { dispatch: false },
    );

    constructor(
        private actions$: Actions,
        private apiService: ApiService,
        private store: Store<IAppState>,
        private snackBar: SnackbarService,
        private translate: TranslateService,
    ) {}
}
