import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IBreadcrumb } from 'src/app/store/interfaces/breadcrumb.interface';
import { skin } from 'src/white-labels';
import { TNT_CONTACT_US } from '../../store/constants/common.constants';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { IUser } from '../../store/interfaces';
import { selectUser } from '../../store/selectors/user.selector';
import { Store } from '@ngrx/store';
import { IAppState } from '../../store/state/app.state';

@Component({
    selector: 'app-contact-us',
    templateUrl: './contact-us.component.html',
    styleUrls: ['./contact-us.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactUsComponent implements OnInit {
    user$: Observable<IUser>;
    skin = skin;
    contactsTNT = TNT_CONTACT_US;
    breadcrumbsList: Array<IBreadcrumb> = [
        {
            label: this.translate.instant('CONTACT_US'),
            path: '/',
        },
    ];
    isShowHeader: boolean;

    constructor(
        private translate: TranslateService,
        private route: ActivatedRoute,
        private store: Store<IAppState>,
    ) {}

    ngOnInit(): void {
        this.user$ = this.store.select(selectUser);
        this.route.data.subscribe(({ isShowHeader }) => (this.isShowHeader = isShowHeader));
    }
}
