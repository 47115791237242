import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import { GoogleMap } from '@angular/google-maps';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { DevicesActions } from 'src/app/store/actions/devices.actions';
import { MapActions } from 'src/app/store/actions/map.actions';
import { IDeviceByToken } from 'src/app/store/interfaces';
import { selectDeviceGettedByToken } from 'src/app/store/selectors/shared.selector';
import { GoogleMapService } from 'src/app/store/services';
import { IAppState } from 'src/app/store/state/app.state';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { Actions, ofType } from '@ngrx/effects';

@Component({
    selector: 'app-public-map',
    templateUrl: './public-map.component.html',
    styleUrls: ['./public-map.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PublicMapComponent implements OnInit, OnDestroy {
    @ViewChild('GoogleMap') set map(map: GoogleMap) {
        if (map) {
            this.googleMapService.map = map;
        }
    }
    address$: Observable<string>;
    device$: Observable<IDeviceByToken>;
    mapApiLoaded: boolean;

    private destroyed$ = new Subject<void>();

    constructor(
        private googleMapService: GoogleMapService,
        private store: Store<IAppState>,
        private route: ActivatedRoute,
        private cdRef: ChangeDetectorRef,
        private readonly actions$: Actions,
    ) {}

    ngOnInit(): void {
        if (!this.googleMapService.map) {
            this.store.dispatch(MapActions.loadMap());
        }

        this.device$ = this.store.select(selectDeviceGettedByToken).pipe(
            filter((device) => Boolean(device)),
            tap((device) => {
                this.address$ = this.googleMapService.getAddress(
                    device.geoLocation.latitude,
                    device.geoLocation.longitude,
                );
            }),
        );
        this.actions$
            .pipe(ofType(MapActions.mapLoadedSuccess), takeUntil(this.destroyed$))
            .subscribe(({ response }) => {
                this.mapApiLoaded = true;
                this.cdRef.markForCheck();
                this.route.queryParams.subscribe((params) =>
                    this.store.dispatch(DevicesActions.getDeviceDetailsByToken({ token: params.token })),
                );
            });
    }

    ngOnDestroy(): void {
        this.destroyed$.next();
        this.destroyed$.complete();
    }
}
