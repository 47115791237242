<ng-container *ngIf="skin.whiteLabel !== 'TAGANDTRACK'">
    <div class="password-hints password-hints-plus">
        <div class="password-hints-plus-img-wrap">
            <i class="icon" [inlineSVG]="'#information-white'"></i>
        </div>
        <div class="password-hints-content password-hints-plus-content">
            <p class="password-hints-text">{{ 'YOUR_PASSWORD_SHOULD' | translate }}:</p>
            <p
                class="password-hints-text circle"
                [ngClass]="{ checked: (passwordHints$ | async)?.correctLength }"
            >
                {{ 'BE_6_24_CHARACTERS_IN_LENGTH' | translate }}
            </p>
            <p
                class="password-hints-text circle"
                [ngClass]="{ checked: (passwordHints$ | async)?.containUpperCase }"
            >
                {{ 'PASSWORD_1_UPPER_CHARACTER' | translate }}
            </p>
            <p
                class="password-hints-text circle"
                [ngClass]="{ checked: (passwordHints$ | async)?.containDigit }"
            >
                {{ 'PASSWORD_1_DIGIT' | translate }}
            </p>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="skin.whiteLabel === 'TAGANDTRACK'">
    <div class="password-hints">
        <p class="password-hints-text">{{ 'YOUR_PASSWORD_SHOULD' | translate }}:</p>
        <p
            class="password-hints-text circle"
            [ngClass]="{ checked: (passwordHints$ | async)?.correctLength }"
        >
            {{ 'PASS_MIN_MAX' | translate: { min: 8, max: 24 } }}
        </p>
        <p
            class="password-hints-text circle"
            [ngClass]="{ checked: (passwordHints$ | async)?.containUpperCase }"
        >
            {{ 'PASSWORD_1_UPPER_CHARACTER' | translate }}
        </p>
        <p
            class="password-hints-text circle"
            [ngClass]="{ checked: (passwordHints$ | async)?.containLowerCase }"
        >
            {{ 'PASSWORD_1_LOWER_CHARACTER' | translate }}
        </p>
        <p class="password-hints-text circle" [ngClass]="{ checked: (passwordHints$ | async)?.containDigit }">
            {{ 'PASSWORD_1_DIGIT' | translate }}
        </p>
        <p
            class="password-hints-text circle"
            [ngClass]="{ checked: (passwordHints$ | async)?.containSpecialChar }"
        >
            {{ 'PASSWORD_1_SPECIAL_CHARACTER' | translate }}
        </p>
    </div>
</ng-container>
