<h1 class="title">{{ 'FORGOT_PASSWORD' | translate }}</h1>

<p class="description">
    {{ 'ASSOCIATED_EMAIL' | translate }}
</p>

<form [formGroup]="forgotPasswordForm" (ngSubmit)="onSubmit(forgotPasswordForm.valid)">
    <div class="input-text-wrapper">
        <mat-form-field class="form-field" appearance="outline">
            <mat-label>{{ 'EMAIL' | translate }}</mat-label>
            <input matInput required placeholder="{{ 'EMAIL' | translate }}" formControlName="email" />
        </mat-form-field>
        <p
            class="input-text-error"
            *ngIf="forgotPasswordForm.controls.email.invalid && forgotPasswordForm.controls.email.touched"
        >
            {{ 'PLEASE_ENTER_EMAIL_VALID' | translate }}
        </p>
    </div>

    <button class="btn primary form-btn" type="submit" mat-flat-button>
        {{ 'RESET_PASSWORD' | translate }}
    </button>
</form>

<button class="btn basic" routerLink="/">{{ 'CANCEL' | translate }}</button>
