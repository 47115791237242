import { IStatusLabel } from '../interfaces';

export interface IStatusState {
    paymentProcessing: boolean;
    loginProcessing: boolean;
    loginSuccess: boolean;
    devicesLoaded: boolean;
    deviceLoaded: boolean;
    zonesLoaded: boolean;
    mapLoaded: boolean;
    subAccountsLoaded: boolean;
    statusLabel: IStatusLabel;
    devicesLocationsStatus: string;
    pingDeviceLocation: boolean;
    noLocationModal: boolean;
}

export const initialStatusState: IStatusState = {
    paymentProcessing: false,
    loginProcessing: false,
    loginSuccess: false,
    devicesLoaded: false,
    deviceLoaded: false,
    zonesLoaded: false,
    mapLoaded: false,
    subAccountsLoaded: false,
    statusLabel: undefined,
    devicesLocationsStatus: undefined,
    pingDeviceLocation: false,
    noLocationModal: false,
};
