<div class="main-container">
    <app-header [isShowMobSupportMenu]="true" class="header" [user]="user$ | async"></app-header>
    <div class="banner-top"></div>
    <div class="navbar-wrapper">
        <nav mat-tab-nav-bar mat-align-tabs="center">
            <a
                mat-tab-link
                *ngFor="let link of navLinks"
                [routerLink]="link.link"
                routerLinkActive
                #rla="routerLinkActive"
                [active]="rla.isActive"
                class="mat-tab-link"
            >
                {{ link.label | translate }}
            </a>
        </nav>
    </div>
    <router-outlet></router-outlet>
</div>
