<div class="dialog-wrapper">
    <div class="dialog-title-wrapper" mat-dialog-title>
        <h1 class="title">{{ 'NO_GPS_SIGNAL' | translate }}</h1>

        <div
            class="dialog-content-wrapper"
            mat-dialog-content
            [innerHTML]="'ONLY_GSM_AVAILABLE' | translate: { devices: data?.devicesNames?.toString() }"
        ></div>

        <div class="dialog-content-actions" mat-dialog-actions>
            <div class="nav-container">
                <button class="btn basic" (click)="moreInfo()" mat-flat-button>
                    {{ 'moreInfo' | translate }}
                </button>

                <button class="btn primary" (click)="close()" mat-flat-button>
                    {{ 'GOT_IT' | translate }}
                </button>
            </div>
        </div>
    </div>
</div>
