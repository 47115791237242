export enum DeviceMarker {
    Mini = 'assets/images/devices/6.svg',
    Guardian = 'assets/images/devices/5.svg',
    Travel = 'assets/images/devices/7.svg',
    TrackiPro = 'assets/images/devices/3.svg',
    Watch = 'assets/images/devices/2.svg',
    Universal = 'assets/images/devices/4.svg',
    Male = 'assets/images/devices/10.svg',
    Female = 'assets/images/devices/11.svg',
    Pet = 'assets/images/devices/14.svg',
}
