import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PhoneInputComponent } from 'src/app/shared/phone-input/phone-input.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
    EMAIL_VALIDATOR_REG_EXP,
    PASSWORD_VALIDATOR_REG_EXP,
    TEXT_INPUT,
} from '../../store/constants/common.constants';
import { ApiService } from '../../store/services';
import { takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { IAppState } from '../../store/state/app.state';
import { TranslateService } from '@ngx-translate/core';
import { selectUser } from '../../store/selectors/user.selector';
import { IUser } from '../../store/interfaces';
import { SnackbarService } from '../../store/services/snackbar.service';

@Component({
    selector: 'app-add-sub-account-dialog',
    templateUrl: './add-sub-account-dialog.component.html',
    styleUrls: ['./add-sub-account-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddSubAccountDialogComponent implements OnInit, OnDestroy {
    @ViewChild(PhoneInputComponent) phoneInputComponent: PhoneInputComponent;
    user$: Observable<IUser>;
    accountID: number;

    inviteForm: FormGroup = this.formBuilder.group({
        inviteEmail: ['', [Validators.required, Validators.pattern(EMAIL_VALIDATOR_REG_EXP)]],
    });

    createAccountForm: FormGroup = this.formBuilder.group({
        userName: ['', [Validators.required, Validators.pattern(TEXT_INPUT)]],
        userEmail: ['', [Validators.required, Validators.pattern(EMAIL_VALIDATOR_REG_EXP)]],
        userPassword: ['', [Validators.required, Validators.pattern(PASSWORD_VALIDATOR_REG_EXP)]],
        passwordConfirmed: ['', [Validators.required]],
        userAddress: ['', [Validators.required]],
    });

    showPassword: boolean;
    showConfirmedPassword: boolean;

    private destroyed$ = new Subject<void>();

    constructor(
        public dialogRef: MatDialogRef<AddSubAccountDialogComponent>,
        private formBuilder: FormBuilder,
        private apiService: ApiService,
        private store: Store<IAppState>,
        private translate: TranslateService,
        private snackBar: SnackbarService,
        @Inject(MAT_DIALOG_DATA) public data,
    ) {}

    ngOnInit() {
        this.store
            .select(selectUser)
            .pipe(takeUntil(this.destroyed$))
            .subscribe((user) => (this.accountID = user.account_id));
    }

    ngOnDestroy(): void {
        this.destroyed$.next();
        this.destroyed$.complete();
    }

    changeVisiblePassword() {
        this.showPassword = !this.showPassword;
    }

    changeVisibleConfirmPassword() {
        this.showConfirmedPassword = !this.showConfirmedPassword;
    }

    closeDialog(): void {
        this.dialogRef.close();
    }

    submitInviteFrom(isValid: boolean) {
        if (isValid) {
            const payload = [this.inviteForm.controls['inviteEmail'].value];
            this.apiService
                .addSubAccountInvitation(this.accountID, payload)
                .pipe(takeUntil(this.destroyed$))
                .subscribe(
                    (res) => {
                        this.snackBar.success('INVITATION_SENT_BY_EMAIL');
                        this.dialogRef.close();
                    },
                    ({ error }) => {
                        this.snackBar.error(error.message_key);
                    },
                );
        }
    }

    submitCreateAccountFrom(isValid: boolean) {
        this.createAccountForm.markAllAsTouched();
        this.phoneInputComponent.validatePhoneForm();
        if (
            isValid &&
            this.phoneInputComponent.countryCtrl.valid &&
            this.phoneInputComponent.phoneNumb.valid
        ) {
            const formData = this.createAccountForm.value;
            const phoneCountryData = this.phoneInputComponent.countryCtrl.value;
            const phoneNumber = this.phoneInputComponent.phoneNumb.value;

            let userData = {
                address: formData.userAddress,
                email: formData.userEmail,
                name: formData.userName,
                parent_id: this.accountID,
                password: formData.userPassword,
                phone: `+${phoneCountryData[2]}${phoneNumber}`,
                type: 'REGULAR',
            };
            this.apiService
                .addSubAccountNewUser(userData)
                .pipe(takeUntil(this.destroyed$))
                .subscribe(
                    (res) => {
                        this.snackBar.success('USER_WAS_CREATED_SUCCESSFULLY');
                        this.dialogRef.close(true);
                    },
                    ({ error }) => {
                        this.snackBar.error(error.message_key);
                    },
                );
        }
    }
}
