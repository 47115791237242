import { createReducer, on } from '@ngrx/store';
import { DevicesActions } from '../actions/devices.actions';
import { initialSharedState } from '../state/shared.state';

export const sharedReducer = createReducer(
    initialSharedState,
    on(DevicesActions.showDeviceHistoryInfo, (state, { historyInfo }) => ({
        ...state,
        historyItem: historyInfo,
    })),
    on(DevicesActions.hideHistoryPoint, (state) => ({
        ...state,
        historyItem: undefined,
    })),
    on(DevicesActions.getBrainTreeDropInTokenSuccess, (state, { token }) => ({
        ...state,
        brainTreeToken: token,
    })),
    on(DevicesActions.removeBrainTreeDropInToken, (state) => ({
        ...state,
        brainTreeToken: undefined,
    })),
    on(DevicesActions.getDeviceDetailsByTokenSuccess, (state, { device }) => ({
        ...state,
        deviecByToken: device[0],
    })),
);
