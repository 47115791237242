import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { skin } from 'src/white-labels';
import { IBreadcrumb } from '../../store/interfaces/breadcrumb.interface';
import { TranslateService } from '@ngx-translate/core';
import { TNT_PRIVACY_POLICY } from '../../store/constants/common.constants';

@Component({
    selector: 'app-terms-and-conditions',
    templateUrl: './terms-and-conditions.component.html',
    styleUrls: ['./terms-and-conditions.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TermsAndConditionsComponent implements OnInit {
    @Input() isShowFooter: boolean;
    @Input() isModalView: boolean;
    @Input() isShowTNTPrivacyPolicy: boolean;
    privacyPoliciesTNT = TNT_PRIVACY_POLICY;
    skin = skin;
    breadcrumbsList: Array<IBreadcrumb> = [
        {
            label: this.translate.instant('TERMS_AND_CONDITIONS'),
            path: '/',
        },
    ];
    constructor(private route: ActivatedRoute, private translate: TranslateService) {}

    ngOnInit(): void {
        this.route.data.subscribe(({ isShowFooter }) => (this.isShowFooter = isShowFooter));
    }
}
