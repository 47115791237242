import { Injectable } from '@angular/core';
import { LocalStorageService } from './local-storage.service';
import { IFeatureToggleItem } from '../interfaces';

const featureToggleConfig = [
    {
        featureName: 'SOCIAL_LOGIN',
        featureTitle: 'Social login',
        featureValue: false,
    },
    {
        featureName: 'COMPLEX_GZONES',
        featureTitle: 'Complex g-zones',
        featureValue: false,
    },
    {
        featureName: 'HISTORY_MOVIE',
        featureTitle: 'History movie',
        featureValue: false,
    },
];

@Injectable({ providedIn: 'root' })
export class FeatureToggleService {
    constructor(private localStorageService: LocalStorageService) {}

    initFeaturesData() {
        if (featureToggleConfig.length) {
            featureToggleConfig.map((featureItem: IFeatureToggleItem) => {
                if (!this.localStorageService.getItem(featureItem.featureName)) {
                    this.localStorageService.setItem(
                        featureItem.featureName,
                        String(featureItem.featureValue),
                    );
                }
            });
        }
    }

    getFeaturesData() {
        let featuresData = [];
        featureToggleConfig.map((featureItem: IFeatureToggleItem) => {
            featuresData.push({
                ...featureItem,
                featureValue: this.localStorageService.getItem(featureItem.featureName),
            });
        });
        return featuresData;
    }

    updateFeaturesData(data: IFeatureToggleItem[]) {
        data.map((item: IFeatureToggleItem) => {
            this.localStorageService.setItem(item.featureName, String(item.featureValue));
        });
    }
}
