import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { IEvent, IEventsResponse } from '../interfaces/events.interface';

const prefix = '[Events]';

const getEvents = createAction(`${prefix} Get Events`);
const getEventsSuccess = createAction(`${prefix} Get Events Success`, props<{ response: IEvent[] }>());
const getEventsError = createAction(`${prefix} Get Events Error`, props<{ error: HttpErrorResponse }>());
const getSOSDevicesEvents = createAction(`${prefix} Get SOS Devices Events`);
const getSOSDevicesEventsSuccess = createAction(
    `${prefix} Get SOS Devices Events Success`,
    props<{ response: IEventsResponse }>(),
);
const getSOSDevicesEventsError = createAction(
    `${prefix} Get SOS Devices Events Error`,
    props<{ error: HttpErrorResponse }>(),
);
const getSOSDescendantsEvents = createAction(`${prefix} Get SOS Descendants Events`);
const getSOSDescendantsEventsSuccess = createAction(
    `${prefix} Get SOS Descendants Events Success`,
    props<{ response: IEventsResponse }>(),
);
const getSOSDescendantsEventsError = createAction(
    `${prefix} Get SOS Descendants Events Error`,
    props<{ error: HttpErrorResponse }>(),
);
const deleteAllEvents = createAction(`${prefix} Delete All Events`);
const deleteAllEventsSuccess = createAction(`${prefix} Delete All Events Success`, props<{ msg: string }>());
const deleteAllEventsError = createAction(
    `${prefix} Delete All Events Error`,
    props<{ error: HttpErrorResponse }>(),
);
const updateEvent = createAction(
    `${prefix} Update Event`,
    props<{ eventID: number[] | number; msg: string }>(),
);
const updateEventSuccess = createAction(
    `${prefix} Update Event Success`,
    props<{ eventsCount: number; msg: string }>(),
);
const updateEventError = createAction(`${prefix} Update Event Error`, props<{ error: HttpErrorResponse }>());
const triggerEvents = createAction(`${prefix} Trigger Events`);
const triggerSubAccountsEvents = createAction(`${prefix} Trigger Sub Accounts Events`);
const clearEvents = createAction(`${prefix} Clear Events`);

export const EventsActions = {
    getEvents,
    getEventsSuccess,
    getEventsError,
    getSOSDevicesEvents,
    getSOSDevicesEventsSuccess,
    getSOSDevicesEventsError,
    getSOSDescendantsEvents,
    getSOSDescendantsEventsSuccess,
    getSOSDescendantsEventsError,
    deleteAllEvents,
    deleteAllEventsSuccess,
    deleteAllEventsError,
    updateEvent,
    updateEventSuccess,
    updateEventError,
    triggerEvents,
    triggerSubAccountsEvents,
    clearEvents,
};
