import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { IContact, IContactDeletePayload, IContactPayload } from '../interfaces';

const prefix = '[Contacts]';

const getContacts = createAction(`${prefix} Get Contacts`);
const getContactsSuccess = createAction(`${prefix} Get Contacts Success`, props<{ response: IContact[] }>());
const getContactsError = createAction(`${prefix} Get Contacts Error`, props<{ error: HttpErrorResponse }>());
const addContact = createAction(`${prefix} Add Contact`, props<{ payload: IContactPayload }>());
const addContactSuccess = createAction(`${prefix} Add Contact Success`, props<{ msg: string }>());
const addContactError = createAction(`${prefix} Add Contact Error`, props<{ error: HttpErrorResponse }>());
const editContact = createAction(
    `${prefix} Edit Contact`,
    props<{ contactId: number; payload: IContactPayload }>(),
);
const editContactSuccess = createAction(`${prefix} Edit Contact Success`, props<{ msg: string }>());
const editContactError = createAction(`${prefix} Edit Contact Error`, props<{ error: HttpErrorResponse }>());
const deleteContact = createAction(`${prefix} Delete Contact`, props<{ payload: IContactDeletePayload }>());
const deleteContactSuccess = createAction(`${prefix} Delete Contact Success`, props<{ msg: string }>());
const deleteContactError = createAction(
    `${prefix} Delete Contact Error`,
    props<{ error: HttpErrorResponse }>(),
);

const clearContacts = createAction(`${prefix} Clear Contacts`);

export const ContactsActions = {
    getContacts,
    getContactsSuccess,
    getContactsError,
    addContact,
    addContactSuccess,
    addContactError,
    editContact,
    editContactSuccess,
    editContactError,
    deleteContact,
    deleteContactSuccess,
    deleteContactError,
    clearContacts,
};
