import { Pipe, PipeTransform } from '@angular/core';
import { IDeviceShort, IZone } from 'src/app/store/interfaces';
import { FILTER_TYPES } from '../../store/constants/common.constants';

@Pipe({
    name: 'searchFilter',
})
export class SearchFilterPipe implements PipeTransform {
    transform(value: any, searchText?: any, filterType?: string): any {
        if (!searchText) {
            return value;
        }

        if (FILTER_TYPES.devices === filterType) {
            return value.filter(
                (device: IDeviceShort) =>
                    device.device_name.toString().includes(searchText) ||
                    device.device_id.toString().includes(searchText),
            );
        }
        if (FILTER_TYPES.zones === filterType) {
            return value.filter((zone: IZone) => zone.name.toString().includes(searchText));
        }
    }
}
