<div class="account-locked">
    <div class="account-locked-warning-title">
        {{ 'SORRY_THIS_ACCOUNT_IS_LOCKED' | translate }}
    </div>
    <div class="account-locked-desc-text">
        {{ 'WAIT_20_MINUTES' | translate }}
    </div>
    <div class="account-locked-timer-text" *ngIf="timeLeft > 0">
        {{ 'TIME_TO_UNLOCK' | translate }} {{ timeLeft | formatCountdownTime }}
    </div>
    <button class="btn primary" routerLink="/" mat-flat-button>
        {{ 'OK' | translate }}
    </button>
</div>
