
  export let env = {
    production: false,
    whiteLabel: 'TAGANDTRACK' || 'TRACKIMO',
    baseApiUrl: 'api' || 'api',
    baseRedirectUrl: 'https://stg.tagandtrack.iot.vodafone.com',
    clientId: '943f9b0f-73c8-4435-8801-0260db687f05' || 'aff9ffc1-3c99-41cf-bf43-c9aef0c31ffa',
    clientSecret: '96ca64b0ae5f7005fd18387a28019615' || '2ca5172f86f7b314c3f6b2cfc6f0a0f2',
    googleMapApiKey: 'AIzaSyCbL8rgOtmuwHr7038PmPSeU7kpKKBL8vo'
  };
