<div
    class="status-label-wrapper {{ statusLabel?.labelType }}"
    [ngClass]="{ hover: isHoverType, somitomoMibile: isSomitomoMobile }"
>
    <div class="icon-wrap">
        <i class="icon" [inlineSVG]="'#warning'"></i>
    </div>

    <div class="text-wrap" rtl>
        <h3 *ngIf="statusLabel?.status">{{ statusLabel?.status }}</h3>
        <p *ngIf="statusLabel?.description || address">{{ statusLabel?.description || address }}</p>
        <div class="somitomo-btns-wrap" *ngIf="isSomitomo">
            <div class="button" (click)="closeStatusLabel()">
                {{ statusLabel?.okCloseBtn }}
            </div>
            <div class="button button-orange" (click)="checkAgainSomitomoStatus()">
                {{ statusLabel?.tryAgainBtn }}
            </div>
        </div>
    </div>

    <div class="close-button-wrap" *ngIf="!isHoverType && !statusLabel?.isHideClose">
        <i class="warning-icon" [inlineSVG]="'#close'" (click)="closeStatusLabel()"></i>
    </div>
</div>
