import { createReducer, on } from '@ngrx/store';
import { ClientsActions } from '../actions/clients.actions';
import { initialClientsState } from '../state/clients.state';

export const clientsReducer = createReducer(
    initialClientsState,
    on(ClientsActions.getClientsError, () => initialClientsState),
    on(ClientsActions.getClientsSuccess, (state, { response }) => ({
        ...state,
        clients: response,
    })),
);
