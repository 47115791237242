import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { skin } from '../../../../white-labels';

@Injectable()
export class TPlusSupportPagesService implements CanActivate {
  skin = skin;
  constructor(private router: Router) {}

  canActivate() {
    if (skin.whiteLabel !== 'TAGANDTRACK') {
      return true;
    }
    return this.router.navigate(['/']);
  }
}
