import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { UserActions } from 'src/app/store/actions/user.actionsts';
import { OAUTH_CLIET_ID } from 'src/app/store/constants';
import { IAppState } from 'src/app/store/state/app.state';
import { skin } from 'src/white-labels';

@Component({
    selector: 'app-account-activation',
    templateUrl: './account-activation.component.html',
    styleUrls: ['./account-activation.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountActivationComponent implements OnInit {
    userEmail: string;
    skin = skin;

    constructor(private route: ActivatedRoute, private router: Router, private store: Store<IAppState>) {}

    ngOnInit(): void {
        this.checkUrl();
    }

    checkUrl() {
        this.route.queryParams.subscribe((params) => {
            params.email ? (this.userEmail = params.email) : this.router.navigate(['/']);
        });
    }

    resendEmailLink() {
        const resendData = {
            client_id: OAUTH_CLIET_ID,
            email: this.userEmail,
        };

        this.store.dispatch(UserActions.resendLink({ resendData }));
    }
}
