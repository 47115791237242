import { Location } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-back-btn',
    templateUrl: './back-btn.component.html',
    styleUrls: ['./back-btn.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BackBtnComponent {
    @Input() title: string;
    @Input() url?: string;

    constructor(private location: Location, private router: Router) {}

    backClicked() {
        if (this.url) {
            this.router.navigate([this.url]);
        } else {
            this.location.back();
        }
    }
}
