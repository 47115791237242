import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-status-dialog',
    templateUrl: './status-dialog.component.html',
    styleUrls: ['./status-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatusDialogComponent {
    constructor(
        public dialogRef: MatDialogRef<StatusDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data,
    ) {}

    close(): void {
        this.dialogRef.close();
    }
}
