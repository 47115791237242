import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CookiesService } from '../../store/services/cookie/cookie.service';
import { skin } from 'src/white-labels';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent {
    accepted: boolean;
    date: number;
    skin = skin;

    constructor(private cookieService: CookiesService) {
        this.accepted = this.cookieService.getCookie('accepted_privacy') === 'true';
        this.date = new Date().getFullYear();
    }

    acceptCookie() {
        this.accepted = true;
        this.cookieService.setCookie('accepted_privacy', 'true');
    }
}
