import { Directive, ElementRef, OnInit, OnDestroy } from '@angular/core';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Directive({
    selector: '[rtl]',
})
export class RtlSupportDirective implements OnInit, OnDestroy {
    private subscription: Subscription;
    constructor(private el: ElementRef, public translate: TranslateService) {
        setTimeout(() =>
            el.nativeElement.setAttribute('dir', translate.currentLang === 'he' ? 'rtl' : 'ltr'),
        );
    }
    ngOnInit() {
        this.subscription = this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.el.nativeElement.setAttribute('dir', event.lang === 'he' ? 'rtl' : 'ltr');
        });
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }
}
