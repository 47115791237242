<mat-select
    class="language-select"
    [(ngModel)]="currentLanguage"
    name="language"
    (selectionChange)="onLanguageChange($event.value)"
>
    <mat-option *ngFor="let language of languagesList" [value]="language.value" rtl>
        {{ language.viewValue }}
    </mat-option>
</mat-select>
