<ng-container *ngIf="skin.whiteLabel !== 'TAGANDTRACK'; else whiteLabelTNT">
    <section rtl class="terms" [innerHTML]="'TERMS_AND_CONDITIONS_BODY' | translate"></section>
</ng-container>

<ng-template #whiteLabelTNT>
    <ng-container *ngIf="!isModalView; else pageViewTemplate">
        <div class="tnt-container">
            <h1 class="page-header">{{ 'TERMS_AND_CONDITIONS' | translate }}</h1>
            <app-breadcrumbs [breadcrumbsList]="breadcrumbsList"> </app-breadcrumbs>
            <mat-accordion>
                <mat-expansion-panel class="expansion-item" hideToggle disabled>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            {{ 'TNT_TERMS_AND_CON_INFO' | translate }}
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
    </ng-container>
    <ng-template #pageViewTemplate>
        <div class="tnt-container tnt-container-modal-view">
            <ng-container *ngIf="isShowTNTPrivacyPolicy; else termAndCondTemplate">
                <div class="content-wrapper">
                    <div *ngFor="let item of privacyPoliciesTNT">
                        <h3>{{ item.title | translate }}</h3>
                        <div *ngFor="let itemInfo of item.info">
                            <p [innerHTML]="itemInfo | translate"></p>
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-template #termAndCondTemplate>
                <h3>{{ 'TNT_TERMS_AND_CON_INFO' | translate }}</h3>
            </ng-template>
        </div>
    </ng-template>
</ng-template>

<app-footer *ngIf="isShowFooter" class="footer"></app-footer>
