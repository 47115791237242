import { NgModule } from '@angular/core';

import { LoginComponent } from './login/login.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';

import { PublicComponent } from './public.component';
import { SharedModule } from '../shared/shared.module';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { CreateAccountComponent } from './create-account/create-account.component';
import { FaqComponent } from './faq/faq.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { AccountActivationComponent } from './account-activation/account-activation.component';
import { PublicMapComponent } from './public-map/public-map.component';
import { AccountVerificationComponent } from './account-verification/account-verification.component';
import { SignupConfirmComponent } from './signup-confirm/signup-confirm.component';
import { LoginAuthCodeComponent } from './login-auth-code/login-auth-code.component';
import { ForgotPasswordVerificationComponent } from './forgot-password-verification/forgot-password-verification.component';
import { ForgotPasswordSuccessComponent } from './forgot-password-success/forgot-password-success.component';
import { CommonSupportComponent } from './common-support/common-support.component';
import { UserGuideComponent } from './user-guide/user-guide.component';
import { AccountLockedComponent } from './account-locked/account-locked.component';
import { CreateAccountStepperComponent } from '../shared/create-account-stepper/create-account-stepper.component';
import { DownloadAppComponent } from './download-app/download-app.component';

@NgModule({
    declarations: [
        PublicComponent,
        LoginComponent,
        CreateAccountComponent,
        ForgotPasswordComponent,
        ResetPasswordComponent,
        FaqComponent,
        ContactUsComponent,
        AboutUsComponent,
        AccountActivationComponent,
        PublicMapComponent,
        AccountVerificationComponent,
        SignupConfirmComponent,
        LoginAuthCodeComponent,
        ForgotPasswordVerificationComponent,
        ForgotPasswordSuccessComponent,
        CommonSupportComponent,
        UserGuideComponent,
        AccountLockedComponent,
        CreateAccountStepperComponent,
        DownloadAppComponent,
    ],
    imports: [SharedModule],
})
export class PublicModule {}
