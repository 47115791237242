<h1 class="title">{{'FORGOTTEN_PASSWORD' | translate}}</h1>

<p class="description">
    {{ 'RESET_PASS_DESC_TEXT' | translate }}
</p>

<app-status-label *ngIf="verifyAccountError$ | async" [statusLabel]="verifyAccountError$ | async">
</app-status-label>

<form [formGroup]="form" (ngSubmit)="onSubmit(form.valid)">
    <mat-form-field class="form-field" appearance="outline">
        <mat-label>{{ 'VERIFICATION_CODE' | translate }}</mat-label>
        <input formControlName="code" matInput placeholder="{{ 'PLEASE_ENTER_VERIFICATION_CODE' | translate }}">
    </mat-form-field>

    <button class="btn primary form-btn" type="submit" mat-flat-button>{{'SUBMIT' | translate}}</button>
</form>
