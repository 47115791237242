import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import { IAppState } from '../state/app.state';
import { clearStateMetaReducer } from './clear-state.reducer';
import { devicesReducer } from './devices.reducers';
import { eventsReducer } from './events.reducers';
import { hydrationMetaReducer } from './hydration.reducer';
import { sharedReducer } from './shared.reducers';
import { statusReducer } from './status.reducers';
import { userReducer } from './user.reducers';
import { zonesReducer } from './zones.reducers';
import { contactsReducer } from './contacts.reducer';
import { descendantsReducer } from './descendants.reducers';
import { clientsReducer } from './clients.reducers';
import { unreadEventsReducer } from './unread-events.reducer';

export const appReducers: ActionReducerMap<IAppState> = {
    user: userReducer,
    devices: devicesReducer,
    zones: zonesReducer,
    status: statusReducer,
    events: eventsReducer,
    shared: sharedReducer,
    contacts: contactsReducer,
    descendant: descendantsReducer,
    clients: clientsReducer,
    unreadEvents: unreadEventsReducer,
};

export const metaReducers: MetaReducer[] = [
    // hydrationMetaReducer,
    clearStateMetaReducer,
];
