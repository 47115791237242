import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IAppState } from '../state/app.state';
import { DATE_FORMATS } from '../constants/common.constants';

@Injectable({ providedIn: 'root' })
export class DateFormatService {
    public formattedDateString: string;

    public set dateFormat(innerFormat: string) {
        if (innerFormat) {
            switch (innerFormat) {
                case 'DEFAULT':
                    this.formattedDateString = DATE_FORMATS.DEFAULT;
                    break;
                case 'EU':
                    this.formattedDateString = DATE_FORMATS.EU;
                    break;
                case 'US':
                    this.formattedDateString = DATE_FORMATS.US;
                    break;
                case 'JP':
                    this.formattedDateString = DATE_FORMATS.JP;
                    break;
                default:
                    this.formattedDateString = DATE_FORMATS.DEFAULT;
                    break;
            }
        } else {
            this.formattedDateString = DATE_FORMATS.DEFAULT;
        }
    }

    public get dateFormat(): string {
        return this.formattedDateString;
    }
}
