<footer class="footer" rtl>
    <div class="content">
        <div class="footer-title">
            <h1>{{ 'TRACKIMO_IS_AVAILABLE_FOR_IOS_AND_ANDROID' | translate: { brand: skin.brandName } }}</h1>
            <app-language-select class="language-switcher"></app-language-select>
        </div>

        <div class="app-liks">
            <a href="{{ skin.iosAppLink }}" target="_blank" class="link"
                ><img src="assets/images/app-store.svg" alt="app store"
            /></a>
            <a href="{{ skin.androidAppLink }}" target="_blank" class="link"
                ><img src="assets/images/google-play.svg" alt="google play"
            /></a>
        </div>

        <div>
            <ul class="footer-nav">
                <ng-container *ngIf="skin.whiteLabel !== 'TAGANDTRACK'; else whiteLabelNav">
                    <li>
                        <a routerLink="/faq" class="link">{{ 'FAQS' | translate }}</a>
                    </li>
                    <li *ngIf="skin.whiteLabel === 'MAMORI'">
                        <a routerLink="/contact-us" class="link">{{ 'CONTACT_US' | translate }}</a>
                    </li>
                    <li *ngIf="skin.whiteLabel === 'TRACKIMO'">
                        <a href="https://trackimoplus.com/support-request" target="_blank" class="link">{{
                            'CONTACT_US' | translate
                        }}</a>
                    </li>
                    <li>
                        <a
                            target="_blank"
                            href="{{ 'https://trackimoplus.com/privacy-policy' }}"
                            class="link"
                            >{{ 'PRIVACY_POLICY' | translate }}</a
                        >
                    </li>
                    <li>
                        <a target="_blank" href="https://trackimoplus.com/terms-of-service" class="link">
                            {{ 'TERMS_AND_CONDITIONS' | translate }}
                        </a>
                    </li>
                </ng-container>
                <ng-template #whiteLabelNav>
                    <li>
                        <a [routerLink]="['/about/faqs']" class="link">{{ 'FAQS' | translate }}</a>
                    </li>
                    <li>
                        <a [routerLink]="['/about/contact-support']" class="link">{{ 'CONTACT_US' | translate }}</a>
                    </li>
                    <li>
                        <a target="_blank" href="https://www.vodafone.com/business/privacy-policy" class="link">{{
                            'PRIVACY_POLICY' | translate
                        }}</a>
                    </li>
                    <li>
                        <a [routerLink]="['/about/terms-and-conditions']" class="link">{{
                            'TERMS_AND_CONDITIONS' | translate
                        }}</a>
                    </li>
                </ng-template>
            </ul>

            <ng-container *ngIf="skin.whiteLabel !== 'TAGANDTRACK'; else whiteLabelCopyright">
                <p class="copyright">{{ 'COPY_RIIGHT' | translate }}</p>
            </ng-container>
            <ng-template #whiteLabelCopyright>
                <p class="copyright">{{ 'TNT_COPYRIGHT' | translate: { date: date } }}</p>
            </ng-template>
        </div>
    </div>
    <app-cookie-bar *ngIf="!accepted" (acceptCookieEvent)="acceptCookie()"></app-cookie-bar>
</footer>
