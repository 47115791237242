<p class="description">{{ 'WE_SENT_YOU_CODE_EMAIL' | translate }}<br />
    {{'SENT_TO_EMAIL' | translate}}
    <strong>{{ userEmail }}</strong>
</p>

<app-status-label *ngIf="verifyAccountError$ | async" [statusLabel]="verifyAccountError$ | async"></app-status-label>

<form [formGroup]="authLoginForm" (ngSubmit)="onSubmit(authLoginForm.valid)">
    <mat-form-field class="form-field" appearance="outline">
        <mat-label>{{ 'VERIFICATION_CODE' | translate }}</mat-label>
        <input formControlName="code" matInput placeholder="{{ 'PLEASE_ENTER_VERIFICATION_CODE' | translate }}">
    </mat-form-field>

    <button class="btn primary form-btn" type="submit" mat-flat-button>{{'VERIFY' | translate}}</button>
</form>

<p class="resend">
    {{'CODE_NOT_RECIEVED' | translate}}
    <span class="resend-btn" (click)="resendEmailLink()">{{ 'RESEND_EMAIL' | translate }}</span>
</p>
