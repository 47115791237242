import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { AddDeviceService } from 'src/app/dashboard/add-device-form/add-device.service';
import { IDeviceListItem, IStatusLabel } from 'src/app/store/interfaces';
import { skin } from 'src/white-labels';
import {
    DEVICES_TYPES,
    ETOLL_ICON_TYPE,
    MAX_FILE_SIZE_LIMIT,
    StatusLabelType,
} from '../../store/constants/common.constants';
import { SnackbarService } from '../../store/services/snackbar.service';

@Component({
    selector: 'app-edit-photo',
    templateUrl: './edit-photo.component.html',
    styleUrls: ['./edit-photo.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditPhotoComponent implements OnInit {
    @Input() iconID: number;
    @Input() isHiddenDevicesBar: boolean;
    @Input() deviceTypeIcon: number;
    @Input() isEtollDevice = false;
    @Output() fileSelectEvent = new EventEmitter();
    @Output() fileResetEvent = new EventEmitter();
    eTollIconType = ETOLL_ICON_TYPE;
    skin = skin;

    devicesTypes: IDeviceListItem[] = DEVICES_TYPES;
    imgURL: SafeResourceUrl;
    isMaxSizeError: boolean;

    statusLabel: IStatusLabel = {
        status: this.translate.instant('EXCEPTION_FILE_SIZE_EXCEEDED'),
        description: this.translate.instant('EXCEPTION_FILE_SIZE_EXCEEDED_DESC'),
        labelType: StatusLabelType.ERROR,
        isHideClose: true,
    };

    constructor(
        private cdRef: ChangeDetectorRef,
        private translate: TranslateService,
        private snackBar: SnackbarService,
        private addDeviceService: AddDeviceService,
    ) {}

    ngOnInit(): void {
        if (skin.whiteLabel === 'TAGANDTRACK') {
            this.devicesTypes = DEVICES_TYPES.filter((divice) => +divice.icon_id === 3);
        }

        if (skin.whiteLabel === 'MYTRACKI') {
            this.devicesTypes = [];
        }

        if (this.deviceTypeIcon === this.eTollIconType || this.isEtollDevice) {
            this.devicesTypes = DEVICES_TYPES.filter((divice) => +divice.icon_id === this.eTollIconType);
        }

        this.devicesTypes.forEach((device) => (device.isSelected = +device.icon_id === this.iconID));
    }

    selectDeviceImg(device): void {
        this.devicesTypes.forEach((device) => (device.isSelected = false));
        device.isSelected = true;
        this.fileSelectEvent.emit(+device.icon_id);
    }

    onDrop(event): void {
        event.preventDefault();
        const file = event.dataTransfer.files[0];
        let Extension = file.name.substring(file.name.lastIndexOf('.') + 1).toLowerCase();
        if (
            Extension == 'gif' ||
            Extension == 'tiff' ||
            Extension == 'png' ||
            Extension == 'bmp' ||
            Extension == 'jpeg' ||
            Extension == 'jpg'
        ) {
            this.renderImage(file);
            this.fileSelectEvent.emit(file);
            const element = event.target as HTMLInputElement;
            element.value = '';
        } else {
            this.snackBar.error(
                this.translate.instant('ALLOWED_IMG_EXT') + ' ' + 'gif, png, bmp, tiff, jpeg, jpg',
            );
            const element = event.target as HTMLInputElement;
            element.value = '';
        }
    }

    onDragOver(event): void {
        event.stopPropagation();
        event.preventDefault();
    }

    onFileSelect(event): void {
        const file = event.target.files[0];
        let Extension = file.name.substring(file.name.lastIndexOf('.') + 1).toLowerCase();
        if (
            Extension == 'gif' ||
            Extension == 'tiff' ||
            Extension == 'png' ||
            Extension == 'bmp' ||
            Extension == 'jpeg' ||
            Extension == 'jpg'
        ) {
            this.renderImage(file);
            this.fileSelectEvent.emit(file);
            const element = event.target as HTMLInputElement;
            element.value = '';
        } else {
            this.snackBar.error(
                this.translate.instant('ALLOWED_IMG_EXT') + ' ' + 'gif, png, bmp, tiff, jpeg, jpg',
            );
            const element = event.target as HTMLInputElement;
            element.value = '';
        }
    }

    onRemovePhoto() {
        this.imgURL = '';
        this.fileResetEvent.emit();
    }

    private renderImage(file): void {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            if (file.size > MAX_FILE_SIZE_LIMIT) {
                this.isMaxSizeError = true;
                this.cdRef.markForCheck();
                return;
            } else {
                this.isMaxSizeError = false;
            }

            this.imgURL = reader.result;
            this.cdRef.markForCheck();
        };
    }
}
