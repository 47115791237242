<div class="dialog-wrapper" rtl>
    <div mat-dialog-title>
        <h1 class="title">{{ 'DELETE_MY_ACCOUNT' | translate }}</h1>
    </div>

    <div class="content" mat-dialog-content>
        <p>{{ 'GOING_TO_HAPPEN' | translate }}</p>

        <ul>
            <li><span class="dot"></span>{{ 'DEACTIVATE_ACCOUNT' | translate }}</li>
            <li><span class="dot"></span>{{ 'ALL_INFORMATION' | translate }}</li>
        </ul>

        <span [innerHTML]="'MORE_INFO' | translate"></span>
    </div>
</div>

<div mat-dialog-actions>
    <div class="nav-container">
        <button class="btn basic" (click)="close()" mat-flat-button>{{ 'NO_THANKS' | translate }}</button>
        <button class="btn primary" (click)="confirm()" mat-flat-button>{{ 'delete' | translate }}</button>
    </div>
</div>