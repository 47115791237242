import { HttpClient } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { GoogleMap } from '@angular/google-maps';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, of, Subject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { skin } from 'src/white-labels';
import { ApiService, WindowService } from '..';
import { DevicesActions } from '../../actions/devices.actions';
import { EventsActions } from '../../actions/events.actions';
import { MapActions } from '../../actions/map.actions';
import { GOOGLE_MAP_API_KEY } from '../../constants';
import { ZoneType } from '../../constants/common.constants';
import { IDeviceHistory, ILatLng, IZone, IZoneSize } from '../../interfaces';
import { IDeviceByToken, IDeviceFull, IDeviceShort } from '../../interfaces/devices.interface';
import { IAppState } from '../../state/app.state';
import { DeviceMarker } from './device-marker.enum';
import createHTMLMapMarker from './html-map-marker.class';
declare const MarkerClusterer: any;

@Injectable({ providedIn: 'root' })
export class GoogleMapService {
    private googleMap: GoogleMap;
    private markerClusterer: any;
    private selecteedDeviceCircle: google.maps.Circle;
    private markers: google.maps.OverlayView[] = [];
    private userPin: google.maps.OverlayView;
    private rectangles: google.maps.Rectangle[] = [];
    private infoWindows: google.maps.InfoWindow[] = [];
    private polyline: google.maps.Polyline;
    private directionsRenderer: google.maps.DirectionsRenderer[] = [];

    private PRIMARY_COLOR = skin.primaryColor;
    private SOS_MARKER_COLOR = '#e53935';
    private INACTIVE_MARKER_COLOR = '#999999';

    private MAP_MAX_ZOOM = 21 as const;

    private zoneSize = new Subject<IZoneSize>();

    isShowDeviceName = true;

    public zoneSizeChange = this.zoneSize.asObservable();
    public panorama: google.maps.StreetViewPanorama;

    initRouteCalled: boolean;

    constructor(
        private zone: NgZone,
        private router: Router,
        private httpClient: HttpClient,
        private store: Store<IAppState>,
        private window: WindowService,
        private apiService: ApiService,
    ) {}

    mapApiLoaded = (): Observable<boolean> => {
        return this.httpClient
            .jsonp(
                `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAP_API_KEY}&libraries=geometry,places`,
                'callback',
            )
            .pipe(
                map(() => true),
                catchError(() => of(false)),
            );
    };

    set map(map: GoogleMap) {
        this.googleMap = map;
        this.googleMap.googleMap?.setOptions({
            minZoom: 3,
            maxZoom: this.MAP_MAX_ZOOM,
            restriction: {
                latLngBounds: { north: 85, south: -85, west: -180, east: 180 },
            },
            fullscreenControl: false,
            streetViewControl: false,
            zoomControl: false,
            mapTypeControl: false,
        });
    }

    get map() {
        return this.googleMap;
    }

    initStreetView(inPano: HTMLElement) {
        this.panorama = new google.maps.StreetViewPanorama(inPano, {
            pov: {
                heading: 300,
                pitch: 0,
            },
            zoom: 0,
        });
    }

    setStreetViewPanorama(lat: number, lng: number) {
        this.panorama.setPosition({ lat, lng });
        this.googleMap.googleMap?.setStreetView(this.panorama);
    }

    zoomIn(): void {
        var currentZoomLevel = this.googleMap.getZoom();
        if (currentZoomLevel != this.MAP_MAX_ZOOM) {
            this.googleMap.googleMap.setZoom(currentZoomLevel + 1);
        }
    }

    zoomOut(): void {
        var currentZoomLevel = this.googleMap.getZoom();
        if (currentZoomLevel != 0) {
            this.googleMap.googleMap.setZoom(currentZoomLevel - 1);
        }
    }

    changeMapType(mapType: string): void {
        this.googleMap.googleMap.setMapTypeId(google.maps.MapTypeId[mapType]);
    }

    getMapType(): string {
        return this.googleMap.googleMap.getMapTypeId();
    }

    getUserLocation(): void {
        const navigator = this.window.getWindow.navigator;

        if (navigator && navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position: GeolocationPosition) => {
                    if (this.userPin) {
                        this.removeUserMarker();
                    }

                    const location: ILatLng = {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                    };
                    const bounds = new google.maps.LatLngBounds();
                    bounds.extend(new google.maps.LatLng(location));

                    this.userPin = createHTMLMapMarker({
                        latlng: new google.maps.LatLng(location.lat, location.lng),
                        map: this.googleMap.googleMap,
                        html: this.getUserPin(),
                        isSetToMap: true,
                    });

                    this.googleMap.fitBounds(bounds);
                    this.googleMap.googleMap?.setCenter(bounds.getCenter());
                },
                (error) => console.error(error.message),
            );
        }
    }

    fitBounds(devices: IDeviceShort[], zones: IZone[], device?: IDeviceFull): void {
        const bounds = new google.maps.LatLngBounds();

        if (devices.length) {
            devices
                .filter((device) => device.lat && device.lng)
                .forEach((device: IDeviceShort) => {
                    let lat = device.lat;
                    let lng = device.lng;
                    if (device.location_ping) {
                        lat = device.location_ping.lat;
                        lng = device.location_ping.lng;
                    }
                    const location: ILatLng = { lat, lng };
                    bounds.extend(new google.maps.LatLng(location));
                });
        }

        if (zones.length) {
            zones.forEach((zone: IZone) => {
                if (zone.type === ZoneType.POLYGON) {
                    zone.preferences.vertices.forEach((e) => bounds.extend(e));
                } else if (zone.type === ZoneType.RECTANGLE) {
                    const northeast = zone.preferences.vertices.northeast;
                    bounds.extend(
                        new google.maps.LatLng({
                            lat: northeast.lat,
                            lng: northeast.lng,
                        }),
                    );
                    const southwest = zone.preferences.vertices.southwest;
                    bounds.extend(
                        new google.maps.LatLng({
                            lat: southwest.lat,
                            lng: southwest.lng,
                        }),
                    );
                } else if (zone.type === ZoneType.POLYLINE) {
                    zone.preferences.vertices.forEach((e) => bounds.extend(e));
                }
            });
        }

        if (device) {
            bounds.extend(
                new google.maps.LatLng({ lat: device.location_ping.lat, lng: device.location_ping.lng }),
            );
        }

        this.googleMap.fitBounds(bounds);
        this.googleMap.googleMap?.setCenter(bounds.getCenter());
    }

    addMarkerCluster(devices: IDeviceShort[]): void {
        this.markers = devices
            .filter((device) => device.lat && device.lng)
            .map((device: IDeviceShort, i) => {
                let lat = device.lat;
                let lng = device.lng;
                if (device.location_ping) {
                    lat = device.location_ping.lat;
                    lng = device.location_ping.lng;
                }

                const marker = createHTMLMapMarker({
                    latlng: new google.maps.LatLng(lat, lng),
                    map: this.googleMap.googleMap,
                    html: this.getMarkerTemplate(device, device.age),
                    id: device.device_id,
                    isSetToMap: false,
                });

                const circle = this.getMarkerRadius(device, { lat, lng });
                circle.bindTo('map', marker, 'map');

                marker.addListener('click', () => {
                    if (device.allSOSEvents?.length) {
                        const eventIDs = device.allSOSEvents.map((event) => event.id);
                        this.store.dispatch(
                            EventsActions.updateEvent({ eventID: eventIDs, msg: 'SOS_READ' }),
                        );
                    }
                    this.zone.run(() => this.router.navigate(['/map/devices/', device.device_id]));
                });

                return marker;
            });

        const r = 35;
        const r0 = Math.round(r * 0.6);
        const w = 70;

        const clusterIcon = window.btoa(`
            <svg xmlns="http://www.w3.org/2000/svg" viewbox="0 0 ${w} ${w}">
                <circle cx="${r}" cy="${r}" r="${r0}" fill="${skin.primaryColor}" />
            </svg>
        `);

        this.markerClusterer = new MarkerClusterer(this.googleMap.googleMap, this.markers, {
            styles: [
                {
                    url: `data:image/svg+xml;base64,${clusterIcon}`,
                    height: w,
                    width: w,
                    textColor: '#fff',
                    textSize: 18,
                    fontWeight: 'normal',
                },
            ],
            maxZoom: this.MAP_MAX_ZOOM - 1,
        });
    }

    removeMarkerById(device: IDeviceShort, isVisible: boolean) {
        this.markers.forEach((marker) => {
            if (device.device_id === marker['id']) {
                if (isVisible) {
                    this.markerClusterer.removeMarker(marker);
                } else {
                    this.markerClusterer.addMarker(marker);
                }
            }
        });
    }

    removeUserMarker() {
        this.userPin['html'] = this.getUserPin(false);
        this.userPin.setMap(this.googleMap.googleMap);
    }

    removeMarkersByIds(devices: IDeviceShort[], isVisibleMarkers: boolean) {
        devices.forEach((device) => {
            this.markers.forEach((marker) => {
                if (device.device_id === marker['id']) {
                    if (!isVisibleMarkers) {
                        this.markerClusterer.removeMarker(marker);
                    } else {
                        this.markerClusterer.addMarker(marker);
                    }
                }
            });
        });
    }

    addZones(zones: IZone[]): void {
        this.rectangles = zones.map((zone: IZone) => {
            let zoneRect;
            if (zone.type === ZoneType.POLYGON) {
                zoneRect = new google.maps.Polygon({
                    strokeColor: this.PRIMARY_COLOR,
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                    fillColor: this.PRIMARY_COLOR,
                    fillOpacity: 0.3,
                    map: this.googleMap.googleMap,
                    paths: zone.preferences.vertices,
                });
            } else if (zone.type === ZoneType.RECTANGLE) {
                zoneRect = new google.maps.Rectangle({
                    strokeColor: this.PRIMARY_COLOR,
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                    fillColor: this.PRIMARY_COLOR,
                    fillOpacity: 0.3,
                    map: this.googleMap.googleMap,
                    bounds: {
                        north: zone.preferences.vertices.northeast.lat,
                        east: zone.preferences.vertices.northeast.lng,
                        south: zone.preferences.vertices.southwest.lat,
                        west: zone.preferences.vertices.southwest.lng,
                    },
                });
            } else if (zone.type === ZoneType.POLYLINE) {
                const iconsetngs = {
                    path:
                        'M25.395,0H17.636c-3.117,0-5.643,3.467-5.643,6.584v34.804c0,3.116,2.526,5.644,5.643,5.644h11.759' +
                        'c3.116,0,5.644-2.527,5.644-5.644V6.584C35.037,3.467,32.511,0,29.395,0z M34.05,14.188v11.665l-2.729,0.351v-4.806L34.05,14.188z' +
                        'M32.618,10.773c-1.016,3.9-2.219,8.51-2.219,8.51H16.631l-2.222-8.51C14.41,10.773,23.293,7.755,32.618,10.773z M15.741,21.713' +
                        'v4.492l-2.73-0.349V14.502L15.741,21.713z M13.011,37.938V27.579l2.73,0.343v8.196L13.011,37.938z M14.568,40.882l2.218-3.336' +
                        'h13.771l2.219,3.336H14.568z M31.321,35.805v-7.872l2.729-0.355v10.048L31.321,35.805z',
                    fillColor: this.PRIMARY_COLOR,
                    fillOpacity: 1,
                    scale: 0.5,
                    anchor: new google.maps.Point(23, 0),
                };

                zoneRect = new google.maps.Polyline({
                    path: zone.preferences.vertices,
                    geodesic: true,
                    strokeColor: this.PRIMARY_COLOR,
                    strokeWeight: 3,
                    map: this.googleMap.googleMap,
                    icons: [
                        {
                            icon: iconsetngs,
                            repeat: '100px',
                        },
                    ],
                });
            }

            const infoWindow = new google.maps.InfoWindow({
                content: `<h1 class="zone-title text-ellipsis" id="infowindow_${zone.id}"">${zone.name}</h1>`,
                disableAutoPan: true,
            });

            let ne: google.maps.LatLng;
            let sw: google.maps.LatLng;
            if (zone.type === ZoneType.POLYGON) {
                const bounds = new google.maps.LatLngBounds();
                zoneRect.getPath().forEach((element) => bounds.extend(element));
                ne = bounds.getNorthEast();
                sw = bounds.getSouthWest();
            } else if (zone.type === ZoneType.RECTANGLE) {
                ne = zoneRect.getBounds().getNorthEast();
                sw = zoneRect.getBounds().getSouthWest();
            } else if (zone.type === ZoneType.POLYLINE) {
                const bounds = new google.maps.LatLngBounds();
                zoneRect.getPath().forEach((element) => bounds.extend(element));
                ne = bounds.getNorthEast();
                sw = bounds.getSouthWest();
            }

            infoWindow.setPosition({
                lat: ne.lat(),
                lng: (sw.lng() + ne.lng()) / 2,
            });

            infoWindow.open(this.googleMap.googleMap);

            google.maps.event.addListenerOnce(infoWindow, 'domready', () => {
                document
                    .getElementById(`infowindow_${zone.id}`)
                    ?.addEventListener('click', () => this.router.navigate(['/map/places/geo/', zone.id]));
            });

            this.infoWindows.push(infoWindow);

            zoneRect.addListener('click', () =>
                this.zone.run(() => this.router.navigate(['/map/places/geo/', zone.id])),
            );

            return zoneRect;
        });
    }

    initDeviceMarker(device: IDeviceFull | IDeviceByToken): void {
        let latitude: number;
        let longitude: number;
        let age: number;

        if (device['geoLocation']) {
            // IDeviceByToken
            latitude = device['geoLocation'].latitude;
            longitude = device['geoLocation'].longitude;
        } else if (device['location_ping']) {
            // IDeviceFull
            latitude = device['location_ping'].lat;
            longitude = device['location_ping'].lng;
            age = device['location_ping'].age;
        }

        if (!latitude && !longitude) {
            this.store.dispatch(MapActions.showNoLocationModal());
            return;
        }

        const location: ILatLng = { lat: latitude, lng: longitude };

        const marker = createHTMLMapMarker({
            latlng: location,
            map: this.googleMap.googleMap,
            html: this.getMarkerTemplate(device, age),
            isSetToMap: true,
        });

        this.selecteedDeviceCircle = this.getMarkerRadius(device, location);
        this.selecteedDeviceCircle.bindTo('map', marker, 'map');
        this.markers.push(marker);
        this.googleMap.fitBounds(this.selecteedDeviceCircle.getBounds());
    }

    updateSelectedDeviceMarkerPosition(device: IDeviceFull, isDeviceFollowing?: boolean): void {
        if (this.markers[0]) {
            const location: ILatLng = { lat: device.location_ping.lat, lng: device.location_ping.lng };
            this.markers[0]['latlng'] = location;
            this.markers[0]['html'] = this.updateMarkerAge(this.markers[0], device.location_ping.age);
            this.markers[0].setMap(this.googleMap.googleMap);
            this.selecteedDeviceCircle.setCenter(location);
            if (isDeviceFollowing) {
                this.googleMap.fitBounds(this.selecteedDeviceCircle.getBounds());
            }
        }
    }

    updateMarkersPositions(devices: IDeviceShort[]) {
        this.markers = devices.map((device: IDeviceShort, i) => {
            const lat = device.location_ping.lat;
            const lng = device.location_ping.lng;
            const marker = createHTMLMapMarker({
                latlng: new google.maps.LatLng(lat, lng),
                map: this.googleMap.googleMap,
                html: this.getMarkerTemplate(device, device.location_ping.age),
                id: device.device_id,
                isSetToMap: false,
            });

            const circle = this.getMarkerRadius(device, { lat, lng });
            circle.bindTo('map', marker, 'map');

            marker.addListener('click', () => {
                if (device.allSOSEvents?.length) {
                    const eventIDs = device.allSOSEvents.map((event) => event.id);
                    this.store.dispatch(EventsActions.updateEvent({ eventID: eventIDs, msg: 'SOS_READ' }));
                }
                this.zone.run(() => this.router.navigate(['/map/devices/', device.device_id]));
            });

            return marker;
        });

        this.markerClusterer.clearMarkers();
        this.markerClusterer.addMarkers(this.markers);
    }

    updateMarkerIcon(devicesWithEvents: IDeviceShort[]) {
        devicesWithEvents.forEach((device) => {
            this.markers.forEach((marker) => {
                if (device.device_id === marker['id']) {
                    marker['html'] = this.getMarkerTemplate(device, device.age);
                }
            });
        });

        this.markerClusterer.clearMarkers();
        this.markerClusterer.addMarkers(this.markers);
    }

    updateSelectedDeviceMarkerIcon(device: IDeviceFull) {
        this.markers[0]['html'] = this.getMarkerTemplate(device, device['location_ping'].age);
        this.markers[0].setMap(this.googleMap.googleMap);
        const circleColor = this.getCircleColor(device);
        this.selecteedDeviceCircle.setOptions({
            fillColor: circleColor,
            strokeColor: circleColor,
        });
    }

    updateMarkerAge(marker, age): string {
        const markerHTML = new DOMParser().parseFromString(marker['html'], 'text/html');
        if (markerHTML.getElementById('deviceAge')) {
            markerHTML.getElementById('deviceAge').textContent = `${this.timeSince(age)} ago`;
        }
        return markerHTML.getElementById('marker')?.outerHTML;
    }

    initDeviceZone(zone: IZone): void {
        let zoneRect;
        let bounds = new google.maps.LatLngBounds();

        if (zone.type === ZoneType.POLYGON) {
            zoneRect = new google.maps.Polygon({
                strokeColor: this.PRIMARY_COLOR,
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: this.PRIMARY_COLOR,
                fillOpacity: 0.3,
                editable: true,
                draggable: true,
                map: this.googleMap.googleMap,
                paths: zone.preferences.vertices,
            });
            const path = zoneRect.getPath();
            path.forEach((element) => bounds.extend(element));

            let dragging: boolean;
            zoneRect.addListener('dragstart', () => (dragging = true));
            zoneRect.addListener('dragend', () => (dragging = false));

            google.maps.event.addListener(zoneRect.getPath(), 'set_at', (event) => {
                if (!dragging) {
                    this.zone.run(() =>
                        this.zoneSize.next(this.getZoneSize(zoneRect, zone.type as ZoneType)),
                    );
                }
            });
            google.maps.event.addListener(zoneRect.getPath(), 'insert_at', (event) => {
                if (!dragging) {
                    this.zone.run(() =>
                        this.zoneSize.next(this.getZoneSize(zoneRect, zone.type as ZoneType)),
                    );
                }
            });

            this.zoneSize.next(this.getZoneSize(zoneRect, zone.type as ZoneType));
        } else if (zone.type === ZoneType.RECTANGLE) {
            zoneRect = new google.maps.Rectangle({
                strokeColor: this.PRIMARY_COLOR,
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: this.PRIMARY_COLOR,
                fillOpacity: 0.3,
                map: this.googleMap.googleMap,
                editable: true,
                draggable: true,
                bounds: {
                    north: zone.preferences.vertices.northeast.lat,
                    east: zone.preferences.vertices.northeast.lng,
                    south: zone.preferences.vertices.southwest.lat,
                    west: zone.preferences.vertices.southwest.lng,
                },
            });

            bounds = zoneRect.getBounds();

            zoneRect.addListener('bounds_changed', () => {
                console.log('bounds_changed');
                return this.zone.run(() =>
                    this.zoneSize.next(this.getZoneSize(zoneRect, zone.type as ZoneType)),
                );
            });

            this.zoneSize.next(this.getZoneSize(zoneRect, zone.type as ZoneType));
        } else if (zone.type === ZoneType.POLYLINE) {
            zoneRect = this.initRoute(
                zone.preferences.vertices[0],
                zone.preferences.vertices[zone.preferences.vertices.length - 1],
            );
        }

        this.rectangles.push(zoneRect);
        this.googleMap.fitBounds(bounds);
    }

    initNewZone(zoneType = ZoneType.RECTANGLE): void {
        this.zoomIn();
        const rectBounds = this.googleMap.getBounds();
        let zoneRect;

        if (zoneType === ZoneType.RECTANGLE) {
            zoneRect = new google.maps.Rectangle({
                strokeColor: this.PRIMARY_COLOR,
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: this.PRIMARY_COLOR,
                fillOpacity: 0.3,
                zIndex: 1000,
                map: this.googleMap.googleMap,
                editable: true,
                draggable: true,
                bounds: rectBounds,
            });

            zoneRect.addListener('bounds_changed', () =>
                this.zone.run(() => this.zoneSize.next(this.getZoneSize(zoneRect, zoneType))),
            );
            this.zoneSize.next(this.getZoneSize(zoneRect, zoneType));
            this.rectangles.push(zoneRect);
        } else if (zoneType === ZoneType.POLYGON) {
            const NE = rectBounds.getNorthEast();
            const SW = rectBounds.getSouthWest();
            const NW = new google.maps.LatLng(NE.lat(), SW.lng());
            const SE = new google.maps.LatLng(SW.lat(), NE.lng());

            zoneRect = new google.maps.Polygon({
                strokeColor: this.PRIMARY_COLOR,
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: this.PRIMARY_COLOR,
                fillOpacity: 0.3,
                zIndex: 1000,
                map: this.googleMap.googleMap,
                editable: true,
                draggable: true,
                paths: [[NE, NW, SW, SE]],
            });

            const bounds = new google.maps.LatLngBounds();
            zoneRect.getPath().forEach((element) => bounds.extend(element));

            let dragging: boolean;
            zoneRect.addListener('dragstart', () => (dragging = true));
            zoneRect.addListener('dragend', () => (dragging = false));

            google.maps.event.addListener(zoneRect.getPath(), 'set_at', (event) => {
                if (!dragging) {
                    this.zone.run(() => this.zoneSize.next(this.getZoneSize(zoneRect, zoneType)));
                }
            });

            google.maps.event.addListener(zoneRect.getPath(), 'insert_at', (event) => {
                if (!dragging) {
                    this.zone.run(() => this.zoneSize.next(this.getZoneSize(zoneRect, zoneType)));
                }
            });

            this.zoneSize.next(this.getZoneSize(zoneRect, zoneType));
            this.rectangles.push(zoneRect);
        } else if (zoneType === ZoneType.POLYLINE) {
            this.zoneSize.next(this.getZoneSize(null, zoneType));
        }

        this.zoomOut();
    }

    initRoute(start: ILatLng, end: ILatLng) {
        this.initRouteCalled = true;
        const directionsService = new google.maps.DirectionsService();
        const directionsRenderer = new google.maps.DirectionsRenderer({
            draggable: true,
            map: this.googleMap.googleMap,
            panel: document.getElementById('panel') as HTMLElement,
            polylineOptions: {
                strokeColor: this.PRIMARY_COLOR,
                strokeWeight: 3,
            },
            routeIndex: 1000,
            markerOptions: {
                icon: 'assets/images/map_point_filled.svg',
            },
        });

        directionsRenderer.addListener('directions_changed', () => {
            const directions = directionsRenderer.getDirections();
            if (directions) {
                this.zoneSize.next(this.getZoneSize(directions, ZoneType.POLYLINE));
            }
        });

        directionsService.route(
            {
                origin: start,
                destination: end,
                waypoints: [],
                travelMode: google.maps.TravelMode.DRIVING,
            },
            (result: google.maps.DirectionsResult) => {
                directionsRenderer.setDirections(result);
                this.zoneSize.next(this.getZoneSize(result, ZoneType.POLYLINE));
            },
        );

        this.directionsRenderer.push(directionsRenderer);
        return directionsRenderer;
    }

    private computeTotalDistance(result: google.maps.DirectionsResult): number {
        let total = 0;
        const myroute = result.routes[0];

        for (let i = 0; i < myroute.legs.length; i++) {
            total += myroute.legs[i]!.distance!.value;
        }

        return total;
    }

    removeMarkers(): void {
        this.markerClusterer?.removeMarkers(this.markers);
        this.markers?.forEach((marker) => marker.setMap(null));
        this.markers = [];
    }

    removeZones(): void {
        this.rectangles?.forEach((r) => r.setMap(null));
        this.removeInitialZone();
    }

    removeInitialZone(): void {
        this.initRouteCalled = false;
        this.rectangles.forEach((r) => {
            // editable temporary zone
            if (r['zIndex'] === 1000) {
                r.setMap(null);
            }
        });

        this.directionsRenderer.forEach((r) => {
            r.setMap(null);
        });
    }

    removeInfoWindows() {
        this.infoWindows.forEach((iw) => iw.close());
    }

    removeHistory(): void {
        this.polyline?.setMap(null);
    }

    initDeviceHistory(history: IDeviceHistory[]): void {
        const bounds = new google.maps.LatLngBounds();
        const coordinates = history.map((h) => {
            const location = { lat: h.lat, lng: h.lng };
            bounds.extend(new google.maps.LatLng(location));
            const marker = createHTMLMapMarker({
                latlng: new google.maps.LatLng(location.lat, location.lng),
                map: this.googleMap.googleMap,
                html: h.point_type === 2 ? this.getMarkerHistoryStopPin(h) : this.getMarkerHistoryPin(h),
                id: h.location_id,
                isSetToMap: true,
            });

            this.markers.push(marker);

            marker.addListener('click', () => {
                this.zone.run(() => {
                    this.store.dispatch(DevicesActions.showDeviceHistoryInfo({ historyInfo: h }));
                });
            });

            return location;
        });

        this.polyline = new google.maps.Polyline({
            path: coordinates.reverse(),
            strokeColor: this.PRIMARY_COLOR,
            strokeOpacity: 1.0,
            strokeWeight: 3,
            icons: [
                {
                    repeat: '100px',
                    icon: {
                        path: google.maps.SymbolPath.BACKWARD_OPEN_ARROW,
                    },
                    offset: '100%',
                },
            ],
        });

        this.polyline.setMap(this.googleMap.googleMap!);
        this.googleMap.fitBounds(bounds);
    }

    getAddress(lat: number, lng: number) {
        return this.apiService.getAddress(lat, lng);
    }

    updateHistoryMarkerIcon(historyItem: IDeviceHistory) {
        const initialZoom = this.googleMap.getZoom();
        const bounds = new google.maps.LatLngBounds();

        this.markers.forEach((marker) => {
            const selectedHistoryItem = {
                ...historyItem,
                isSelected: historyItem?.location_id === marker['id'],
            };
            if (historyItem?.location_id === marker['id']) {
                bounds.extend(new google.maps.LatLng({ lat: historyItem.lat, lng: historyItem.lng }));
                marker['html'] =
                    historyItem.point_type === 2
                        ? this.getMarkerHistoryStopPin(selectedHistoryItem)
                        : this.getMarkerHistoryPin(selectedHistoryItem);
            } else {
                marker['html'] = marker['html'].replace('selected', '');
            }

            marker.setMap(this.googleMap.googleMap);
            this.googleMap.fitBounds(bounds);
            this.googleMap.googleMap.setZoom(initialZoom);
        });
    }

    deselectHistoryPoint() {
        this.markers.forEach((marker) => {
            marker['html'] = marker['html'].replace('selected', '');
            marker.setMap(this.googleMap.googleMap);
        });
    }

    private getMarkerTemplate(
        device: IDeviceShort | IDeviceFull | IDeviceByToken,
        age: number,
        isVisible = true,
    ): string {
        let deviceName: string;

        if (device['info']) {
            // IDeviceFull
            deviceName = device['info'].nick_name ? device['info'].nick_name : device['info'].device_id;
        } else if (device['geoLocation']) {
            // IDeviceByToken
            deviceName = device['deviceName'] ? device['deviceName'] : device['deviceId'];
        } else {
            // IDeviceShort
            deviceName = device['device_name'] ? device['device_name'] : device['device_id'];
        }
        let isSosMarker = device['allSOSEvents']?.length ? 'red-marker' : 'default-marker';
        if (device['location_ping']) {
            isSosMarker = device['location_ping'].comm_stat ? isSosMarker : 'inactive-marker';
        }
        let isInSleep = device['schedule_sleep_configuration']?.is_currently_in_sleep;
        let wakeUpTime = device['schedule_sleep_configuration']?.scheduled_sleep_wakeup_in_min;
        if (isInSleep) {
            if (wakeUpTime !== null) {
                isSosMarker = 'inactive-marker';
                wakeUpTime = wakeUpTime;
            } else {
                isInSleep = null;
            }
        }
        let refreshIconName = !isInSleep ? 'refresh' : 'refresh-wakeup';

        return `
            <div id="marker" class="marker ${!isVisible ? 'hidden' : ''}">
                ${
                    age
                        ? `<div class="marker-time ${isSosMarker}">
                                <img src="assets/images/${refreshIconName}.svg">
                                <span id="${!isInSleep ? 'deviceAge' : 'wakeUp'}">${
                              !isInSleep
                                  ? this.timeSince(age) + ' ago'
                                  : 'Awake in ' + this.timeLeft(wakeUpTime)
                          }</span>
                            </div>`
                        : ''
                }

                <div class="marker-image-container">
                    ${
                        device['location_ping'] || device['location']
                            ? `<div class="connection-type ${isSosMarker}">
                                    <img src="white-labels/${skin.whiteLabel}/${(
                                  device['location_ping'] || device['location']
                              ).type.toLowerCase()}.svg">
                                </div>`
                            : ''
                    }
                    <img class="marker-default" src="white-labels/${skin.whiteLabel}/${isSosMarker}.svg">
                    <img class="m-image ${!device['icon_url'] ? isSosMarker : ''} ${
            isInSleep ? 'inactive-marker' : ''
        }"
                        src="${this.getMarkerIcon(device)}"
                        alt="${deviceName}">
                </div>

                ${
                    this.isShowDeviceName
                        ? `<div class="marker-name" title="${deviceName}">${deviceName}</div>`
                        : ''
                }
            </div>
        `;
    }

    private getMarkerHistoryPin(selectedHistoryItem?): string {
        const isSelected = selectedHistoryItem?.isSelected;
        return `<div class="history-pin ${isSelected ? 'selected' : ''}"></div>`;
    }

    private getMarkerHistoryStopPin(selectedHistoryItem?): string {
        const isSelected = selectedHistoryItem?.isSelected;
        return `<div class="history-stop-pin ${isSelected ? 'selected' : ''}">
                  <svg width="30" height="30" xmlns="http://www.w3.org/2000/svg">
                      <g filter="url(#a)">
                      <path clip-rule="evenodd" d="M15 24.167a9.167 9.167 0 1 0 0-18.334 9.167 9.167 0 0 0 0 18.334Z" stroke="#fff" stroke-width="3"/>
                      </g>
                      <path d="M15 6.124c-4.894 0-8.876 3.982-8.876 8.876 0 4.894 3.982 8.876 8.876 8.876 4.894 0 8.876-3.982 8.876-8.876 0-4.894-3.982-8.876-8.876-8.876Z"/>
                      <path d="m14.517 14.802-3.4 3.425a.482.482 0 0 0 .686.68l3.68-3.708V8.433a.482.482 0 0 0-.966 0v6.369Z" fill="#fff"/>
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M14.758 16.446a1.205 1.205 0 1 0 0-2.41 1.205 1.205 0 0 0 0 2.41Z" fill="#fff"/>
                      <defs><filter id="a" x=".333" y=".333" width="29.333" height="29.334" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                      <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                      <feOffset/><feGaussianBlur stdDeviation="2"/><feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"/>
                      <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_5869:114688"/>
                      <feBlend in="SourceGraphic" in2="effect1_dropShadow_5869:114688" result="shape"/>
                      </filter>
                      </defs>
                  </svg>
                </div>`;
    }

    private getUserPin(isVisible = true): string {
        return `<div class="user-pin ${!isVisible ? 'hidden' : ''}"></div>`;
    }

    private getMarkerIcon(device: IDeviceShort | IDeviceFull | IDeviceByToken): string {
        let markerIconUrl: string;
        let deviceIconUrl: string;
        let deviceIconID: string;

        if (device['info']) {
            deviceIconUrl = device['info'].icon_url;
            deviceIconID = device['info'].icon_id;
        } else {
            deviceIconUrl = device['icon_url'];
            deviceIconID = device['icon_id'];
        }

        if (deviceIconUrl) {
            markerIconUrl = deviceIconUrl;
        } else {
            switch (deviceIconID?.toString()) {
                case '2':
                    markerIconUrl = DeviceMarker.Watch;
                    break;
                case '3':
                    markerIconUrl = DeviceMarker.TrackiPro;
                    break;
                case '4':
                    markerIconUrl = DeviceMarker.Universal;
                    break;
                case '5':
                    markerIconUrl = DeviceMarker.Guardian;
                    break;
                case '6':
                    markerIconUrl = DeviceMarker.Mini;
                    break;
                case '7':
                    markerIconUrl = DeviceMarker.Travel;
                    break;
                case '10':
                    markerIconUrl = DeviceMarker.Male;
                    break;
                case '11':
                    markerIconUrl = DeviceMarker.Female;
                    break;
                case '14':
                    markerIconUrl = DeviceMarker.Pet;
                    break;
                default:
                    markerIconUrl = DeviceMarker.Universal;
            }
        }

        return markerIconUrl;
    }

    private timeLeft(minutes: number): string {
        if (minutes > 60) {
            let interval = minutes / 60;
            return Math.floor(interval) + 'h';
        }
        return minutes + 'm';
    }

    private timeSince(seconds: number): string {
        let interval = seconds / 31536000;
        if (interval > 1) {
            return Math.floor(interval) + 'y';
        }

        interval = seconds / 2592000;
        if (interval > 1) {
            return Math.floor(interval) + 'mo';
        }

        interval = seconds / 86400;
        if (interval > 1) {
            return Math.floor(interval) + 'd';
        }

        interval = seconds / 3600;
        if (interval > 1) {
            return Math.floor(interval) + 'h';
        }

        interval = seconds / 60;
        if (interval > 1) {
            return Math.floor(interval) + 'min';
        }
        return Math.floor(seconds) + 's';
    }

    private getZoneSize(zone, zoneType = ZoneType.RECTANGLE): IZoneSize {
        let bounds: google.maps.LatLngBounds;
        let vertices;
        let distance: number;
        if (zoneType === ZoneType.POLYGON) {
            bounds = new google.maps.LatLngBounds();
            zone.getPath().forEach((element) => bounds.extend(element));
            vertices = zone
                .getPath()
                .getArray()
                .map((e) => ({ lat: e.lat(), lng: e.lng() }));
        } else if (zoneType === ZoneType.RECTANGLE) {
            bounds = zone.getBounds();

            vertices = {
                southwest: { lat: bounds.getSouthWest().lat(), lng: bounds.getSouthWest().lng() },
                northeast: { lat: bounds.getNorthEast().lat(), lng: bounds.getNorthEast().lng() },
            };
        } else if (zoneType === ZoneType.POLYLINE) {
            bounds = new google.maps.LatLngBounds();
            vertices = zone?.routes[0].overview_path.map((e) => ({ lat: e.lat(), lng: e.lng() }));
            distance = zone ? this.computeTotalDistance(zone) : 0;
        }

        const height = google.maps.geometry.spherical.computeDistanceBetween(
            new google.maps.LatLng(bounds.toJSON().north, bounds.toJSON().east),
            new google.maps.LatLng(bounds.toJSON().south, bounds.toJSON().east),
        );

        const width = google.maps.geometry.spherical.computeDistanceBetween(
            new google.maps.LatLng(bounds.toJSON().north, bounds.toJSON().east),
            new google.maps.LatLng(bounds.toJSON().north, bounds.toJSON().west),
        );

        return {
            height: Math.round(height),
            width: Math.round(width),
            distance,
            type: zoneType,
            preferences: {
                trigger: 'BOTH',
                vertices,
            },
        };
    }
    // @TODO Removed if not needed
    private _getAddress(lat: number, lng: number, callback): void {
        const geoCoder = new google.maps.Geocoder();
        geoCoder.geocode({ location: { lat, lng } }, (results, status) => {
            let address: string;
            if (status === 'OK') {
                if (results[0]) {
                    address = results[0].formatted_address;
                } else {
                    address = 'Address not found';
                }
            } else {
                address = 'Address not found';
                console.log('Geocoder failed due to: ' + status);
            }

            callback(address);
        });
    }

    private getMarkerRadius(device, location): google.maps.Circle {
        let connectionType = (device as IDeviceFull).location_ping?.type;
        let circleColor = this.getCircleColor(device);
        // For device by token
        if (device.geoLocation) {
            connectionType = device.geoLocation.type;
            circleColor = this.PRIMARY_COLOR;
        }
        const circle = new google.maps.Circle({
            strokeColor: circleColor,
            strokeOpacity: 0.6,
            strokeWeight: 2,
            fillColor: circleColor,
            fillOpacity: 0.3,
            center: location,
            radius: this.getCircleSize(connectionType),
        });

        return circle;
    }

    private getCircleColor(device: IDeviceFull): string {
        let color: string;
        if (device.allSOSEvents?.length) {
            color = this.SOS_MARKER_COLOR;
        } else if (!device.location_ping?.comm_stat) {
            color = this.INACTIVE_MARKER_COLOR;
        } else {
            color = this.PRIMARY_COLOR;
        }

        return color;
    }

    private getCircleSize(connectionType): number {
        let index = 0;
        if (connectionType === 'gps' || connectionType === 'GPS') {
            index = 20;
        } else if (connectionType === 'wifi' || connectionType === 'WIFI') {
            index = 100;
        } else if (connectionType === 'gsm' || connectionType === 'GSM') {
            index = 1000;
        }
        return index;
    }
}
