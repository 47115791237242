import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { interval, of } from 'rxjs';
import {
    catchError,
    filter,
    map,
    mergeMap,
    switchMap,
    takeUntil,
    takeWhile,
    tap,
    withLatestFrom,
} from 'rxjs/operators';
import { DevicesActions } from '../actions/devices.actions';
import { API_PING_INTERVAL } from '../constants/common.constants';
import {
    selectDevicesList,
    selectDevicesWithLocation,
    selectSectedDevice,
} from '../selectors/devices.selectors';
import { selectPingDeviceLocationStatus } from '../selectors/status.selectors';
import { selectUser } from '../selectors/user.selector';
import { ApiService, GoogleMapService, LocalStorageService } from '../services';
import { IAppState } from '../state/app.state';

@Injectable()
export class LocationsEffects {
    /// ***** Device Location ***** ///
    pingDeviceLocationInterval$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DevicesActions.startPingDeviceLocation),
            switchMap(() =>
                interval(API_PING_INTERVAL).pipe(
                    withLatestFrom(this.store.select(selectPingDeviceLocationStatus)),
                    takeWhile(([, pingDeviceLocation]) => pingDeviceLocation),
                    withLatestFrom(this.store.select(selectUser), this.store.select(selectSectedDevice)),
                    mergeMap(([, user, device]) =>
                        this.apiService.getDeviceLocation(user.account_id, device.info.device_id).pipe(
                            map((locations: string) =>
                                DevicesActions.pingDeviceLocationSuccess({
                                    response: JSON.parse(locations)[0],
                                }),
                            ),
                            catchError((error) => of(DevicesActions.pingDeviceLocationError({ error }))),
                        ),
                    ),
                ),
            ),
        ),
    );

    pingDeviceLocationSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(DevicesActions.pingDeviceLocationSuccess),
                withLatestFrom(this.store.select(selectSectedDevice)),
                filter(([, selectedDevice]) => !!selectedDevice.location_ping),
                tap(([, selectedDevice]) =>
                    this.googleMapService.updateSelectedDeviceMarkerPosition(
                        selectedDevice,
                        Boolean(this.localStorage.getItem('isDeviceFollowing')),
                    ),
                ),
                filter(() => this.router.url.split('/').slice(1).length === 3), // Get Addres only on info page
                tap(([, device]) =>
                    this.store.dispatch(
                        DevicesActions.getAddressForDevice({
                            deviceID: device.info.device_id,
                            lat: device.location_ping.lat,
                            lng: device.location_ping.lng,
                        }),
                    ),
                ),
            ),
        { dispatch: false },
    );

    /// ***** Devices Locations ***** ///
    pingDevicesLocationsInterval$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DevicesActions.getDevicesSuccess),
            withLatestFrom(this.store.select(selectDevicesList)),
            filter(([, devices]) => !!devices.length && this.router.url === '/map/devices'),
            switchMap(() =>
                interval(API_PING_INTERVAL).pipe(
                    takeUntil(this.actions$.pipe(ofType(DevicesActions.clearDevices))),
                    withLatestFrom(this.store.select(selectUser)),
                    mergeMap(([, user]) =>
                        this.apiService.getDevicesLocations(user.account_id).pipe(
                            filter((response) => !!response.length),
                            map((response) => DevicesActions.pingDevicesLocationsSuccess({ response })),
                            catchError((error) => of(DevicesActions.pingDevicesLocationsError({ error }))),
                        ),
                    ),
                ),
            ),
        ),
    );

    getDevicesLocationsSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(DevicesActions.pingDevicesLocationsSuccess),
                withLatestFrom(this.store.select(selectDevicesWithLocation)),
                tap(([, devices]) => {
                    const devicesWithUpdatedLocation = devices?.filter((device) => device.location_ping);
                    this.googleMapService.updateMarkersPositions(devicesWithUpdatedLocation);
                }),
            ),
        { dispatch: false },
    );

    constructor(
        private actions$: Actions,
        private apiService: ApiService,
        private store: Store<IAppState>,
        private googleMapService: GoogleMapService,
        private localStorage: LocalStorageService,
        private router: Router,
    ) {}
}
