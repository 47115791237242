import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { LocalStorageService, TutorialsService } from 'src/app/store/services';
import { skin } from 'src/white-labels';
import { AuthActions } from '../../store/actions/auth.actions';
import { IEvent, IUser } from '../../store/interfaces';
import { IAppState } from '../../store/state/app.state';
import { MAIN_TOUR_STEPS } from '../../store/constants/common.constants';
import { Observable } from 'rxjs';
import { selectUser } from '../../store/selectors/user.selector';
import { selectUnreadEventsList } from '../../store/selectors/unread-events.selector';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {
    @Input() user: IUser;
    @Input() isShowMobSupportMenu: boolean;
    unreadEvents$: Observable<number> = this.store.select(selectUnreadEventsList);
    skin = skin;
    tutorialSteps = MAIN_TOUR_STEPS;

    constructor(
        private store: Store<IAppState>,
        private localStorage: LocalStorageService,
        private router: Router,
        private tutorials: TutorialsService,
    ) {}

    goHome(): void {
        if (this.localStorage.getItem('token')) {
            this.router.navigate(['/map']);
        } else {
            this.router.navigate(['/']);
        }
    }

    logout(): void {
        this.store.dispatch(AuthActions.logout({ accountID: this.user.account_id }));
    }

    moveToTutorials(): void {
        this.localStorage.setItem('tutorials', 'On');
        if (this.router.routerState.snapshot.url === '/map/devices') {
            this.tutorials.initTutorials(this.tutorialSteps);
        } else {
            this.router.navigate(['/map']);
        }
    }
}
