import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
    selector: 'app-forgot-password-success',
    templateUrl: './forgot-password-success.component.html',
    styleUrls: ['./forgot-password-success.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ForgotPasswordSuccessComponent {
    constructor() {}
}
